
.MuiDialogTitle-root
   .more-info-link
      padding: 5px 0
      margin-left: 4px
      a
         font-size: .9rem
         color: $Green
         @media (max-width: 600px)
            font-size: .8rem
         
.MuiDialogContent-root
   .table-content
      width: 100%
      .head-table
         display: grid
         grid-template-areas: "state-activity description"
         grid-template-columns: 40% 60%
         grid-template-rows: auto
         font-weight: bold

         div
            border: 2px solid black

         .state-activity
            border-right: none

         .state-activity, .description
            padding: .4rem
            border-bottom: none
            display: flex
            justify-content: center
            align-items: center
            background: $Green
            color: $White

      .content-table
         font-size: .9rem         
         #BG-g
            .state-activity-content, .description-content
               background: $Grey1
         .item-content
            display: grid
            grid-template-areas: "state-activity description"
            grid-template-columns: 40% 60%
            grid-template-rows: 100%

            div
               border: 2px solid black

            .state-activity-content
               border-right: none
               display: flex
               justify-content: center
               align-items: center
               .state-activity-columns
                  width: 100%
                  height: 100%
                  border: none
                  display: grid
                  grid-template-areas: "color-content text-content"
                  grid-template-columns: 45% 55%
                  grid-template-rows: 100%

                  .color-content
                     border: none
                     border-right: 2px solid black
                     text-align: center
                     display: flex
                     flex-direction: column
                     align-items: center
                     justify-content: center
                     padding: .5rem .5rem .7rem
                     img
                        width: 50px
                        margin-bottom: .4rem
                     p.title
                        font-weight: bold
                  
                  .text-content
                     border: none
                     padding: .5rem
                     display: flex
                     justify-content: center
                     align-items: center
                     p
                        text-align: center
                     
            .description-content
               padding: .5rem
               display: flex
               justify-content: center
               align-items: center
               p
                  text-align: justify

