.main-interactive-legend
	width: 350px
	height: 180px
	display: grid
	grid-template-areas: "title-interactive-legend" "colors-interactive-legend"
	grid-template-columns: 100%
	grid-template-rows: 20% 80%
	padding: 1rem

	.title-interactive-legend
		grid-area: title-interactive-legend
		font-size: 15pt
		
		h6
			img
				margin: 0rem 0.5rem
				width: 15px
		
	
	.colors-interactive-legend
		grid-area: colors-interactive-legend
		font-size: 10pt
		display: grid
		grid-template-areas: "colorsOne colorsTwo"
		grid-template-columns: 50% 50%
		grid-template-rows: 100%
		margin-top: 1rem
		
		ul
			list-style: none

			li
				display: flex
				div
					width: 15px
					height: 15px
					border: 1px solid black
					margin-right: 0.5rem
					background: red
