.MapPanel
   width: max-content
   padding: 10px
   background-color: #ffffff
   box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%)
   border-radius: 5px
   color: #7f7f7f
   min-width: 130px
   max-width: 400px
   .PanelTitle
      font-weight: 600
      margin-bottom: .5rem
      display: flex
      -webkit-box-pack: justify
      justify-content: space-between
      -webkit-box-align: center
      align-items: center
      p
         margin: 0
         letter-spacing: 0.05rem
         color: $Green

   // Settings     
.SettingsLegendContainer
   z-index: 3
   position: absolute
   right: 10px
   top: 10px
   pointer-events: none
   .SettingsItem
      border: 2px solid rgba(0,0,0,0.2)
      border-radius: 4px
      pointer-events: visiblePainted
      margin-bottom: .3rem
      cursor: pointer

      .SettingsItemContainer
         position: relative
         .SeismicityLegend
            pointer-events: none
            position: absolute
            top: 38px
            right: -1px
            opacity: 0.95 !important
            z-index: -1
            @media (max-height: 450px) 
               position: fixed
               top: 10px
               right: 52px
               
      .SettingsIcon
         width: 30px
         height: 30px
         background-color: $Green
         color: #f4f4f4
         text-align: center
         line-height: 40px
         &:hover
            opacity: .85
      .SettingsIcon.active
         background-color: #f4f4f4
         color: $Green
      .SettingsPanel
         position: absolute
         right: 40px
         top: 0
         pointer-events: auto
         cursor: auto
         padding: 15px
         .SettingsPanelItem
            padding: 10px 0 0px
            .SettingsItemTitle
               color: $Green
               font-weight: 700
               font-size: 0.6875rem
               text-transform: uppercase
               letter-spacing: 0.08rem
               text-align: center
            .SettingsItemContent
               padding: 5px 15px
               font-size: 0.7rem

button.CloseButton
   display: inline-flex
   -webkit-box-align: center
   align-items: center
   -webkit-box-pack: center
   justify-content: center
   position: relative
   box-sizing: border-box
   -webkit-tap-highlight-color: transparent
   background-color: transparent
   outline: 0px
   border: 0px
   margin: -5px -5px 0px 0px
   padding: 5px
   cursor: pointer
   user-select: none
   vertical-align: middle
   appearance: none
   text-decoration: none
   text-align: center
   flex: 0 0 auto
   font-size: 1.5rem
   border-radius: 50%
   overflow: visible
   transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
   color: #667f00
   &:hover
      background-color:$Green
      color: #fff

button.filterButton
   color: #fff
   background-color: $Green
   border: 1px solid #667F00
   box-shadow: 2px 2px 4px #555
   font-size: 0.7rem
   padding-top: 5px
   padding-bottom: 5px
   padding-left: 15px
   padding-right: 15px
   cursor: pointer
   text-transform: uppercase
   letter-spacing: 0.08rem
   border-radius: 8px
   font-weight: 550
   &:disabled
      pointer-events: none
      cursor: default
      opacity: 0.6
   &:hover
      background-color: #286405
   
