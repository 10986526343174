.shadowContainer
   height: 120px
   
/**HomeFooter.jsx*
.HomeFooter
  height: max-content
  font-family: 'Nunito Sans', sans-serif
  address
   font-style: normal
  .HomeFtContainer
    background-color: white
    background: url("../assets/img/Home/bg-footer.png")
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    width: 100%
    padding: 10px 0 5vw 0
    .HomeFtContent
      font-weight: 500
      margin: 0 auto
      position: relative
      background: $White
      width: 80%
      max-width: $HzMaxWidth
      padding: 70px 80px 30px
      border-radius: 15px
      margin-top: -100px
      border: 3px solid $Gray
      @media ( max-width: $Large )
         padding: 70px 70px 30px

      .HomeFtHeader
         color: $Green
         margin-bottom: 1rem
         .HomeFtTitle
            font-size: 1.3rem

      .HomeFtInfoGrid
         margin: 2rem 0
         display: grid
         gap: 3.5rem
         grid-template-columns: 1fr 1fr .65fr
         @media ( max-width: $Large )
            gap: 1.5rem
         .HomeFtDescription
            p
               font-size: 8pt
               line-height: 23px
            span.inline
               display: inline-block
         .HomeFtDescription.First
            margin-right: 1rem
         .HomeFtSocial
            .HomeFtLogo
               text-align: right
               img
                  width: 95%
                  max-height: 90px
            .HomeFtSocialMedia
               padding: 0 .5rem
               display: grid
               grid-template-columns: repeat(3, 1fr)
               gap: 10px
               .SocialMediaItem
                  text-align: center
                  transition: .25s
                  a
                     text-decoration: none
                  &:hover
                     transform: scale(102%)
                     opacity: .9
                  img.SocialMediaIcon
                     height: 45px
                  .SocialMediaText
                     font-size: .8rem
                     color: $Green
                     font-weight: 700

      .HomeFtLinks
         padding-top: 1rem
         text-align: center
         a
            color: $Green
            text-decoration: none
            margin-right: 20px
            font-size: 8pt
            &:hover
               text-decoration: underline

// breakpoint 
@media ( max-width: $Medium )
   .HomeFooter
      .HomeFtContainer
         .HomeFtContent
            width: 90%
            padding: 60px 60px 30px
            .HomeFtHeader
               .HomeFtTitle
                  font-size: 1rem
               .HomeFtSubTitle
                  font-size: .8rem
            .HomeFtInfoGrid
               grid-template-columns: 1fr 1fr .9fr
               gap: 1.5rem
               .HomeFtDescription
                  p
                     font-size: 7pt
                     line-height: 19px
               .HomeFtDescription.First
                  margin-right: 15px
               .HomeFtSocial
                  .HomeFtSocialMedia
                     gap: 5px
                     .SocialMediaItem
                        img.SocialMediaIcon
                           max-height: 40px
                        .SocialMediaText
                           font-size: 7pt

// breakpoint tablet
@media ( max-width: $Small )
   .HomeFooter
      .HomeFtContainer
         padding-top: 40px
         .HomeFtContent
            padding: 3rem

            .HomeFtInfoGrid
               grid-template-columns: 1fr 1fr
               row-gap: 5px
               .HomeFtDescription
                  p
                     font-size: 1.5vw
                     line-height: 3vw
               .HomeFtDescription.First
                  margin-right: 0
               .HomeFtSocial
                  max-width: none
                  grid-column-start: 1
                  grid-column-end: 3
                  display: flex
                  align-items: center
                  flex-direction: column
                  margin-top: 1rem
                  .HomeFtLogo
                     text-align: center
                     img
                        max-height: unset
                  .HomeFtSocialMedia
                     width: 250px
                     gap: 25px
                     .SocialMediaItem
                        img.SocialMediaIcon
                           max-height: 80px
                           height: auto
                        .SocialMediaText
                           font-size: 9pt

            .HomeFtLinks
               a
                  font-size: 1.5vw

// breakpoint cellphone
@media ( max-width: $XSmall )
   .HomeFooter
      .HomeFtContainer
         .HomeFtContent
            padding: 11vw 8vw
            .HomeFtHeader
               .HomeFtTitle
                  font-size: 16px
               .HomeFtSubTitle
                  font-size: 14px
            .HomeFtInfoGrid
               margin: 4vw 0
               display: block
               .HomeFtDescription
                  p
                     font-size: 10px
                     line-height: 20px
               .HomeFtSocial
                  .HomeFtLogo
                     width: 90%
                  .HomeFtSocialMedia
                     gap: 7vw
                     width: 90%
                     .SocialMediaItem
                        img.SocialMediaIcon
                           max-width: 50px
                        .SocialMediaText
                           font-size: 11px
            .HomeFtLinks
               padding-top: .5rem
               a
                  font-size: 10px
                  margin-right: 15px
                  display: inline-block

.HomeFtLegal
    height: 70px
    width: 100%
    text-align: center
    padding: 18px
    background: $LegalBl
    img
      height: 100%
      margin: 0 30px 0 0
    
    
  
  