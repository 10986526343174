.container-popup-volcano-marker
	width: 500px
	height: 350px
	display: grid
	grid-template-areas: "main-popup"
	grid-template-columns: auto
	grid-template-rows: auto
	background: #33362D
	font-family: "Roboto"
	font-size: 9pt
	z-index: 10000000000000000000000000000000000000000000000


	.main-popup
		grid-area: main-popup
		display: grid
		grid-template-areas: "image-title-popup" "info-popup" "btn-container"
		grid-template-rows: 70% auto auto
		span
			font-weight: bold
			color: $Green

		.image-title-popup
			grid-area: image-title-popup
			display: grid
			grid-template-areas: "img-container" "title-container"
			grid-template-columns: auto
			grid-template-rows: auto 35%
			
			

			.img-container
				background-size: cover
				background-position: center center !important
				border-bottom: 1px solid $PGreen
				display: flex
				justify-content: flex-end
				align-items: flex-end

				.level-activity-container
					width: 70px
					height: 70px
					display: flex
					justify-content: center
					align-items: center
					background: white
					border-radius: 50%
					border: 1px solid $PGreen
					position: relative
					right: 30px
					top: 40px

					img
						width: 50px
						height: 50px
			
			.title-container
				padding: .5rem .5rem
				background: white	
				color: black

				.title-event-pp
					font-size: 12pt
					font-weight: bolder

				p
					height: 22px

		.info-popup
			padding: .5rem .5rem 0rem .5rem
			background: white	
			color: black
			border-top: none

			.info-container
				grid-area: info-container
				align-content: center

				hr
					border-top: none 
					border-left: none 
					border-right: none
					border-bottom: 1px solid $PGreen
					margin: 0rem 0rem 0.5rem 0rem
			
		
		.btn-container-pp
			grid-area: btn-container
			display: flex
			justify-content: flex-end
			align-items: center
			background: white	
			color: black
			
			// padding-bottom: 0.5rem 
			
			button
				outline: none
				border: none
				color: $White
				background: $Green
				margin: 0rem .5rem
				width: 150px
				height: 30px
				font-size: 10pt
				border: 1px solid $White
				border-radius: 8px            
				cursor: pointer
			button:hover
				border: 1px solid $Green
				color: $Green 
				background: $White

@media screen and ( max-width:  539px)
	.container-popup-volcano-marker
		width: 300px
		display: grid
		grid-template-areas: "main-popup"
		grid-template-columns: auto
		grid-template-rows: auto
		background: #33362D
		font-family: "Roboto"
		font-size: 9pt