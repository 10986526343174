#accessibility_icon, #accessibility_icon_popup
    img
        width: 30px
.SmLatlngText
    position: absolute
    top: -11px
    left: -11px
    font-size: .7em
    font-weight: bold
    box-shadow: none
    padding: 0
    border: none
    border-radius: 0
    color: $Black
    background: transparent
    &::before
        border: none
.leaflet-top
    top: 0 !important
.leaflet-right
    right: 0 !important
.leaflet-control
    .leaflet-control-zoom-fullscreen
        width: 37px
        height: 37px
        outline: none

