.OriginTable
   tr
      th:nth-child(4) // Tiempo
         min-width: 200px

.container-trace
    text-align: center
    height: 450px
    overflow-y: scroll
    scrollbar-color: $Green $Grey1
    scrollbar-width: thin
    
.container-trace::-webkit-scrollbar 
    width: 10px

.container-trace::-webkit-scrollbar-track 
    background: $Grey1

.container-trace::-webkit-scrollbar-thumb 
    background: $Green

.container-trace::-webkit-scrollbar-thumb:hover 
    background: $Grey5

/* EQOrigen */
.EQorigen
  
    .container-origin-tabs
        margin: 22px 0px

    .preferedorigin
        color: $Green
    .vieworigen
        font-size: 30px
        cursor: pointer
        
    .TableDetails
        width: 60%
        margin: auto 
        overflow-x: auto
        .EQHide
            display: none
        td
            padding: 5px 20px    
            width: 50%
            line-height: 1.4    
            
    .origincontributors
        #OriginInvisibleData
            display: none
        .subtitle 
            color: $Grey7
            font-family: "Oxigen"

/* EQOrigen */

@media (max-width: $Small)
  .EQorigen
    .TableDetails
      width: 100%
      td
        padding: 5px 15px
        width: auto