.mf-container-main
  margin: 0rem 1.5rem 1.5rem 1.5rem
  border: 2px solid $Grey1
  padding-top: 2rem

  .EQmf-main
    height: 425px
    display: -ms-grid
    display: grid
    -ms-grid-columns: auto
    grid-template-columns: auto
    -ms-grid-rows: 100px auto
    grid-template-rows: 100px auto
    grid-template-areas: "EQmfTable-container" "EQBeachBall"
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center

    .EQmfTable-container
      width: 820px
      height: 425px

      .EQmfTable
        width: 100%
        height: 100px
        -webkit-box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
        -moz-box-shadow: -7px 10px 14px rgba(235,235,235,1)
        box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)

        .EQmfTable-head
          background: $PGreen

        tr
          height: 33.3px

          &:nth-child(even)
            background: $Grey3

        th
          color: $White
          border-right: 1px solid $White
          font-family: "Roboto-Bold"
          font-size: 12pt

        .right-item
          border-right: none

        td
          border-right: 1px solid $PGreen
          font-family: "Roboto"
          font-size: 11pt

    .EQBeachBall
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center

      img
        height: 250px

@media (max-device-width: 1023px)
  .mf-container-main .EQmf-main
    height: 415px

    .EQmfTable-container
      width: 730px
      height: 415px

@media (max-device-width: 823px)
  .mf-container-main .EQmf-main
    height: 415px

    .EQmfTable-container
      width: 530px
      height: 415px

@media (max-device-width: 623px)
  .mf-container-main .EQmf-main
    height: 415px

    .EQmfTable-container
      width: 330px
      height: 415px

@media (max-device-width: 423px)
  .mf-container-main .EQmf-main
    height: 415px

    .EQmfTable-container
      width: 250px
      height: 415px