.ExcelModal
    display: flex
    justify-content: center
    align-items: center
    width: 80%
    margin: auto
    .QExcelModal
        display: grid
        justify-content: center
        align-items: center
        background: $White
        padding: 20px
        position: relative
        &::before
            content: ''
            position: absolute
            left: 0
            top: 0
            right: 0
            bottom: 0
            border: 6px solid $Grey6
        .QExcelIcon
            display: flex
            align-items: center
            span
                margin: 0 10px
        .QExcelClose
            z-index: 1000
            padding: 20px 10px
            display: flex
            justify-content: flex-end
            cursor: pointer
            span
                cursor: pointer
                color: $Grey6
        .QExcelBody
            z-index: 1000
            min-width: 700px
            p
               padding: 10px 0
            small
               font-size: 10px
            .QDownload
                display: block
                cursor: pointer
                span
                    display: block
                    text-decoration: underline
                    color: $BlueHref
                .QDownloadLink
                    display: none
                    position: fixed
                    bottom: 0
                    left: 0
                &:hover
                    .QDownloadLink
                        display: block
                        background: $Grey1
                        width: 100%
                        color: $Black
            .QDownloadDesc
                opacity: .5
                color: $Grey6
.flexItemCatalog
    display: flex
    justify-content: flex-start
    align-items: center
    img
        width: auto
        margin-left: 10px
.QTableModal_loading
    display: grid
    align-items: center
    justify-items: center
    margin: auto
    width: 200px
    .MuiLinearProgress-barColorPrimary
        background-color: $Green !important
    h3
        display: block
        text-align: center
        color: $White
.container-table-consult
    td, th
        font: inherit
    .MuiInput-underline::after
        border-bottom: none !important
    .TensorMinHeightImg
        max-width: 35px
    .MoreInfoTableConsult
        background: $Green
        color: $White
        text-decoration: none
        height: max-content
        display: block
        border-radius: 5px
        padding: 5px
        &:hover
            text-decoration: underline
            cursor: pointer
    tr td
        text-align: center
        padding: 12px
        &:hover
            cursor: pointer
    tr.selected 
        background-color: #F1F7F2 !important
    tr.selected td
        cursor: auto !important
    th
        background: $Green !important
        border-right: 1px solid $White
        border-left: 1px solid $White
        span
            justify-content: center
    h6
        font-size: 20px
        font-weight: normal
    
    .tableConsultCell
        display: flex
        justify-content: center
        padding: 0 24px 0 24px

    .tableConsultFooter
        display: flex
        align-items: center

    .title-table-consult
        display: flex
        justify-content: space-between
        align-items: center
        padding: 2px 24px
        height: 55px
    .content-table-consult
        overflow: scroll
        table
            td,th
                min-width: 100px
                height: 50px
                font-size: 15px
            .id
                min-width: 129px
            .place
                width: 150px
            .MajorTable
                min-width: max-content !important
                padding: 0 10px
            .time
                min-width: 150px
            .Qactions_button
                min-width: 180px
                outline: none
                color: $PGreen
                text-decoration: underline
                border: none
                cursor: pointer
            
            tbody tr:nth-child(odd) 
                background-color: $Grey2

            tbody tr:nth-child(even) 
                background-color: $White

            #encabezado
                background-color: $PGreen3
                color: $White
                th
                    border: 0.5px solid $White
                

    .pagination-table-consult
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0px 24px
        .pagination_loading
            display: flex
            align-items: center
            width: max-content
            padding: 0 10px
            .pages_loaded
                font-size: 14px
                padding-left: 5px
        .pagination-results 
            p
                font-size: 14px
        .pagination-buttons
            margin: 0px 0px 0px 20px


.container-table-consult-movil
    h6
        font-size: 20px
        font-weight: normal
    .title-table-consult-movil
        display: block
        // align-items: center
        padding: 0.5rem 1rem
        // justify-content: space-between
        .container-title
            display: flex
            justify-content: space-between
            align-items: center
            margin-bottom: 0.5rem
        .filter-inputs
            padding-top: 0 !important
            padding-left: 5px
    .content-table-consult-movil
        width: 100%
        overflow-y: scroll
        .tableHeader
            width: 100%
            display: grid
            grid-template-areas: "tableColumnHeader" "tableColumnHeader"
            grid-template-columns:  50% 50%
            grid-template-rows: 100%

            .tableColumnHeader
                // border: 1px solid black
                padding: 0.5rem
                text-align: center
        .tableItem
            width: 100%
            display: grid
            grid-template-areas: "tableColumnItem" "tableColumnItem"
            grid-template-columns:  50% 50%
            grid-template-rows: 100%

            .tableColumnItem
                // border: 1px solid black
                padding: 0.5rem
                text-align: center

        .tableHeaderTablet
            width: 100%
            display: grid
            grid-template-areas: "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet"
            grid-template-columns:  20% 20% 20% 20% 20%
            grid-template-rows: 100%

            .tableColumnHeaderTablet
                // border: 1px solid black
                padding: 0.5rem
                text-align: center
        .tableItemTablet
            width: 100%
            display: grid
            grid-template-areas: "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet" 
            grid-template-columns:  20% 20% 20% 20% 20%
            grid-template-rows: 100%
            align-items: center

            .tableColumnItemTablet
                // border: 1px solid black
                padding: 0.5rem
                text-align: center
        
        .content-accordion
            .Qactions_button
                text-decoration: none
                color: #667F00
            div
                padding: 0.5rem 0rem
                
        // overflow-y: scroll
        // overflow-x: auto
        // .QMobilCell
        //     width: 100%
        //     // height: max-content
        //     // width: max-content
        //     // min-width: 100%
        //     &:nth-child(odd)
        //         background: $Grey1
        //         border-bottom: 1px solid $Grey19
        //         border-top: 1px solid $Grey19
        //     &:nth-last-child(1)
        //         border-bottom: none
        //     .QMobilColumn
        //         display: flex
        //         justify-content: flex-start
        //         border-bottom: 1px solid $Grey19
        //         position: relative
        //         &::before
        //             content: ''
        //             position: absolute
        //             left: 45%
        //             height: 99%
        //             border: 1px solid $Grey19
        //         h4
        //             margin: 0
        //             width: 100%
        //             height: 100%
        //             padding: 20px 25px

        //         span
        //             display: flex
        //             justify-content: flex-start
        //         span, a
        //             padding: 20px 25px
        //             width: 100%
        //         a
        //             color: $PGreen
        //             text-decoration: underline
    
    .pagination-table-consult-movil
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0px 24px
        .pagination_loading
            width: max-content
            display: flex
            align-items: center
            padding: 0 10px
            .pages_loaded
                font-size: 14px
                padding-left: 5px
        .pagination-results 
            p
                font-size: 14px
        .pagination-buttons
            margin: 0px 0px 0px 20px
@media ( max-width: 840px )
    .title-table-consult-movil
        display: block !important
        h6
            display: block
            padding-left: 10px
@media ( max-width: 445px )
    .pagination-table-consult-movil
        padding: 10px 0 !important
        display: grid !important
        position: relative
        height: 120px
        .pagination-results
            position: absolute
            left: 30%
            top: 10px
            p
                display: inline
        .pagination_loading
            position: absolute
            left: 25%
        .pagination-buttons
            width: 100%
            position: absolute
            bottom: 0
            margin: 0 !important
            display: flex !important
            justify-content: space-between !important
    
@media (max-width: 374px)
    #chip
        max-width: 220px
        font-size: 7pt
    .container-table-consult-movil
        h6
            font-size: 20px
            font-weight: normal
        .content-table-consult-movil
            font-size: 10pt
            table
                tr
                    td
                        width: 70%
                        height: 80px
                        padding: 0rem 0.5rem

                        a
                            font-size: 8pt
                    th 
                        width: 30%
                        height: 80px
                        padding: 0rem 0.5rem
        
// .MuiSelect-selectMenu
//     min-height: 2rem !important
@media ( max-width: 800px )
    .QExcelBody
        min-width: 90% !important
    
                
