.stations_list_download
    text-decoration: none
    color: $Green
    padding: 5px 10px
    margin: 10px
    font-size: 10pt
    &:hover
        background: $DarkGray
        color: $White
        border-radius: 5px    
.Settings_label
    display: flex
    align-items: center
    justify-content: space-around
    img
        width: 20px
        margin-right: 10px
    span
        display: block
.Settings_container
    padding: 2px
    .Settings_header
        background: $Green
        color: $White
        display: block
        text-align: left
        padding: 10px
        div
            display: flex
            align-items: flex-start
            justify-content: flex-start
            padding: 2px 0
            span
                margin-left: 3px
    .Body_settings
        background: $Grey1
        color: $Grey6
        padding: 10px
        position: relative
        &::before
            content: ''
            position: absolute
            width: 100%
            height: 1px
            top: 100%
            background: $White
        div
            display: flex
            align-items: center
            justify-content: flex-start
            span
                margin-left: 3px
    .Footer_settings
        background: $Grey1
        padding: 10px
        display: flex
        justify-content: center
        align-items: center
        a
            text-decoration: none
            padding: 10px
            text-align: center
            background: $Green
            color: $White