$GovBlue:        #2d66d1
$LegalBl:        #3366CC
$Blue:           #337ab7
$BlueHref:       #0700ED
$Blue0:          #33b5e5
$Blue2:          #1d4263
$Blue4:          #4285f4
$Blue5:          #213551
$Blue6:          #3f51b5
$Black:          #000000
$Black1:         #111111
$Black2:         #212529
$ModalGrey:      #2d2d2d
$MenuBlack:      #33362d
$MenuGrey:       #4B4C48
$Grey0:          #cccccc
$SoftGrey:       #DCDCDC
$SoftGrey1:      #fafafa
$Grey1:          #f1f1f1
$Grey2:          #f2f2f2
$GreyHover:      #f5f5f5
$Grey3:          #eae8ea
$Grey4:          #E0E0E0
$Grey5:          #636363
$Grey6:          #646464
$Grey7:          #535353
$Grey10:         #E6E6E6
$Grey11:         #6A6A6A
$Grey12:         #696969
$Grey13:         #dee2e6
$Grey14:         #ececec
$Grey16:         #333333
$Grey17:         #F2F2F2
$Grey18:         #BABABA
$Grey19:         #D9D9D9
$Grey23:         #626262
$BDGrey:         #D1D1D1
$GreyTlev:       #D4D2D2
$GreySocialCell: #8FBC8F
$PGreen:         #667f00
$PGreen1:        #637b31
$PGreen2:        #9fbb2d
$PGreen3:        #598000
$PYellow:        #c4d92d
$YeRadiac:       #C9D743
$YeRadiac2:      #a6bb3f
$YeRadiac3:      #8ca448
$red:            #c75346
$error:          #f44336
$Brown:          #813e03
$Shadow1:        #ebebeb

$CorpGreen:      #069169
/**EQMarkersColors
$markerRed:      #FF0000
$markerYellow:   #EBEB00
$markerGreen:    #00FF00
$markerBlue:     #0000FF
$markerMagenta:  #C800F6
$markerActivate: #7AD4E2
/**EQMarkersColors

/** 2024 Colors
$Green:       #005833
$OranYellow:  #D7A53C
$LightGray:   #F6F6F6
$DarkGray:    #686868
$White:       #FFFFFF
$Gray:        #DFDFDF
$GrayText:    #3E3E33

$SGC: $OranYellow
