.EQUploads
   display: -webkit-box
   display: -ms-flexbox
   display: flex
   justify-content: center
   align-items: center
   gap: 24px
   padding: 20px

   a:hover
      text-decoration: none

   .EQCard
      width: 165px
      margin: 0
      .EQCardImg
         min-height: 130px
         img
            width: 87%
         &:hover
            opacity: 0.7
         img.FullWidth
            width: 100%  

@media (max-width: $Small)
   .EQUploads
      .EQCard
         width: 25vw
   
@media (max-width: $XSmall)
   .EQUploads
      display: grid
      grid-template-columns: 1fr 1fr
      gap: 18px
      .EQCard
         margin: 0
         max-width: unset
         min-width: 100px
         width: 100%
         .EQCardImg
            height: 120px
            img
               height: 100%
