@import S-Reusable/_Reusable
@import S-Home/_EQHome
@import S-Query/_Query
@import S-EQDetail/_EQDetail
@import S-EQViewer/_EQViewer
@import S-EQDetailVolcano/_EQDetailVolcano
@import S-NotFound/_NotFound
@import S-Loader/_Loader
@import S-VViewer/_VViewer
@import S-TableConsultation/_TableConsultation
@import S-CatalogoMapa/_CatalogoMapa

.c100
   z-index: 100

.c200
   z-index: 200

.c300
   z-index: 300

.c400
   z-index: 400

.c500
   z-index: 500

.c600
   z-index: 600

.c700
   z-index: 700

.c800
   z-index: 800
.MuiFormHelperText-filled
   color: red !important
#standard-number-helper-text
   color: red !important

.max_font
   font-size: x-large
   #container-resumen-1
      grid-template-rows: 70px auto
   #leafletMiniMapId
      width: 100%

.initial_font
   font-size: initial

.small_font
   font-size: small

.min_font
   font-size: x-small

.High_contrast
   -webkit-filter: invert(1)
   filter: invert(1)
   background-color: $Black
   table
      tr
         background: $White !important
   .Services_icon_on img
      filter: none !important
.leaflet-container
   height: 100vh
   z-index: 1
.LoadingOrder
   .MuiLinearProgress-barColorPrimary
      background-color: $CorpGreen !important

.HideElement
   display: none !important

.Link
   cursor: pointer
