
.EQDinamicMap
    margin-bottom: 1rem


#dinamic_map
    width: 100%
    height: 840px
    position: relative

.leaflet-top
    top: 15rem


@media (min-device-width: 768px) and (max-device-width: 1024px)
    #dinamic_map
        width: 100%
        height: 375px
        position: relative

@media (max-device-width: 767px)
    #dinamic_map
        width: 100%
        height: 555px
        position: relative