.EQLegalHd
  grid-area: EQLegalHd
  margin-bottom: 5px
#accessibility
  cursor: pointer
  span
    width: 30px
    height: 30px
    display: flex
    justify-content: center
    align-items: center
    transition-duration: .3s
    img
      width: 100%
      height: 100%
  .global_view
    width: 80px
    .button_header
      opacity: .5

.EQButtonActions
  grid-area: EQButtonActions
  position: relative
  display: -ms-grid
  display: grid
  height: 70px
  -ms-grid-rows: 100%
  grid-template-rows: 100%
  -ms-grid-columns: 6% 74% 20%
  grid-template-columns: 6% 84% 10%
  grid-template-areas: "menubutton sgc-logo-container container-buttons"
  background: $Green
  z-index: 100
  #MapServices
    top: 100% !important
  .container-buttons
    .list-buttons .item-buttons-list .button-header
      filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)
    .activate_services
      width: 30px
      filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)

  .sgc-logo-container
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center

    .sgc-logo-a
      display: none

    .sgc-logo-b
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      padding-right: 50px

      img
        width: 135px

  .container-buttons
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: end
    -ms-flex-pack: end
    justify-content: center
    margin-right: 50px
    height: 70px

  .container-buttons ul
    // list-style-type: none
    // margin: 0
    // padding: 0
    // display: flex
    // align-items: center
    .list-buttons
      margin: 0rem 50px
      
      .item-buttons-list
        margin: 0rem 0.1rem
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        -webkit-box-align: center
        -ms-flex-align: center
        align-items: center
      

        

  .menubutton
    grid-area: menubutton
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    align-self: center
    justify-self: center
    -ms-flex-line-pack: center
    align-content: center
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center

    .burger_hamburger-inner__14XT2
      background: $White

      &::after, &::before
        background: $White


.container-buttons ul
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  align-items: center
  
  #ViInfo
    visibility: hidden
    
  li
    float: left
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    height: 30px
    cursor: pointer
    // height: 70px

    a
      display: block
      color: white
      text-align: center
      padding: 16px
      text-decoration: none

    #mapLink
      padding: 0px

    .material-icons
      color: $White
      font-size: 28px
      height: 28px
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 28px

    + li
      margin-left: 0.5em
      height: 30px

    .button-header
      -webkit-filter: none
      filter: none
      background: none

    &.selected .button-header
      -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)
      filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)

@media (min-width: 1200px)
  .EQButtonActions
    background: $Green
    .container-buttons .list-buttons .item-buttons-list .button-header
      filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important

    .menubutton .burger_hamburger-inner__14XT2
      background: white

      &::after, &::before
        background: white

    .sgc-logo-container
      grid-area: sgc-logo-container
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      -webkit-box-pack: start
      -ms-flex-pack: start
      justify-content: space-between
      height: 100%

    .sgc-logo-a
      display: -webkit-box !important
      display: -ms-flexbox !important
      display: flex !important

      img
        width: 150px

    .sgc-logo-b
      display: none !important

      img
        width: 135px

@media (max-width: 1024px)
  .EQheader
    height: 70px
    -ms-grid-rows: 20px 50px
    grid-template-rows: 20px 50px
    .EQButtonActions
      -ms-grid-columns: 16% 64% 30%
      grid-template-columns: 16% 54% 30%

@media screen and (max-width: 767px)
  .EQheader
    .EQButtonActions
      .sgc-logo-container

        .sgc-logo-a
          display: none

        .sgc-logo-b
          display: -webkit-box
          display: -ms-flexbox
          display: flex
          padding-right: 0px
          align-items: center

          img
            width: 100px
            height: 55px
      
      .container-buttons
        margin-right: 30px

      
    .EQsummary-container .EQsummary
      .summarydata
        font-size: 12pt

        .space-line1, .space-line
          display: block

      .summaryplace
        font-size: 16pt