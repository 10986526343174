.shLegend .StarEpicenter
  width: 100%
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

  span
    font-weight: bold
    font-size: 80%
    font-family: Roboto

  img
    width: 25px
    height: 25px

.EQsh
  height: auto

  .ShMap
    height: auto
    padding: 10px 0
    border: 2px solid $Grey1

    .ShMapContent
      position: relative

      .SHtextHistory
        display: block
        margin: 0 20px

      .ShDisplayMap
        height: 650px
        margin: 10px 20px

        .epicenter_popup
          display: -webkit-box
          display: -ms-flexbox
          display: flex
          -webkit-box-pack: justify
          -ms-flex-pack: justify
          justify-content: space-between
          -webkit-box-align: center
          -ms-flex-align: center
          align-items: center

          span
            margin: 0 10px

      .ShLegendContainer
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        position: relative
        -webkit-box-pack: center
        -ms-flex-pack: center
        justify-content: center
        width: max-content
        margin: auto

        .ShLegend
          position: absolute
          bottom: 20px
          padding: 2.5px 10px
          cursor: pointer
          z-index: 400
          width: max-content

    .EQShTable
      color: $Black
      margin: 40px 0
      padding: 0 20px
      overflow: hidden
      -webkit-box-shadow: -7px 10px 14px -7px $Shadow1
      box-shadow: -7px 10px 14px -7px $Shadow1

      td
        text-align: left
        padding: 14px

      .MuiTableCell-head, .MUIDataTableHeadCell-root-253, .MUIDataTableHeadCell-fixedHeaderCommon-255, .MUIDataTableHeadCell-fixedHeaderXAxis-256, .MUIDataTableHeadCell-fixedHeaderYAxis-257
        background: $PGreen
        color: $White
        border-right: 1px solid $White

      .MuiPaper-root, .MuiPaper-elevation4, .MuiPaper-rounded
        overflow-x: auto

      .shCell img
        width: 20px

      svg
        opacity: 1 !important

      a
        text-decoration: none
        background: $PGreen
        color: $White
        cursor: pointer
        padding: 5px 10px
        border-radius: 10px

        &:hover
          padding: 6px 12px

.ContainerAge
  width: 100%
  display: -webkit-box
  display: -ms-flexbox
  display: flex

  .Cont_icons
    width: 20%
    margin: auto
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center

    .CicleAge
      width: 15px
      height: 15px
      border: 1px solid $Black

    span
      font-family: Roboto
      font-size: 11.5px

@media (min-width: 650px)
  .ShMap
    margin: 22px 40px 22px 40px