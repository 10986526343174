.ListContainer
   padding: 8px
   font-size: 12px
   &.sm
      .RoundedAccordion
         .AccordionContent
            .ContentInfo
               grid-column: 1 / 4

   
.AccordionContainer
   background-color: white
   padding: 3px 5px
   color: $DarkGray
   font-size: 1em
   font-family: 'Nunito Sans', sans-serif
   font-weight: 300
   &.Active
      color: $White
      .RoundedAccordion
         background-color: $Green
      .AccordionContent
         .HeaderContainer
            .HeaderTitle
               color: $White
         .ImgLeft
            img.Animate
               width: 81%
               max-width: 120px

         .ContentInfo
            padding: 10px
            max-height: 1000px
            opacity: 1

      .AccordionMore
         max-height: 1000px
         opacity: 1
         padding: 10px 25px
         
.RoundedAccordion
   border-radius: 15px
   border: 2px solid
   border-color: black
   overflow: hidden
   
   &.Green
      border-color: $Green
   &.Yellow
      border-color: #dcdc1c
   &.Orange
      border-color: $OranYellow
   &.Red
      border-color: red

   .AccordionContent
      display: grid
      grid-template-columns: 22% auto 15%
      padding: 12px
      cursor: pointer
      .ImgLeft
         display: flex
         grid-row: span 2
         justify-content: center
         align-items: center
         img
            display: block
            border-radius: 50%
            aspect-ratio: 1 / 1
            width: 80%
            max-width: 70px
            &.Animate
               transition: .35s ease

      .HeaderContainer
         display: flex
         justify-content: center
         flex-direction: column
         .HeaderText
            display: flex
            flex-direction: column
            padding: 10px
            z-index: 2

         .HeaderTitle
            font-size: 1.25em
            color: $Green
            font-weight: 900
            margin-bottom: 5px

      .IconRight
         display: flex
         justify-content: center
         align-items: center

      .ContentInfo
         cursor: auto
         display: flex
         justify-content: center
         flex-direction: column
         max-height: 0px
         padding: 0 10px
         opacity: 0
         transition: .3s ease
         z-index: 1
         
   
   .Buttons
      display: flex
      justify-content: center
      margin-top: 20px
      margin-bottom: -5px

   .AccordionMore
      max-height: 0
      opacity: 0
      transition: .3s ease
      padding: 0 20px

