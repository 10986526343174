@import VViewerComponents/_VListFilter
@import VViewerComponents/_VListLevels
@import VViewerComponents/_VListMain
@import VViewerComponents/_VPopupVolcanosMarker
@import VViewerComponents/_VVolcanosMarker
@import VViewerComponents/_InfoObservatory
@import VViewerComponents/_ModalLevelActivity
@import VViewerComponents/_ModalLevelActivityMovil
	

.volcanos-list
	background: $White
	height: calc( 100vh - 100px )
	display: grid
	grid-template-areas: "volcanos-list-filter" "volcanos-list-main" "volcanos-list-levels"
	grid-template-columns: 100% 
	grid-template-rows: 160px auto 100px


	.volcanos-list-main
		-webkit-overflow-scrolling: touch
		overflow-y: auto
	.volcanos-list-levels
		overflow: hidden
	
.safari-list
	grid-template-rows: 155px auto auto !important
