
.volcanos-list-filter
	-webkit-box-shadow: 0px 0px 10px $Black
	box-shadow: 3px 0px 10px $Black
	z-index: 1
	position: relative
	.volcanos-list-filter-container
		height: 100%
		grid-area: volcanos-list-filter-container
		display: grid
		grid-template-areas: "volcanos-list-filter-title" "volcanos-list-filter-main"
		grid-template-columns: auto
		grid-template-rows: auto 70%
		// grid-gap: 0.5rem
		

		.volcanos-list-filter-title
			background: $Green
			color: $White
			display: flex
			align-items: center
			padding: 0rem 1rem
		
	.volcanos-list-filter-main
		padding: 5px 15px 10px
		background: $Grey1
		display: grid
		grid-template-columns: 100% 
		min-height: 110px

		.order-filter
			font-size: 12px
			.MuiInput-underline
				&::after
					border-bottom: $PGreen

		.search-filter
			.MuiFormLabel-root.Mui-focused
				color: $Green
				-webkit-transform: translate(0, 1px) scale(0.75)
				-moz-transform: translate(0, 1px) scale(0.75)
				-ms-transform: translate(0, 1px) scale(0.75)                                 

			.MuiInputBase-root.MuiInput-root
				&::after
					border-bottom: 2px solid $Green
				input
					padding: 6px 0 7px
			label+.MuiInputBase-root.MuiInput-root
				margin-top: 8px



				

					
				
