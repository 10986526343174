.HmMenuBurger
   position: absolute
   right: 20px
   bottom: calc( 50% - 40px )
   transform: translateY(-50%)
   display: none
   @media ( max-width: $Medium )
      display: unset

.HmProducts
  list-style: none
  margin: auto
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  font-size: 0.85rem

  ul
    list-style: none

    a
      text-decoration: none



@media (min-width: 200px)
   .HmProductsContent
      display: none
      background: $White
      width: 100%
      z-index: 20
      position: absolute

      .HmProducts
         display: block
         width: 100%

         p
            width: 100%
            display: -webkit-box
            display: -ms-flexbox
            display: flex
            -webkit-box-align: center
            -ms-flex-align: center
            align-items: center
            -webkit-box-pack: justify
            -ms-flex-pack: justify
            justify-content: space-between
            position: relative

            span
               color: $Grey11

            img
               width: 15px
               height: 8px

         .HmPrListHome
            .HomeIconMenu
               display: none
               width: 100%

            a
               text-decoration: none
               width: 100%

         .HmPrList
            display: block
            padding: 10px 20px
            border-bottom: 0.1px solid $BDGrey

            .HmPrListTXT
               font-weight: 700

            .ArrowIcon
               position: absolute
               right: 0
               top: 50% 
               transform: translateY(-50%)
               font-size: 2.4rem
               color: $SGC
               &:hover
                  opacity: .7

            a
               color: $Grey6
               text-decoration: none

         .Second_level
            margin-top: 10px
            display: none
            background: $Grey10
            .HmPrListTXT
               font-weight: 500

            .HmPrListIcon
               position: relative
               display: -webkit-box
               display: -ms-flexbox
               display: flex
               -webkit-box-pack: justify
               -ms-flex-pack: justify
               justify-content: space-between
               -webkit-box-align: center
               -ms-flex-align: center
               align-items: center
               margin: 0 12px

               a
                  width: 100%

               img
                  width: 15px
                  height: 8px
                  margin-left: 15px

            li
               padding: 10px 0px
               border-bottom: 0.1px solid $BDGrey

            .Third_level
               margin-top: 10px
               display: none
               background: $GreyTlev
               padding: 0 15px

               li
                  display: -webkit-box
                  display: -ms-flexbox
                  display: flex
                  -webkit-box-pack: justify
                  -ms-flex-pack: justify
                  justify-content: space-between
                  -webkit-box-align: center
                  -ms-flex-align: center
                  align-items: center

                  a
                     width: 100%

                  img
                     width: 15px
                     height: 8px

@media (min-width: $Medium + 1px)
   .HmProductsContent
      -webkit-transition: 0.5s
      -o-transition: 0.5s
      transition: 0.5s
      position: relative
      background: $Gray
      &::before
         content: ''
         position: absolute
         left: 0
         top: 100%
         background: $SGC
         width: 100%
         height: 3px

      .HmProducts
         width: 100%
         max-width: $HzMaxWidth
         display: -webkit-box
         display: -ms-flexbox
         display: flex
         -webkit-box-pack: center
         -ms-flex-pack: center
         justify-content: center
         @media (min-width: $Large)
            width: 80%
            .HmPrList
               flex-grow: 1

         .Second:hover
            background: $GreyHover

         .HmPrListIcon img, .ArrowIcon
            display: none !important

         .HmPrListHome
            border-right: none !important

            a
               display: none

            .HomeIconMenu
               display: unset

         .HmPrListWeare
            border-left: 0.5px solid $White

         .HmPrList
            border-bottom: none
            border-right: 2px solid $White
            padding: 3px 20px
            margin: 5px 0
            line-height: 19px
            &>p>.HmPrListTXT
               text-align: center

            &:hover .Second_level
               display: block
               padding: 5px 0
               position: absolute
               background: $White
               border-top: 7.5px solid $Grey19
               margin: 4px 0 0 -20px
               min-width: 160px
               width: 200px

               span
                  color: $Grey6

                  &:hover
                     cursor: pointer

               li
                  padding: 5px 20px
                  width: 100%
                  border-bottom: 1px solid $Grey1

                  &:hover
                     cursor: pointer

               a
                  color: $Grey6

                  &:hover
                     color: $SGC

               .third:hover
                  position: relative
                  background: $GreyHover

                  span
                     color: $SGC

                  .Third_level
                     margin-top: 5px
                     border-top: 3.5px solid $SGC
                     background: $White
                     display: block
                     padding: 5px 0
                     width: 160px
                     position: absolute
                     top: -13px
                     left: 100%
                     width: 200px

                     li
                        &:hover
                           background: $GreyHover

                        a
                           color: $Grey6

                           &:hover
                              color: $SGC

                        img
                           display: none

               .Second:hover
                  color: $SGC
                  background: $GreyHover

            .HmPrListTXT
               color: $DarkGray
               text-decoration: none

            p
               -webkit-box-pack: center
               -ms-flex-pack: center
               justify-content: center
               -webkit-box-align: center
               -ms-flex-align: center
               align-items: center

               span
                  color: $Grey6
                  line-height: 20px

                  &:hover
                     color: $PYellow
                     cursor: pointer

            .HmPrListTXT:hover
               color: $SGC
               cursor: pointer

            .HmPrListHome a
               display: none