/**_NotFound**/
.page-404 
    display: grid
    grid-template-areas: "menu-404 not-404"
    grid-template-columns: 20% 80%
    grid-template-rows: 100%

    .menu-404-tablet
        display: none

    .menu-404
        height: 100vh
        display: grid
        grid-template-areas: "title-menu-404" "content-menu-404"
        grid-template-columns: 100%
        grid-template-rows: 15% auto

        .title-menu-404
            display: flex
            justify-content: center
            align-items: center
            font-size: 15pt
            font-weight: bold
            color: $White
            background: $PGreen
       

        .content-menu-404
            display: grid
            grid-template-areas: "lines-404" "question-404" "options-menu-404"
            grid-template-columns: 100%
            grid-template-rows: 7% 7% auto
            color: $White
            background: #33362D

            .lines-404
                hr
                    margin: .3rem 0rem
                    border-left: none
                    border-right: none
                    border-bottom: none
                    border-top: 2px solid $White

            .question-404
                display: flex
                justify-content: center
                align-items: center
                color: $PGreen
                p
                    margin: 0px

            .options-menu-404
                div
                    display: flex
                    justify-content: center
                    align-items: center
                    margin: .5rem 0rem
                    border-bottom: 1px solid $PGreen

                    a
                        text-align: center
                        text-decoration: none
                        color: $White
                        p
                            margin: .3rem 0rem
                    img
                        width: 70px

        

    .not-404
        display: grid
        grid-template-areas: "bg-404" "info-404"
        grid-template-columns: auto
        grid-template-rows: 90% 10%

        .bg-404
            display: flex
            justify-content: center
            align-items: center
            img
                width: 60%

        .info-404
            display: flex
            justify-content: center
            align-items: center
            font-size: 20pt
            font-weight: bold

@media screen and ( max-width: 1024px )
    .page-404 
        height: 100vh
        display: grid
        grid-template-areas: "not-404" "menu-404-tablet"
        grid-template-columns: 100%
        grid-template-rows: auto auto

        .menu-404-tablet
            display: grid
            grid-template-areas: "question-404-tablet" "options-menu-404"
            grid-template-columns: 100%
            grid-template-rows: 20% auto
            background: black
            color: $PGreen

            .question-404-tablet
                display: flex
                justify-content: center
                align-items: center
                font-size: 25pt
                font-weight: 600

            .options-menu-404
                display: grid
                grid-template-areas: "home-option-404-tablet sismos-option-404-tablet volcanos-option-404-tablet"
                grid-template-columns: 33% 34% 33%
                grid-template-rows: 100%

                div
                    display: flex
                    justify-content: center
                    align-items: center
                    margin: .5rem 0rem
                    border-right: 1px solid $PGreen
                    border-left: 1px solid $PGreen

                    a
                        text-align: center

                        p
                            margin: .3rem 0rem
                            font-size: 25pt
                            font-weight: 600
                    img
                        width: 150px

        .menu-404
            display: none
            
        .not-404
            display: grid
            grid-template-areas: "bg-404" "info-404"
            grid-template-columns: 100%
            grid-template-rows: 90% 10%

            .bg-404
                img
                    width: 100%


@media screen and ( max-width: 767px )
    .page-404 
        height: max-content
        display: grid
        grid-template-areas: "not-404" "menu-404-tablet"
        grid-template-columns: 100%
        grid-template-rows: auto auto
        

        .menu-404-tablet
            display: grid
            grid-template-areas: "question-404-tablet" "options-menu-404"
            grid-template-columns: 100%
            grid-template-rows: auto auto

            .question-404-tablet
                display: flex
                justify-content: center
                align-items: center
                font-size: 15pt
                font-weight: 600

            .options-menu-404
                display: grid
                grid-template-areas: "home-option-404-tablet" "sismos-option-404-tablet" "volcanos-option-404-tablet"
                grid-template-columns: 100%
                grid-template-rows: auto auto auto

                div
                    margin: 0rem
                    a
                        text-align: center
                        p
                            margin: .3rem 0rem
                            font-size: 15pt
                            font-weight: 600
                    img
                        width: 120px
            
        .not-404

            .info-404
                font-size: 10pt

            .bg-404
                img
                    width: 100%
/**_NotFound**/
