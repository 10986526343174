/**loader.sass

.block
  width: 100%
  padding: 80px 0
  border-bottom: 2px solid $Grey16
  background: $Grey14
  height: 100vh
  font-family: lato, helvetica, arial, sans-serif

  .block-in
    width: 300px
    margin: 80px auto

  .gear
    &::before
      content: ""
      position: absolute
      top: 50%
      left: 50%
      border-radius: 50%
      border-style: solid
      border-top-color: $YeRadiac
      border-right-color: $YeRadiac2
      border-bottom-color: $YeRadiac3
      border-left-color: $PGreen
      -webkit-transform: translate(-50%, -50%)
      -ms-transform: translate(-50%, -50%)
      transform: translate(-50%, -50%)
      -webkit-animation: rotate 1.5s infinite ease-in-out
      animation: rotate 1.5s infinite ease-in-out
      border-width: 10vh

    &::after
      content: ""
      position: absolute
      top: 50%
      left: 50%
      border-radius: 50%
      border-style: solid
      border-top-color: $YeRadiac
      border-right-color: $YeRadiac2
      border-bottom-color: $YeRadiac3
      border-left-color: $PGreen
      -webkit-transform: translate(-50%, -50%)
      -ms-transform: translate(-50%, -50%)
      transform: translate(-50%, -50%)
      -webkit-animation: rotate 1.5s infinite ease-in-out
      animation: rotate 1.5s infinite ease-in-out
      width: 30vh
      height: 30vh
      border-width: 2.5vh
      animation-direction: reverse

@-webkit-keyframes rotate
  0%
    -webkit-transform: translate(-50%, -50%) rotate(0)
    transform: translate(-50%, -50%) rotate(0)

  100%
    -webkit-transform: translate(-50%, -50%) rotate(360deg)
    transform: translate(-50%, -50%) rotate(360deg)

@keyframes rotate
  0%
    -webkit-transform: translate(-50%, -50%) rotate(0)
    transform: translate(-50%, -50%) rotate(0)

  100%
    -webkit-transform: translate(-50%, -50%) rotate(360deg)
    transform: translate(-50%, -50%) rotate(360deg)

/**loader.sass