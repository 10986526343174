/**_LegalHeader*
.LegalHeader
  background: $LegalBl
  display: flex
  align-items: center
  justify-content: flex-start
  width: 100%
  height: 40px
  padding: 0 15px
  a
    display: flex
    align-items: center
    height: 100%
    padding: 7px
    img
      height: 100%

@media ( max-width: $XSmall )
   .LegalHeader
      justify-content: flex-end
   
/**_LegalHeader*