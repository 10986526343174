/**
    667f00 : $PGreen
    646464 : $Grey6
    535353 : $Grey7
    696969 : $Grey12
    c75346 : $red
    **/
.normal_contrast
    .contrast_green
        background: $PGreen
    .contrast_grey
        background: $Grey6
    .contrast_greys7
        background: $Grey7
    .contrast_greys12
        background: $Grey12
    .contrast_red
        background: $red
.black_contrast
    .contrast_green
        background: $Black
    .contrast_grey
        background: $Black
    .contrast_greys7
        background: $Black
    .contrast_greys12
        background: $Black
    .contrast_red
        background: $Black