@import ReusableComponents/_Colors
@import ReusableComponents/_Breakpoints
@import ReusableComponents/_ColorMarker
@import ReusableComponents/_Mixins
@import ReusableComponents/_Fonts
@import ReusableComponents/_Tooltips
@import ReusableComponents/_LegalHeader
@import ReusableComponents/_LegalFooter
@import ReusableComponents/_ModalImg
@import ReusableComponents/_Notifications
@import ReusableComponents/_NotificationCard
@import ReusableComponents/_NotificationCardVolcano
@import ReusableComponents/_Services
@import ReusableComponents/_SettingsPopup
@import ReusableComponents/_Map
@import ReusableComponents/_CarrouselIcons
@import ReusableComponents/_Table
@import ReusableComponents/_Map
@import ReusableComponents/_RoundedAccordion
@import ReusableComponents/_SettingsPanel
@import ReusableComponents/_SeismicitySummary
@import UI/_CustomUI

.SgcContent
   font-size: .875rem // 14px default
   color: $GrayText

body
   .Title
      font-family: 'Montserrat', sans-serif
      font-weight: 700 
   .TitleText
      font-size: 1rem // 16px
   .SubText
      font-size: .8125rem // 13px
   .RegularText
      font-size: .875rem // 14px

   .Emphasis-Gray
      color: $Grey6
   a
      text-decoration: none
      color: $Green
      &:hover
         text-decoration: underline

   button, label
      font-family: inherit

   @media ( max-width: $Small )
      font-size: .8125rem // 13px
      .TitleText
         font-size: 0.9375rem // 15px
      .SubText
         font-size: 0.75rem // 12px

.hidden
   visibility: hidden

.selectable
   cursor: pointer
   &:hover, &:focus, &:active
      background-color: rgba(0, 0, 0, 0.04)
.pointer
   cursor: pointer

.SGCProgress
   color: $SGC !important

.mb-1
   margin-bottom: 8px
.mt-1
   margin-top: 8px

.GrayBordered
   border: 2px solid $Grey2
   border-radius: 8px

.TextEllipsis
   white-space: nowrap
   overflow: hidden
   text-overflow: ellipsis

button.SgcButton
   font-weight: bold
   background: $LightGray 
   margin: 0px 18px 0px 18px
   min-width: 10rem
   border-radius: 8px
   min-height: 1.7rem
   height: max-content
   border: 1px solid $White
   outline: none
   &.Oxigen
      font-family: Oxigen
      font-size: 10px
   &.Nunito
      font-family: 'Nunito Sans', sans-serif
      font-size: 11px
   a
      display: block
      width: 100%
      padding: 8px
      color: $Green
      text-decoration: none
   &:hover
      border: 1px solid $White 
      cursor: pointer
      background: $White

table
   border-spacing: 0
   border-collapse: collapse
   width: 100%
   text-align: center

   tr
      border-bottom: 1px solid $Grey4


// Mui Bugs
// .MuiGrid-spacing-xs-4 > .MuiGrid-item
//    padding-right: 0 !important
//    padding-bottom: 0 !important
