/**_QueryBody.sass*
.QBodyDisabled
  opacity: .5
  position: relative
  &::before
    content: ''
    width: 100%
    height: 100%
    position: absolute
    left: 0
    z-index: 1000
#QrangeCircle
  text-align: center
  padding: 10px 10px 0
  .radio-input
    input
      width: 100%

@media (min-width: 200px)
  .Qselections
    display: -ms-grid
    display: grid
    -ms-grid-rows: auto
    grid-template-rows: auto

  

@media (min-width: 800px)
  .Qselections
    -ms-grid-columns: 50% 50%
    grid-template-columns: 50% 50%

    .selector1
      padding-right: 10px

.QmodalOpen
  display: -webkit-box
  display: -ms-flexbox
  display: flex

  .QmodalContainer
    height: 300px
    width: 70%
    margin: auto
    border: 8px solid $Grey12
    background: $White
    color: $Grey12

    .QmodalHead
      padding: 10px 0
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center

    .QmodalBody
      padding: 20px

      p
        text-align: justify

      button
        float: right
        color: $GovBlue
        border: none
        background: transparent
        padding: 20px 20px 0 0

.QMap
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center

#map
  width: 100%
  height: 400px

  .leaflet-popup-content-wrapper
    border-radius: 20px !important
    padding: 8px
    #QPopup
      color: $Black
      text-align: center
      min-width: unset
    
  a.leaflet-popup-close-button
   top: 2px
   right: 2px
   &:hover
      text-decoration: none


  .QMapContent
    background: $White
    font-family: Roboto, sans-serif
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
    box-shadow: 0 2px 5px 0 rgba(253, 148, 148, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
    border: 2px solid $Black
    padding: 10px 10px 10px

    .QMapHeader
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: justify
      -ms-flex-pack: justify
      justify-content: space-between
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      border-bottom: 1px solid $Grey13
      padding: 0.2rem

      h5
        font-weight: 300
        font-size: 1.25rem

      button
        padding: 0.4rem .6rem
        cursor: pointer
        opacity: 0.5
        color: $Black
        text-shadow: 0 1px 0 $White

    .QMapBody
      display: block
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      width: 465px
      height: 480px
      padding: 1rem

      #map
        margin: 10px 0

      #QueryMapModal
        width: 100%
        height: 100%

      #QrangeCircle
        // position: relative
        padding: 10px 0
        display: block

        .radio-input
          display: grid
          grid-template-areas: "slider radio"
          grid-template-columns: 90% 10%
          grid-template-rows: 100%

          span
            // position: absolute
            // top: 0
            // right: 0px
            // padding: 2px
            width: 45px
            height: 45px
            -webkit-box-sizing: border-box
            box-sizing: border-box
            background: $Grey5
            color: $White
            text-align: center
            border-radius: 50%
            display: flex
            justify-content: center
            align-items: center

            &::before
              content: ""
              top: 50%
              position: absolute
              -webkit-transform: translateY(-50%) rotate(45deg)
              -ms-transform: translateY(-50%) rotate(45deg)
              transform: translateY(-50%) rotate(45deg)
              left: -5px
              width: 10px
              height: 10px
              background: $Grey3

          .slider
            display: flex
            align-items: center
            input
              width: 100%
              display: flex
              align-items: center
              // height: 2px
              background: $Black1
              border-radius: 2px
              outline: none

    .QMapFooter
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: end
      -ms-flex-pack: end
      justify-content: flex-end
      padding: 4rem 0 0
      border-top: 1px solid $Grey13

      #close_map
        background: $Grey7
        height: 30px

      #save_changes
        background: $Blue4
        margin-left: 0.25rem
        height: 30px

      button
        border: none
        color: $White
        cursor: pointer
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
        border-radius: 0.125rem
        font-size: 0.81rem
        padding: 0.84rem 2.14rem

.QueryBody
  .Qerror
    -webkit-transition: all .7s
    -o-transition: all .7s
    transition: all .7s
    transition-duration: .7s
    color: red
    small
      display: unset !important
      margin: -20px 0
    .QWarning
      visibility: visible !important
      color: red
      position: absolute
      top: 0
      left: 0

    label
      color: red !important

    input
      color: red !important

  .QBodyContent
    padding: 50px 0 2rem
    .infoMessageContent
      h4
        color: $PGreen
      p
        color: $Grey6
    .infoBodyContent
      h4
        color: $PGreen
      p
        color: $Grey6
      margin: 0rem 0rem 2rem 0rem

    .Q_main
      border-radius: 5px
      background-color: $White
      padding: 15px
    
    .Q_advanced 
      margin-top: 1rem
      .disabledInput
        opacity: .5
        z-index: -1
      .MuiAccordionDetails-root
        display: -ms-grid
        display: grid
        background-color: $Grey17
        padding-left: 21px
        padding-right: 21px
        .MuiOutlinedInput-root
          background-color: $White
      h4
        color: $Grey6

    .Q_action_form
      display: flex

      .Q_action_form_text
        width: 60%
        margin-top: 2rem
        font-size: 10pt
        color: $Grey18

      .Q_action_form_buttons
        width: 40%
        margin-top: 2rem
        display: flex
        button
          width: 100%
          margin: 0rem 0.3rem
          font-weight: bold
        #QConsult
          background-color: $Green
          color: $White
        .QConsultDiabled
          opacity: .6
        

    .QselectionsArea
      display: flex
      .coordinatesArea
        width: 35%
      .mapArea
        width: 65%

    #Qselectionsarea
      margin-top: .5rem
    .Qselections
      width: 100%
    .QselectLayer
      height: max-content !important
    .Qselector-error
      padding: 1rem
      color: red
    .Qselector
      margin: 8px 0
      height: max-content
      width: 100%
      display: grid
      .radio_area
        flex-direction: row
      small
        display: none
      .Qselector_input
        height: 5.1rem
        position: relative
        display: grid
        display: -ms-grid
        align-items: flex-end
        width: 100%
        height: 100%
        .MuiInputLabel-shrink 
          transform: translate(14px, -10px) scale(1.3)
        &.QTime 
          .MuiInputLabel-shrink 
            transform: translate(14px, -6px) scale(1.3)         
        .Qlabel_inputs
          position: relative
          background: $White
          width: max-content
          color: $Black2
          // transform: translate(5%, -65px)
        // select, input[type=number]
          padding: 0 5px
          width: 100%
          height: calc(2.5em + .75rem + 2px)
          box-sizing: border-box
          outline: none
          border: 1px solid $Black
          transition: all 1s
          &:focus
            transition: all 1s
            border: 1px solid $Blue6
            ~ .Qlabel_inputs
              color: $Blue6
              font-weight: bold
      #QLoading
        height: max-content

      .QWarning
        visibility: hidden
        position: absolute
      legend
        padding-bottom: 10px
        font-family: Roboto, sans-serif
        font-weight: 300
        font-size: 1rem !important
        color: $Black2

      #Qdraw_map
        background: $Blue0 !important
        color: $White
        width: max-content
        border-radius: 0.125rem
        border: none
        height: 50px
        margin: 15px auto
        cursor: pointer
        padding: 0.84rem 2.14rem
        -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)

        &:hover
          -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 10px 10px 0 rgba(0, 0, 0, 0.12)
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 10px 10px 0 rgba(0, 0, 0, 0.12)

    .QDate
      display: block !important

      div
        display: flex
        justify-content: space-around
        align-items: center

      label
        padding-bottom: 10px
        font-family: Roboto, sans-serif
        font-weight: 300
        font-size: 1rem !important
        color: $Black2
        position: initial !important
        -webkit-transform: translateY(20px)
        -ms-transform: translateY(20px)
        transform: translateY(20px)
@media ( max-width: 500px )
  .QMapContent
    width: 100%
    .QMapBody
      padding: 0 !important
      width: 100% !important
      height: 300px !important
    .QMapFooter
      padding: 2.6rem 0 0 !important
  .Qselector
    .radio_area
      flex-direction: column !important
@media ( max-width: 825px )
  .QselectionsArea
    display: grid !important
    .coordinatesArea
      width: 100% !important
    .mapArea
      width: 100% !important
      height: 400px
  .Q_action_form
    display: block !important
    .Q_action_form_text
      width: 100% !important
    .Q_action_form_buttons
      width: 100% !important

.flexItemCatalog    
  .dates-icons-option
    margin-right: 8px
    margin-left: 0px !important
    width: 20px
    height: 20px
    border: none
  
/**_QueryBody.sass*
