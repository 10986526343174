/*HomeAside*/
@import _HomeAside
/* HomeMainBanner*/
@import _HomeSearchBar
/*Home & Events Carousel*/
@import _HomeCarousel
/**_HomeServices**/
@import _HomeServices
/**_HomeNews**/
@import _HomeNews
/**HomeTopic**/
@import _HomeTopic

#SGCTitle
  height: 0
  width: 0

/* Main Banner (SearchBar & Carousel) */
.HmMainBanner
   position: relative
   background: $Gray
   background-image: url("../assets/img/Home/textures/Textura5.png")
   background-size: cover 
   &::before
      content: ""
      position: absolute
      top: 0px
      right: 0px
      bottom: 0px
      left: 0px
      background-color: rgba(255, 255, 255, 0.75)


