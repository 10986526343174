/* Mixin para dar estilo a los textos dentro del acordeón de las tarjetas de eventos (sismos y volcanes) */
@mixin text-type-events($font-family, $font-size, $font-weight, $color, $display, $align-items, $justify-content)
	font-family: $font-family
	font-size: $font-size
	font-weight: $font-weight
	color: $color
	display: $display
	align-items: $align-items
	justify-content: $justify-content

/* Para el Encabezado */
.header-text
	@include text-type-events(Roboto, 10pt, bold, "", flex, center, auto)

/* Para los Indicadores (Magnitude, Depth) */
.indicators-text
	@include text-type-events(Roboto, 12pt, bold, "", flex, center, auto)

/* Para el texto "Superficial" que se muestra en la Profundidad (Depth) */
.depth-superficial
	@include text-type-events(Roboto, 6pt, bold, "", flex, center, auto)

/* Para las medidas de los indicadores (Magnitude (Ml), Depth (Km) */
.measures-text
	@include text-type-events(Roboto, 8pt, bold, "", flex, center, center)

/* Para la fecha */
.date-text
	@include text-type-events(Oxigen, 8pt, normal, $Black, flex, auto, auto)

/* Para la información del evento (Cuando se abre el acordeon) */
.info-text
	@include text-type-events(Oxigen, 8pt, normal, $White , "", "", "")

/* Para la información del evento, resaltado con negrita */
.info-text-bold
	@include text-type-events(Roboto, 10pt, bold, $White , "", "", "")
	// -webkit-filter: blur(0.000001px)

/* Para los botones ¿Sintió este sismo? y Mas información */
.buttons-text
	@include text-type-events(Oxigen, 7pt, bold, $Black, "", "", "")

/* Para alinear y justificar al centro un div FLEX*/
.align-justify-center
	display: flex
	justify-content: center
	align-items: center

/* Para alinear y al centro un div FLEX*/
.align-center
	display: flex
	align-items: center

/* Para justificar al centro un div FLEX*/
.justify-center
	display: flex
	justify-content: center

/* Mixin para los iconos de mmi, cdi, depth (Acordeón) */
@mixin dates-icons($width, $height, $border)
	display: flex
	align-items: center
	width: $width
	margin: 6px auto
	height: $height
	border: $border
	justify-content: center

/* Para los iconos de mmi, cdi, depth (Acordeón) */
.dates-icons
	@include dates-icons(25px, 25px, none)

/* Acordeón Event Cards */
.accordion
	display: block
	overflow: hidden

	.buttons
		@extend .align-justify-center
		justify-content: space-evenly
		max-width: 500px
		margin: 0 auto   
		button
			background: $LightGray 
			margin: 0px 5px
			padding: 8px
			min-width: 10rem
			border-radius: 8px
			min-height: 1.7rem
			height: max-content
			border: 1px solid $White
			outline: none 
			a
				color: $Green
				text-decoration: none
			&:hover
				border: 1px solid $White 
				cursor: pointer
				background: $White

	.item
		background: $White 
		color: $Black

	.item.active
		background: $Green
		color: $White 

		.date-text
			color: $White

	
	.item2
		background-color: $Grey1
		color:$Black

	.item-container
		display: grid
		grid-template-columns: 25% 50% 25%
		grid-template-rows: 65px
		grid-template-areas: "magnitude main depth"
		border-bottom: 1px solid
		border-color: $White
		align-items: center
		justify-content: space-between
	
		.magnitude
			@extend .justify-center
			grid-area: magnitude
			border-right: $White 1px solid

			.magnitude-background
				@extend .align-justify-center
				width: 45px
				height: 45px
				background-size: cover
				background-image: url("../assets/icons/EQViewer/Indicators/Magnitude-Background.svg")

		.main 
			grid-area: main
			padding: 0rem .5rem
			
			.place
				@extend .align-center
				height: 50%
				
				h2
					width: 100%
					div
						white-space: nowrap
						overflow: hidden
						text-overflow: ellipsis
				
			.dates
				display: grid
				grid-template-columns: 50% 50%
				grid-template-rows: auto
				grid-template-areas: "container-date container-icons"
				align-items: center
				
				.container-date
					display: flex
					
				.container-icons
					display: flex
					align-items: center
					
					.dates-mmi
						@extend .justify-center
						grid-area: dates-mmi
						
					.dates-cdi
						@extend .justify-center
						grid-area: dates-cdi
												
					.dates-sm
						@extend .justify-center
						grid-area: dates-sm

					.dates-tm
						@extend .justify-center
						grid-area: dates-tm
						img
							max-height: 20px
							max-width: 20px
							width: 100%

						
		.depth 
			@extend .align-justify-center
			grid-area: depth
			border-left: $White 1px solid
			
			.depth-background
				@extend .align-justify-center
				width: 45px
				height: 45px
				background-size: cover
				background-image: url("../assets/icons/EQViewer/Indicators/Depth-Background.svg")

	.item-content 
		background: $Green
		overflow: hidden
		max-height: 0px
		opacity: 0
		transition: 0.3s ease
		display: block

	.active + .item-content 
		max-height: 1000px
		padding-top: 10px
		padding-bottom: 14px
		opacity: 1
		border-bottom: 2px solid $Green

		.info-content
			margin: 0px 0px 10px 0px
			padding: 0px 15px


	.item-volcano-container
		display: grid
		grid-template-columns: 25% 75%
		grid-template-rows: 65px
		grid-template-areas: "icon-volcano dates-volcano"
		align-items: center
		justify-content: space-between
		border-bottom: 1px solid $White 
		
		.icon-volcano
			@extend .align-justify-center
			grid-area: icon-volcano
			margin-top: 10px
			height: 100%
			
		.info-volcano
			padding: 0rem .5rem
			
			.place-volcano
				@extend .align-center
				
			.dates-volcano
				@extend .align-center
				grid-area: dates-volcano				
				margin-top: 10px

@media (max-device-width: 360px) 
	.dates-icons
		@include dates-icons(20px, 20px, none)

	.main 		
		.place
			h2 
				width: 100%
				div
					white-space: nowrap
					overflow: hidden
					text-overflow: ellipsis

@media (max-device-width: 375px) 
	.accordion 
		.item 
			.item-container
				grid-template-columns: 20% 60% 20%

		.item-volcano-container
			grid-template-columns: 20% 80%

@media (max-device-width: 770px) and (min-device-width: 720px) 
	.accordion 
		.item 
			.item-container
				grid-template-columns: 20% 60% 20%
	
		.item-volcano-container
			grid-template-columns: 20% 80%
	
		.dates-icons
			@include dates-icons(15px, 15px, none)
	
		.main 
			.dates
				grid-template-columns: 62% 38%

@media (max-device-width: 1024px) and (min-device-width: 770px) 
	.accordion 
		.item 
			.item-container
				grid-template-columns: 20% 60% 20%
	
		.item-volcano-container
			grid-template-columns: 20% 80%
	
		.dates-icons
			@include dates-icons(15px, 15px, none)
	
		.main 
			.dates
				grid-template-columns: 60% 40%
	
		.header-text
			font-size: 9pt
		
		.date-text
			font-size: 7pt

@media (max-device-width: 1400px) and (min-device-width: 1024px) 
	.accordion 
		.item 
			.item-container
				grid-template-columns: 20% 60% 20%
	
		.item-volcano-container
			grid-template-columns: 20% 80%
	
		.dates-icons
			@include dates-icons(20px, 20px, none)

		.main 
			.dates
				grid-template-columns: 60% 40%

@media (max-device-width: 1600px) and (min-device-width: 1400px) 
	.dates-icons
		@include dates-icons(20px, 20px, none)
	
	.main 
		.dates
			grid-template-columns: 60% 40%
.Vsection_slider
	display: flex
	flex-direction: row
	justify-content: center
	align-content: center
	align-items: center
	padding: 0 15px 0 20px
	border-bottom: 1px solid $White
	



	



	




	




	



	

