/**EQHeader.jsx**/
@import EQDetailComponents/_EQHeader
/**EQHeader.jsx**/
/**EQMenu.jsx**/
@import EQDetailComponents/_EQMenu
/**EQMenu.jsx**/
/**EQMain.jsx**/
@import EQDetailComponents/_EQmain
/**EQMain.jsx**/
/**EQOrigen.jsx**/
@import EQDetailComponents/_EQOrigin
/**EQOrigen.jsx**/
/**EQMmi.jsx**/
@import EQDetailComponents/_EQMmi
/**EQMmi.jsx**/
/**EQmf.jsx**/
@import EQDetailComponents/_EQmf
/**EQmf.jsx**/
/**EQtm.jsx**/
@import EQDetailComponents/_EQtm
/**EQtm.jsx**/
/* EQCdi */
@import EQDetailComponents/_EQCdi
/* EQCdi */
/**EQDinamicMap.jsx**/
@import EQDetailComponents/_EQDinamicMap
/**EQsm.jsx**/
@import EQDetailComponents/_EQsm
/**EQNearbyseismos.jsx**/
@import EQDetailComponents/_EQsh
/**EQNearbyseismos.jsx**/
/**EQoriginHistory.jsx**/
@import EQDetailComponents/_EQHistoric
/**EQoriginHistory.jsx**/
/**EQDownloads.jsx**/
@import EQDetailComponents/_EQDownloads
/**EQDownloads.jsx**/
/**EQsmTabs.jsx**/
@import EQDetailComponents/_EQsmTabs

@import EQDetailComponents/_SmMarker

@import EQDetailComponents/_InteractiveMapLegend

/* EQCards  */
@import EQDetailComponents/_EQCards
/* EQCards  */


/*EQDetail sass to css*/
@media ( min-width: 200px )
    .EQcontainer
        width: 100%
        min-height: 100vh
        margin: 0 auto
        display: grid
        grid-template-columns: 100%
        grid-template-rows:  70px auto
        grid-template-areas: "EQheader" "EQmain"
        overflow: hidden
        .EQheader
            grid-template-rows: 70px  

.EQcontainer
    width: 100%
    height: 100vh
    margin: 0 auto
    display: grid
    grid-template-columns: 100%
    grid-template-rows: 70px auto 30px
    grid-template-areas: "EQheader" "EQmain" "footer"
    overflow: hidden
    .EQheader
        grid-area: EQheader
        width: 100%
        box-shadow: 0 1px 8px rgba($Black, 0.5)
        z-index: 1000
        background: $White 
        position: fixed
        display: grid
        grid-template-rows: auto
        grid-template-columns: 100%
        grid-template-areas: "EQButtonActions"

    .EQmain
        height: 100%
        grid-area: EQmain
        display: grid
        grid-template-columns: 22% 78%
        grid-template-rows: auto
        grid-template-areas: "EQmenu EQproduct"
        transition: all 0.5s
        z-index: 5
        overflow-y: auto
        
        #MapServices
            z-index: 1000
            position: fixed
            a
                display: flex
                color: white
                text-align: center
                padding: 16px
                text-decoration: none


        .EQmenu
            grid-area: EQmenu
            box-shadow: 0 1px 8px rgba($Black, 0.5)
            background: $MenuBlack
            display: block
            transition: all 0.3s
        
        .EQproduct
            grid-area: EQproduct
            width: 100%

    .active
        grid-template-columns: 100%
        grid-template-rows: auto
        grid-template-areas: "EQproduct"
        
    .active>.EQmenu
        width: 0
        height: 0
        position: relative

    

    .EQfooter
        grid-area: EQfooter
        z-index: 4
        @include footerMargin(100%, auto)

@media screen and ( max-width: 1237px )
    .EQcontainer
        .EQmain
            grid-template-columns: 100%
            grid-template-rows: auto
            grid-template-areas: "EQmenu"

            .EQmenu
                grid-area: EQmenu
                display: block
                width: 100%
                position: relative
            
            .EQproduct
                display: none

        .active
            grid-template-columns: 100%
            grid-template-rows: auto
            grid-template-areas: "EQproduct"
            
        .active
            >.EQmenu
                display: none
                width: 0
                height: 0

            >.EQproduct
                display: block


    