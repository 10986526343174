@media (min-width: 200px)
  .EQHistoric
    .MuiTableCell-head, .MUIDataTableHeadCell-root-253, .MUIDataTableHeadCell-fixedHeaderCommon-255, .MUIDataTableHeadCell-fixedHeaderXAxis-256, .MUIDataTableHeadCell-fixedHeaderYAxis-257
      background: $PGreen
      color: $White
      border-right: 1px solid $White
    .HistoricContent
      padding: 0 10px

    .HistoricTable
      padding: 0 20px
      overflow-x: auto

      div
        overflow-x: auto

      .EQHistoricHr
        padding: 10px 0

      .HmTableRowd
        background: $White
        td
          text-align: left
          padding: 14px

      .HmTableRow:nth-child(even)
        background-color: $Grey2
      .HmGds
        background: $GreySocialCell

        td
          padding: 14px
          text-align: center

      .EQHisFtTable
        margin: 40px 0
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        -webkit-box-pack: start
        -ms-flex-pack: start
        justify-content: flex-start
        -webkit-box-align: center
        -ms-flex-align: center
        align-items: center

        div
          width: 20px
          margin-right: 20px
          height: 20px
          background: $GreySocialCell

        span
          color: $Black

@media (min-width: 1200px)
  .EQHistoric .HistoricContent
    margin: 22px 10px 22px 10px
    border: 2px solid $Grey1

    .HistoricGrid
      display: -ms-grid
      display: grid
      -ms-grid-columns: 50% 50%
      grid-template-columns: 50% 50%
      -ms-grid-rows: auto
      grid-template-rows: auto

      > *
        &:nth-child(1)
          -ms-grid-row: 1
          -ms-grid-column: 1

        &:nth-child(2)
          -ms-grid-row: 1
          -ms-grid-column: 2