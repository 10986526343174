/**_Query.sass**/
.mode-catalog
    height: auto !important
.QueryContainerModal
    height: 100vh
.QueryContainer
    overflow-x: hidden
    transition-duration: .5s
    .LegalLogo
        a
            background: transparent !important
    .Hmmenu_burger
        display: none

    .QueryBody
        margin: auto
.TableContainer
    overflow: auto
    overflow-x: hidden
    height: calc( 100vh - 69px )


@media (min-width: 200px)
    .QueryBody
        width: 90%

@media (min-width: 1300px)
    .QueryBody
        width: 70%

/**_Query.sass**/
    
@import QueryComponents/_QueryBody
@import QueryComponents/_QueryModal
@import QueryComponents/_TableConsult
@import QueryComponents/_QueryBanner