/*EQDetails Menu

.bm-burger-button
  position: fixed
  width: 36px
  height: 30px
  left: 36px
  top: 36px

.sgc-logo-a img
  width: 120px
  height: 50px

/*EQDetails Menu
/* Slide Menu */


/* EQsummry CSS

@import _EQSummary

/* EQsummry CSS

/* EQoverview

.EQoverview

    .CardsTitle
      h3
        color: $Green
        margin: 1rem 1rem .5rem

    .CardsContent
      display: flex
      margin-bottom: 15px
      .overViewGrid
        width: 40%
        margin: 0 auto
        overflow: auto
      .container-eventmap-overview
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        margin: 0 auto
        padding-right: 20px
        .eventmap
          width: 100%
          max-height: 1200px
          -webkit-box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
          -moz-box-shadow: -7px 10px 14px rgba(235,235,235,1)
          box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
          padding-bottom: 20px
          background: $White

      .overviewtable
        border-collapse: collapse
        border-spacing: 0
        width: 100%

        td a
          color: $Black
          text-decoration: none

        th
          border-right: 2px solid $Green

        td, th
          text-align: left
          padding: 16px
          height: 38px

        .EQoverHref
          cursor: pointer
          td img
            height: 30px
          .short_icon
            .dates-icons
              height: 30px
            img
              width: 1.875rem !important
              height: 30px
              margin-left: 0

        tr:nth-child(odd)
          background-color: $Grey2

          .dates-icons
            height: 20px
            max-width: 100%

        .EQoverviewcell
          width: 10%
          text-align: left

    .Mui-selected span
      color: $Green

    .MuiTabs-indicator
      background-color: $Green !important

    /* EQoverview


    /* ---------------------PRODUCT --------------------- */
@media (max-width: 1400px)
  .EQoverview
    .EQSectionContainer
      .CardsContent
        display: block
        .container-eventmap-overview
         margin-bottom: 20px
      .eventmap, .overViewGrid
        width: 90% !important
        margin: auto
        .overViewGrid, .container-eventmap-overview
          width: 100%
    #container-resumen-products
      margin: 0.5rem 0rem
      padding: 0rem
    #container-resumen-1
      margin: 0.5rem 0rem

@media (max-width: 900px)
  .eventmap
    padding-right: 0 !important
  .EQoverview
    .container-eventmap-overview
      padding-right: 0 !important
    #container-resumen-products
      margin: 0.5rem 0rem
      padding: 0rem
    #container-resumen-1
      margin: 0.5rem 0rem
    
    .EQCardsContainer
      width: 100%

@media (max-width: $XSmall)
  .EQoverview
   .CardsContent
    .overviewtable
      td, th
         padding: 7px 15px




                  

      