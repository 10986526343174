/**_index.sass*

*
  padding: 0
  margin: 0
  -webkit-box-sizing: border-box
  box-sizing: border-box

body
  font-family: 'Nunito Sans', sans-serif

.ui-viewer-container
  height: 100vh
  margin: 0 0
  display: -ms-grid
  display: grid
  overflow-y: hidden
  -ms-grid-columns: auto
  grid-template-columns: auto
  -ms-grid-rows: 70px auto 30px
  grid-template-rows: 70px auto 30px
  grid-template-areas: "container-header" "content" "footer"
  -webkit-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease

/*------------------- HEADER ---------------

.container-header
  grid-area: container-header
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  display: -ms-grid
  display: grid
  grid-template-areas: "viewer-header"
  -ms-grid-columns: auto
  grid-template-columns: auto
  -ms-grid-rows: auto
  grid-template-rows: auto
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  position: relative
  z-index: 3

.map_icon_access
  height: max-content !important

.legal-header
  grid-area: legal-header

.viewer-header
  grid-area: viewer-header
  background-color: $Green
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -ms-grid-columns: 1fr 20px 1fr
  grid-template-columns: 1fr 1fr
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: justify
  -ms-flex-pack: justify
  justify-content: space-between
  grid-gap: 20px
  grid-template-areas: "sgc-log earthquakes-modes-view"
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  position: relative
  z-index: 3
  height: 70px

.sgc-logo
  grid-area: sgc-log
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

  .logo img
    border: none
    height: 60px
    width: 120px
    margin-left: 10px

.earthquakes-modes-view
  grid-area: earthquakes-modes-view
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  align-items: center
  -webkit-box-pack: end
  -ms-flex-pack: end
  justify-content: flex-end
  margin-right: 10px
  height: 70px
  .modes-view-item
    .icon_display
      width: 30px
      display: flex
      align-items: center
      img
        width: 30px

.container-header .earthquakes-modes-view ul
  height: 70px
  list-style-type: none
  margin: 0
  padding: 0
  display: flex
  align-items: center
  
  #ViInfo
    visibility: hidden
    
  li
    float: left
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    cursor: pointer
    height: 70px

    a
      display: block
      color: white
      text-align: center
      padding: 16px
      text-decoration: none

    .material-icons
      color: $White
      font-size: 28px
      height: 28px
      -webkit-user-select: none
      -moz-user-select: none
      -ms-user-select: none
      user-select: none
      width: 28px

    + li
      margin-left: 0.5em
      height: 30px

    .button-header
      -webkit-filter: none
      filter: none
      background: none

    &.selected .button-header
      -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)
      filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%)

/*------------------- HEADER ---------------
.contentv
  grid-area: content
  width: 100%
  height: 100%
  margin: 0 auto
  display: -ms-grid
  display: grid
  -ms-grid-columns: 450px auto
  grid-template-columns: 450px auto
  -ms-grid-rows: auto
  grid-template-rows: auto
  grid-template-areas: "events-list map"
  -webkit-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease
  -webkit-box-shadow: 0px 14px 10px -6px $Black
  box-shadow: 0px 14px 10px -6px $Black
  position: relative
  z-index: 2

.content
  grid-area: content
  width: 100%
  height: 100%
  margin: 0 auto
  display: -ms-grid
  display: grid
  -ms-grid-columns: 450px auto
  grid-template-columns: 450px auto
  -ms-grid-rows: auto
  grid-template-rows: auto
  grid-template-areas: "events-list map"
  -webkit-transition: all 0.3s ease
  -o-transition: all 0.3s ease
  transition: all 0.3s ease
  -webkit-box-shadow: 0px 14px 10px -6px $Black
  box-shadow: 0px 14px 10px -6px $Black
  position: relative
  z-index: 2
 
  .events-list
    grid-area: events-list
    overflow-x: hidden
    background: $White
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
    position: relative
    z-index: 2
    height: 100%
    

    .container-events-list
      height: 100%
      display: -ms-grid
      display: grid
      grid-template-areas: "summary-container" "events-container"
      -ms-grid-columns: auto
      grid-template-columns: 100%
      -ms-grid-rows: 150px auto
      grid-template-rows: 150px auto
      .summary-container
        grid-area: summary-container
        height: 100%
        background: $Grey14
        -webkit-box-shadow: 0px 0px 10px $Black
        box-shadow: 3px 0px 10px $Black
        z-index: 1
        .filter-container
          padding: 0rem .5rem
          .MuiInput-underline
            &::after
              border-bottom: $PGreen

          .MuiInput-underline:after
            border-bottom: 2px solid $PGreen
          
          .MuiFormLabel-root.Mui-focused
            color: $PGreen
        .summary_content
          width: 100%
          height: 50px
          padding-top: 20px
          background: $Grey14
          display: -webkit-box
          display: -ms-flexbox
          display: flex
          -webkit-box-orient: horizontal
          -webkit-box-direction: normal
          -ms-flex-direction: row
          flex-direction: row
          -webkit-box-pack: center
          -ms-flex-pack: center
          justify-content: center
          -webkit-box-align: center
          -ms-flex-align: center
          align-items: center
          z-index: 0
          .summary_h6
            font-weight: bold
          .summary_p
            font-size: 70%

      .events-container
        grid-area: events-container
        scroll-behavior: smooth
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
          width: 10px
        &::-webkit-scrollbar-track
          background: $Grey1
        &::-webkit-scrollbar-thumb
          background: $PGreen

.button-header
  width: 30px

.map
  grid-area: map
  height: 100%
  width: 100%
  background: $Brown
  z-index: 2

  
.leafletMap
  position: absolute
  bottom: 10px
  left: 50%
#leafletMapId
  width: 100%
  height: 100%
  position: relative
  .leaflet-popup-content
    min-width: 300px
  .leafletDivIcon
    margin-top: -0.7rem

#leafletMapIdVolcano
  width: 100%
  height: 100%
  position: relative
  .leafletDivIcon
    margin-top: -0.7rem

   
  .leaflet-map-pane
    
    .leaflet-popup-pane
      
      .leaflet-popup
        
        .leaflet-popup-content-wrapper
          width: 500px
          .leaflet-popup-content
            width: 500px

#leafletMiniMapId
  z-index: 1000
  width: 100%
  height: 135px
  position: relative

.leafletDivIcon
  border: none
  height: 25px
  width: 25px
  background-color: transparent
  margin-left: -13px

#mapLegendContainer
  position: absolute
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-orient: horizontal
  -webkit-box-direction: normal
  -ms-flex-direction: row
  flex-direction: row
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -ms-flex-line-pack: center
  align-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  width: 100%
  bottom: 0
  margin-bottom: 20px


#selectedMarker
  z-index: 2000

.earthquakePoint
  border-radius: 50%
  border: 2px solid $Blue5
  background-color: $White

.legendVolcanoIcon
  border: none
  height: 35px
  width: 35px
  background-color: transparent

.leaflet-popup-content-wrapper
  padding: 0
//   border-radius: 0px !important

.leaflet-popup-content
  margin: 0px 0px 0px 0px
  color: inherit

  p
    margin: 0px

#startDate, #endDate
  font-size: 10px

.MuiFormLabel-root
  font-size: 12px !important
  white-space: nowrap
  overflow: hidden 
  text-overflow: ellipsis
  max-width: 100%

.MuiPopover-root 
  .MuiPopover-paper
    min-width: 200px !important
    max-width: 90% !important

.MuiButton-textPrimary
  color: $Green !important

.MuiButton-textSecondary
  color: $White !important

.leaflet-popup-tip
  background-color: $LightGray

/* * Map section
/* * Map Settings Section

.mapSettingsScroll
  overflow-y: scroll
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  z-index: 2
  padding: 0

/* * Map Settings Section

.settings 
  grid-area: settings
  background: $Grey1
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  position: relative
  z-index: 2
  height: 100%
  .settings_content
    height: calc( 100vh - 110px )
    overflow-y: scroll

    &::-webkit-scrollbar
      width: 10px

    &::-webkit-scrollbar-track
      background: $Grey1

    &::-webkit-scrollbar-thumb
      background: $Green   

      &:hover
        background: $Grey5

/*--------------- Footer ---------------

.footer
  grid-area: footer
  background: $Grey5
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  text-align: center
  height: 100%
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5)
  position: relative
  z-index: 3

  p
    color: $White
    font-size: 12px

.MuiSnackbar-anchorOriginBottomCenter
   // background-color: $Black
   opacity: 0.8
   bottom: 35px
   .MuiAlert-filledSuccess
      opacity: 0.5
      transform: none
      transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
      background-color: $Black

/* -----Display configuration settings
@media (max-width: 2900px)
  .contentv
    >
      .map, .events-list, .settings
        display: none

    &.mode-list
      -ms-grid-columns: auto
      grid-template-columns: auto
      grid-template-areas: "events-list"
      overflow-y: hidden

      .events-list
        display: block

  .content
    >
      .map, .events-list, .settings
        display: none

    &.mode-list
      -ms-grid-columns: auto
      grid-template-columns: auto
      grid-template-areas: "events-list"
      overflow-y: hidden

      .events-list
        display: block

  .mode-list >
    .map, .settings
      display: none

  .contentv.mode-map
    -ms-grid-columns: auto
    grid-template-columns: auto
    grid-template-areas: "map"

    .map
      display: block

  .content.mode-map
    -ms-grid-columns: auto
    grid-template-columns: auto
    grid-template-areas: "map"

    .map
      display: block

  .mode-map >
    .events-list, .settings
      display: none

  .contentv.mode-settings
    -ms-grid-columns: auto
    grid-template-columns: auto
    grid-template-areas: "settings"

    .settings
      display: block

  .content.mode-settings
    -ms-grid-columns: auto
    grid-template-columns: auto
    grid-template-areas: "settings"

    .settings
      display: block

  .mode-settings >
    .events-list, .map
      display: none

  .contentv
    &.mode-map.mode-list
      -ms-grid-columns: 18% auto
      grid-template-columns: 18% auto
      grid-template-areas: "events-list map"

      >
        .events-list, .map
          display: block

    &.mode-settings
      &.mode-list
        -ms-grid-columns: 50% 50%
        grid-template-columns: 50% 50%
        grid-template-areas: "events-list settings"

        >
          .events-list, .settings
            display: block

      &.mode-map
        -ms-grid-columns: auto 25%
        grid-template-columns: auto 25%
        grid-template-areas: "map settings"

        .events-list
          display: none

        >
          .map, .settings
            display: block

      &.mode-list.mode-map
        -ms-grid-columns: 18% auto 25%
        grid-template-columns: 18% auto 25%
        grid-template-areas: "events-list map settings"

        >
          .events-list, .map, .settings
            display: block


  .content
    &.mode-map.mode-list
      -ms-grid-columns: 26% auto
      grid-template-columns: 26% auto
      grid-template-areas: "events-list map"

      >
        .events-list, .map
          display: block

    &.mode-settings
      &.mode-list
        -ms-grid-columns: 50% 50%
        grid-template-columns: 50% 50%
        grid-template-areas: "events-list settings"

        >
          .events-list, .settings
            display: block

      &.mode-map
        -ms-grid-columns: auto 25%
        grid-template-columns: auto 25%
        grid-template-areas: "map settings"

        .events-list
          display: none

        >
          .map, .settings
            display: block

      &.mode-list.mode-map
        -ms-grid-columns: 25% auto 25%
        grid-template-columns: 25% auto 25%
        grid-template-areas: "events-list map settings"

        >
          .events-list, .map, .settings
            display: block

@media (max-width: 1500px)
  .content
    &.mode-map.mode-list
      -ms-grid-columns: 30% auto
      grid-template-columns: 30% auto

@media (max-width: 1250px)
  .content
    &.mode-map.mode-list
      -ms-grid-columns: 33% auto
      grid-template-columns: 33% auto

@media (max-width: 1000px)
  .content.mode-map.mode-list
    -ms-grid-columns: 35% auto
    grid-template-columns: 35% auto
    grid-template-areas: "events-list map"

/* -----Display configuration settings
@media (max-width: 720px)
  .content
    >
      .map, .settings, .events-list
        display: none

    &.mode-list > .events-list
      display: block

  .settings, .map
    display: none

  .content
    &.mode-settings
      grid-template-areas: "settings"

      .settings
        display: block

      >
        .map, .events-list
          display: none

    &.mode-map
      grid-template-areas: "map"

      .map
        display: block

      >
        .settings, .events-list
          display: none

  .footer
    grid-area: footer
    background: $Grey5
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    text-align: center

    

    p
      color: $White
      font-size: 8px

  #leafletMapIdVolcano
  
    .leaflet-map-pane
      
      .leaflet-popup-pane
        
        .leaflet-popup
          
          .leaflet-popup-content-wrapper
            width: auto
            .leaflet-popup-content
              width: auto
@media  ( max-width: 500px )
  .MuiMenuItem-root
    white-space: normal !important
  

@media (max-width: 403px)
  .button-header
    width: 32.5px

@media (max-width: 375px)
  .container-header .earthquakes-modes-view ul li .material-icons
    font-size: 25px
    height: 25px
    width: 25px

  .sgc-logo .logo img
    border: none
    height: 60px
    width: 120px
    margin-left: 10px

@media (max-width: 360px)
  .container-header .earthquakes-modes-view ul li .material-icons
    font-size: 25px
    height: 25px
    width: 25px

  .button-header
    width: 26px

@media (max-width: 320px)
  .container-header .earthquakes-modes-view ul li .material-icons
    font-size: 20px
    height: 20px
    width: 20px

  .sgc-logo .logo img
    border: none
    height: 50px
    width: 100px
    margin-left: 10px

/**_index.sass*