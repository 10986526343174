.EQCardsContainer
   padding: 20px 0
   width: 100%
   overflow: hidden
   .EQCards
      display: grid
      margin: auto
      width: 80%
      grid-template-columns: 20% 20% 20% 20% 20%
      gap: 20px

.EQCard
   display: grid
   width: 100%
   min-height: 225px
   border-radius: 8px
   overflow: hidden
   background: $GreyHover
   margin: 10px auto
   cursor: pointer
   -webkit-box-shadow: -5px 6px 4px 0px rgba(203,203,203,1)
   -moz-box-shadow: -5px 6px 4px 0px rgba(203,203,203,1)
   box-shadow: -5px 6px 4px 0px rgba(203,203,203,1)
   &:hover
      border: 2px solid $Grey0
   .EQCardImg
      display: flex
      justify-content: center
      padding: 8px
      align-items: center
      img
         width: 90%
   .EQCardContent
      background: $White
      padding: 20px 8px
      color: $Green
      text-align: center
      height: 100%

@media ( max-width: $Medium )
   .EQCardsContainer
      .EQCards
         grid-template-columns: repeat(3, 1fr)
         grid-template-rows: auto
         width: 90%
   .EQCard
      width: 100%
      min-height: unset
      margin: 0 auto

@media ( max-width: $XSmall )
   .EQCardsContainer
      .EQCards
         grid-template-columns: repeat(2, 1fr)
         width: 90%
         gap: 18px


  