
.CatalogPriority
    .events-container
        overflow-y: visible !important
.catalog_list_map
    grid-template-rows: max-content auto !important
    .MuiTabs-indicatorSpan
        background-color: transparent !important
    .summary-container
        height: max-content !important
        padding-bottom: 10px
    .events-container
        height: calc( 100vh - 207px ) !important
    .events-container-simple
        height: calc( 100vh - 207px ) !important
        display: grid !important
        .grid-events
            overflow-y: scroll
    .events-pagination
        align-self: end
    .events-container-double
        height: calc( 100vh - 400px ) !important
    .QfilterModal
        outline: none
        background: $GovBlue
        color: $White
        padding: 2px 10px
        border: none
        cursor: pointer
.QFilterContainer
    width: 80%
    margin: auto
    height: 500px
    background: $White
    overflow-y: scroll
    .QueryContainer
        background: $White
.App
    position: relative
    width: 100%
    height: 100%

    #leafletWSMmap
        position: relative
        width: 100%
        height: 100%

#mapLegendContainerCatalogo
    position: absolute
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-orient: horizontal
    -webkit-box-direction: normal
    -ms-flex-direction: row
    flex-direction: row
    -webkit-box-pack: end
    -ms-flex-pack: end
    justify-content: flex-end
    -ms-flex-line-pack: center
    align-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    bottom: 0
    margin-bottom: 20px
    width: 100%
    padding: 0 1rem

    button
        z-index: 100
        background-color: $SoftGrey1
        z-index: 1000
        font-size: 100%

        .legend-label
            font-size: 80%

    .popup
        z-index: 1000
        background-color: $SoftGrey1
        margin-bottom: 20px
        left: 0 !important
    .MuiPopover-paper
        top: 60 !important
    
.CatalogModal
    height: 100vh
    overflow-y: scroll
    position: relative
    .Hmlogo
        width: 100%
    .container-table-consultation
        background: $White
    .CatalogModalClose
        position: fixed
        right: 10px
        top: 20px
        cursor: pointer
        z-index: 2000
    .QueryContainer
        background: $White
        height: 100vh
        overflow-y: scroll
.tableConsultTimeFormat
    span
        display: block