.notification-card
    padding: 0.3rem

    .notification-item
        padding: 0.1rem
        border-bottom: 1px solid $Green
        color: #646464
        
        .header-notification
            color: $Green
            font-size: 8pt

            .bold-place
                font-size: 10pt
                font-weight: bold

        .info-notification
            padding: 0.1rem 
            display: grid
            grid-template-areas: "intensity-notification main"
            grid-template-columns: 20% 80%
            grid-auto-rows: auto
            margin-bottom: .5rem

            .intensity-notification
                display: flex
                justify-content: center
                align-items: center
                .magnitude-container
                    @extend .align-justify-center
                    width: 45px
                    height: 45px
                    background-size: cover
                    background-image: url("../assets/icons/EQViewer/Indicators/Magnitude-Background.svg")

            .main
                grid-area: main
                height: 100%
                padding: 0rem .5rem
                font-size: 8pt

                hr
                    border-top: none
                    border-left: none
                    border-right: none
                    color: $White
                    border-bottom: 2px solid $White 
                    margin-top: .2rem

                .bold-depth
                    font-weight: bold

                .status-icons-notification
                    display: grid
                    width: 100%
                    grid-template-columns: 40% 60%
                    grid-template-rows: 100%
                    grid-template-areas: "container-status" "container-icons" 
                    align-items: center
                
                        
                    .container-icons
                        display: grid
                        width: 100%
                        grid-template-columns: 25% 25% 25% 25%
                        grid-template-rows: 100%
                        grid-template-areas: "dates-mmi dates-cdi dates-sm dates-tm"
                            
                        .dates-mmi
                            @extend .justify-center
                            grid-area: dates-mmi 
                            
                        .dates-cdi
                            @extend .justify-center
                            grid-area: dates-cdi
                                                    
                        .dates-sm
                            @extend .justify-center
                            grid-area: dates-sm

                        .dates-tm
                            @extend .justify-center
                            grid-area: dates-tm

            .depth-notification
                display: flex
                justify-content: center
                align-items: center
                .depth-container
                    @extend .align-justify-center
                    width: 45px
                    height: 45px
                    background-size: cover
                    background-image: url("../assets/icons/EQViewer/Indicators/Depth-Background.svg") 

        &:hover
            background: #A1A1A1
            color: $White
            .header-notification
                color: $White
               