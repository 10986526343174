@charset "UTF-8";
/**EQMarkersColors */
/**EQMarkersColors */
/** 2024 Colors */
/**Marker colors */
@import url("https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap");
.markerRed {
  animation: markerRedAnimation 1s infinite;
}

.markerYellow {
  animation: markerYellowAnimation 1s infinite;
}

.markerGreen {
  animation: markerGreenAnimation 1s infinite;
}

.markerBlue {
  animation: markerBlueAnimation 1s infinite;
}

.markerMagenta {
  animation: markerMagentaAnimation 1s infinite;
}

.activate_marker {
  fill: #7AD4E2;
}

/**Marker colors animation */
@keyframes markerRedAnimation {
  0% {
    fill: #FF0000;
  }
  100% {
    fill: #7AD4E2;
  }
}
@keyframes markerYellowAnimation {
  0% {
    fill: #EBEB00;
  }
  100% {
    fill: #7AD4E2;
  }
}
@keyframes markerGreenAnimation {
  0% {
    fill: #00FF00;
  }
  100% {
    fill: #7AD4E2;
  }
}
@keyframes markerBlueAnimation {
  0% {
    fill: #0000FF;
  }
  100% {
    fill: #7AD4E2;
  }
}
@keyframes markerMagentaAnimation {
  0% {
    fill: #C800F6;
  }
  100% {
    fill: #7AD4E2;
  }
}
/**Marker colors animation */
@font-face {
  font-family: "Oxigen";
  src: local("Oxigen"), url(../assets/fonts/Oxygen-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Oxigen-Bold";
  font-weight: 900;
  src: local("Oxigen"), url(../assets/fonts/Oxygen-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "PTSans";
  src: local("PTSans"), url(../assets/fonts/PTSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PTSans-Bold";
  font-weight: 900;
  src: local("PTSans"), url(../assets/fonts/PTSans-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "Roboto";
  font-weight: 500;
  src: local("Roboto"), url(../assets/fonts/Roboto-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "MontserratBlack";
  src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Black.ttf) format("opentype");
}
@font-face {
  font-family: "MontserratLight";
  src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Light.ttf) format("opentype");
}
@font-face {
  font-family: "MontserratRegular";
  src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype");
}
@font-face {
  font-family: "MontserratMedium";
  src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format("opentype");
}
@font-face {
  font-family: "Work_sansBlack";
  src: local("Work_sans"), url(../assets/fonts/Work_sans/WorkSans-Black.otf) format("opentype");
}
@font-face {
  font-family: "Work_sansBold";
  src: local("Work_sans"), url(../assets/fonts/Work_sans/WorkSans-Bold.otf) format("opentype");
}
.defaultToolTip {
  padding: 0;
}

.inlineTooltip {
  display: inline;
  vertical-align: middle;
}

.spaceBottom_tooltip {
  padding-bottom: 5px;
}

.spaceTop_tooltip {
  padding-top: 5px;
}

.spaceRight_tooltip {
  padding-right: 5px;
}

.spaceLeft_tooltip {
  padding-left: 5px;
}

.TmContainer {
  display: flex;
  align-items: center;
}
.TmContainer label {
  width: max-content;
  min-width: 110px;
}
.TmContainer .spaceTm_tooltip {
  padding-left: 10px;
}

/**_LegalHeader* */
.LegalHeader {
  background: #3366CC;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding: 0 15px;
}
.LegalHeader a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 7px;
}
.LegalHeader a img {
  height: 100%;
}

@media (max-width: 500px) {
  .LegalHeader {
    justify-content: flex-end;
  }
}
/**_LegalHeader* */
.shadowContainer {
  height: 120px;
}

/**HomeFooter.jsx* */
.HomeFooter {
  height: max-content;
  font-family: "Nunito Sans", sans-serif;
}
.HomeFooter address {
  font-style: normal;
}
.HomeFooter .HomeFtContainer {
  background-color: white;
  background: url("../assets/img/Home/bg-footer.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 10px 0 5vw 0;
}
.HomeFooter .HomeFtContainer .HomeFtContent {
  font-weight: 500;
  margin: 0 auto;
  position: relative;
  background: #FFFFFF;
  width: 80%;
  max-width: 1364px;
  padding: 70px 80px 30px;
  border-radius: 15px;
  margin-top: -100px;
  border: 3px solid #DFDFDF;
}
@media (max-width: 1500px) {
  .HomeFooter .HomeFtContainer .HomeFtContent {
    padding: 70px 70px 30px;
  }
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader {
  color: #005833;
  margin-bottom: 1rem;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader .HomeFtTitle {
  font-size: 1.3rem;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid {
  margin: 2rem 0;
  display: grid;
  gap: 3.5rem;
  grid-template-columns: 1fr 1fr 0.65fr;
}
@media (max-width: 1500px) {
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid {
    gap: 1.5rem;
  }
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription p {
  font-size: 8pt;
  line-height: 23px;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription span.inline {
  display: inline-block;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription.First {
  margin-right: 1rem;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtLogo {
  text-align: right;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtLogo img {
  width: 95%;
  max-height: 90px;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia {
  padding: 0 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem {
  text-align: center;
  transition: 0.25s;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem a {
  text-decoration: none;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem:hover {
  transform: scale(102%);
  opacity: 0.9;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem img.SocialMediaIcon {
  height: 45px;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem .SocialMediaText {
  font-size: 0.8rem;
  color: #005833;
  font-weight: 700;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks {
  padding-top: 1rem;
  text-align: center;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks a {
  color: #005833;
  text-decoration: none;
  margin-right: 20px;
  font-size: 8pt;
}
.HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks a:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .HomeFooter .HomeFtContainer .HomeFtContent {
    width: 90%;
    padding: 60px 60px 30px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader .HomeFtTitle {
    font-size: 1rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader .HomeFtSubTitle {
    font-size: 0.8rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid {
    grid-template-columns: 1fr 1fr 0.9fr;
    gap: 1.5rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription p {
    font-size: 7pt;
    line-height: 19px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription.First {
    margin-right: 15px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia {
    gap: 5px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem img.SocialMediaIcon {
    max-height: 40px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem .SocialMediaText {
    font-size: 7pt;
  }
}
@media (max-width: 800px) {
  .HomeFooter .HomeFtContainer {
    padding-top: 40px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent {
    padding: 3rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid {
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription p {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription.First {
    margin-right: 0;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial {
    max-width: none;
    grid-column-start: 1;
    grid-column-end: 3;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtLogo {
    text-align: center;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtLogo img {
    max-height: unset;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia {
    width: 250px;
    gap: 25px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem img.SocialMediaIcon {
    max-height: 80px;
    height: auto;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem .SocialMediaText {
    font-size: 9pt;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks a {
    font-size: 1.5vw;
  }
}
@media (max-width: 500px) {
  .HomeFooter .HomeFtContainer .HomeFtContent {
    padding: 11vw 8vw;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader .HomeFtTitle {
    font-size: 16px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtHeader .HomeFtSubTitle {
    font-size: 14px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid {
    margin: 4vw 0;
    display: block;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtDescription p {
    font-size: 10px;
    line-height: 20px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtLogo {
    width: 90%;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia {
    gap: 7vw;
    width: 90%;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem img.SocialMediaIcon {
    max-width: 50px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtInfoGrid .HomeFtSocial .HomeFtSocialMedia .SocialMediaItem .SocialMediaText {
    font-size: 11px;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks {
    padding-top: 0.5rem;
  }
  .HomeFooter .HomeFtContainer .HomeFtContent .HomeFtLinks a {
    font-size: 10px;
    margin-right: 15px;
    display: inline-block;
  }
}
.HomeFtLegal {
  height: 70px;
  width: 100%;
  text-align: center;
  padding: 18px;
  background: #3366CC;
}
.HomeFtLegal img {
  height: 100%;
  margin: 0 30px 0 0;
}

/**_ModalImg* */
.ModalImg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #2d2d2d;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
}
.ModalImg .ModalControllers {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ModalImg .ModalControllers .ModalClose {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  padding: 20px;
}
.ModalImg .ModalControllers .ModalClose img {
  width: 20px;
}
.ModalImg .ModalControllers .PrevVisibleModal {
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  position: absolute;
  visibility: visible;
  cursor: pointer;
  opacity: 1;
  top: 50%;
  left: 0;
  padding-left: 20px;
}
.ModalImg .ModalControllers .PrevVisibleModal img {
  width: 50px;
}
.ModalImg .ModalControllers .PrevInvisibleModal {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  top: 50%;
  left: 0;
  padding-left: 20px;
}
.ModalImg .ModalControllers .PrevInvisibleModal img {
  width: 50px;
}
.ModalImg .ModalControllers .NextArrow {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 50%;
  padding-right: 20px;
}
.ModalImg .ModalControllers .NextArrow img {
  width: 50px;
}
.ModalImg .ModalControllers .ModalImgContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  background: #FFFFFF;
  padding: 20px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  width: 50%;
  height: 90%;
}
.ModalImg .ModalControllers .ModalImgContainer .ImgContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  margin: auto;
}
.ModalImg .ModalControllers .ModalImgContainer .ImgContainer img {
  width: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

/**_ModalImg* */
.notifications {
  position: absolute;
  z-index: 2;
  background: #ececec;
  color: #33362D;
  max-width: 360px;
  min-width: 360px;
  max-height: 80vh;
  min-height: 100px;
  right: 0.6rem;
  padding: 0.5rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}
.notifications::-webkit-scrollbar {
  width: 10px;
}
.notifications::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.notifications::-webkit-scrollbar-thumb {
  background: #005833;
}

@media (max-width: 400px) {
  .notifications {
    min-width: 100% !important;
    max-width: 100% !important;
    right: 0 !important;
  }
}
.notification-card {
  padding: 0.3rem;
}
.notification-card .notification-item {
  padding: 0.1rem;
  border-bottom: 1px solid #005833;
  color: #646464;
}
.notification-card .notification-item .header-notification {
  color: #005833;
  font-size: 8pt;
}
.notification-card .notification-item .header-notification .bold-place {
  font-size: 10pt;
  font-weight: bold;
}
.notification-card .notification-item .info-notification {
  padding: 0.1rem;
  display: grid;
  grid-template-areas: "intensity-notification main";
  grid-template-columns: 20% 80%;
  grid-auto-rows: auto;
  margin-bottom: 0.5rem;
}
.notification-card .notification-item .info-notification .intensity-notification {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-card .notification-item .info-notification .intensity-notification .magnitude-container {
  width: 45px;
  height: 45px;
  background-size: cover;
  background-image: url("../assets/icons/EQViewer/Indicators/Magnitude-Background.svg");
}
.notification-card .notification-item .info-notification .main {
  grid-area: main;
  height: 100%;
  padding: 0rem 0.5rem;
  font-size: 8pt;
}
.notification-card .notification-item .info-notification .main hr {
  border-top: none;
  border-left: none;
  border-right: none;
  color: #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  margin-top: 0.2rem;
}
.notification-card .notification-item .info-notification .main .bold-depth {
  font-weight: bold;
}
.notification-card .notification-item .info-notification .main .status-icons-notification {
  display: grid;
  width: 100%;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  grid-template-areas: "container-status" "container-icons";
  align-items: center;
}
.notification-card .notification-item .info-notification .main .status-icons-notification .container-icons {
  display: grid;
  width: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  grid-template-rows: 100%;
  grid-template-areas: "dates-mmi dates-cdi dates-sm dates-tm";
}
.notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-mmi {
  grid-area: dates-mmi;
}
.notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-cdi {
  grid-area: dates-cdi;
}
.notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-sm {
  grid-area: dates-sm;
}
.notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-tm {
  grid-area: dates-tm;
}
.notification-card .notification-item .info-notification .depth-notification {
  display: flex;
  justify-content: center;
  align-items: center;
}
.notification-card .notification-item .info-notification .depth-notification .depth-container {
  width: 45px;
  height: 45px;
  background-size: cover;
  background-image: url("../assets/icons/EQViewer/Indicators/Depth-Background.svg");
}
.notification-card .notification-item:hover {
  background: #A1A1A1;
  color: #FFFFFF;
}
.notification-card .notification-item:hover .header-notification {
  color: #FFFFFF;
}

.notification-card-volcano {
  padding: 0.3rem;
}
.notification-card-volcano .notification-volcano-item {
  padding: 0.1rem;
  border-bottom: 1px solid #005833;
}
.notification-card-volcano .notification-volcano-item .type-notification-volcano {
  font-size: 8pt;
}
.notification-card-volcano .notification-volcano-item .title-notification-volcano {
  padding: 0.1rem;
  font-size: 8pt;
  font-weight: normal;
  color: #005833;
}
.notification-card-volcano .notification-volcano-item .title-notification-volcano .title-type {
  font-size: 8pt;
}
.notification-card-volcano .notification-volcano-item .level-notification-volcano {
  padding: 0.1rem;
  font-size: 8pt;
  font-weight: bold;
}
.notification-card-volcano .notification-volcano-item .level-notification-volcano span {
  font-weight: normal;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano {
  padding: 0.1rem;
  display: grid;
  grid-template-areas: "intensity-notification-volcano depth-localization-notification-volcano";
  grid-template-columns: 15% 85%;
  grid-auto-rows: auto;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .intensity-notification-volcano {
  width: 45px;
  height: 45px;
  background-size: cover;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano {
  padding: 0.1rem;
  font-size: 8pt;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano span {
  font-weight: normal;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano hr {
  color: #FFFFFF;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 2px solid #FFFFFF;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano p {
  font-weight: normal;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano .container-description {
  width: 250px;
}
.notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano .container-description p {
  font-size: 8pt;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.notification-card-volcano .close-notification-volcano-card .icon-close {
  cursor: pointer;
}
.notification-card-volcano:hover {
  background: #6d6d6d;
  color: #FFFFFF;
}
.notification-card-volcano:hover .title-notification-volcano {
  color: #FFFFFF;
}

/***Services_map */
.Services_hidden {
  opacity: 0 !important;
  display: none !important;
  height: 0 !important;
}
.Services_hidden .services_header {
  display: none;
  height: 0 !important;
}
.Services_hidden .services_body {
  height: 0 !important;
  display: none !important;
}
.Services_hidden .services_body a {
  display: none !important;
  height: 0 !important;
}

.Services_icon_on img {
  -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important;
  filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important;
}

#MapservicesCrt {
  padding: 0;
  display: flex;
  transition-duration: 0.3s;
}

#MapServices, #accesibility_services, #accesibility_services_popup {
  position: absolute;
  box-shadow: -7px 10px 14px -10px rgba(0, 0, 0, 0.5);
  display: block;
  max-width: 300px;
  width: 100%;
  border-radius: 5px;
  right: 10px;
  background: #FFFFFF;
  transition-duration: 0.3s;
}
#MapServices:hover, #accesibility_services:hover, #accesibility_services_popup:hover {
  box-shadow: -7px 10px 14px -10px rgba(0, 0, 0, 0.8);
}
#MapServices img, #accesibility_services img, #accesibility_services_popup img {
  width: 35px;
}
#MapServices .services_header, #accesibility_services .services_header, #accesibility_services_popup .services_header {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  justify-items: center;
}
#MapServices .services_header::before, #accesibility_services .services_header::before, #accesibility_services_popup .services_header::before {
  content: "";
  position: absolute;
  height: 1.5px;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #6A6A6A;
}
#MapServices .services_header img, #accesibility_services .services_header img, #accesibility_services_popup .services_header img {
  width: 150px;
}
#MapServices .services_header h4, #accesibility_services .services_header h4, #accesibility_services_popup .services_header h4 {
  text-align: center;
  color: #6A6A6A;
}
#MapServices .services_body, #accesibility_services .services_body, #accesibility_services_popup .services_body {
  height: 320px;
  overflow-y: scroll;
  display: block;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
#MapServices .services_body::-webkit-scrollbar, #accesibility_services .services_body::-webkit-scrollbar, #accesibility_services_popup .services_body::-webkit-scrollbar {
  width: 10px;
}
#MapServices .services_body::-webkit-scrollbar-track, #accesibility_services .services_body::-webkit-scrollbar-track, #accesibility_services_popup .services_body::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#MapServices .services_body::-webkit-scrollbar-thumb, #accesibility_services .services_body::-webkit-scrollbar-thumb, #accesibility_services_popup .services_body::-webkit-scrollbar-thumb {
  background: #005833;
}
#MapServices .services_body a, #accesibility_services .services_body a, #accesibility_services_popup .services_body a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#MapServices .services_body a span, #accesibility_services .services_body a span, #accesibility_services_popup .services_body a span {
  color: #6A6A6A;
  font-size: 10pt;
  text-align: left;
  margin-left: 0.5rem;
}

/***Services_map */
/***Accessibility */
.styles_body span {
  display: block;
  padding: 10px;
  cursor: pointer;
}
.styles_body span:hover {
  background: #f1f1f1;
}

.High_contrast .EQTensor_container .fila2 .tablas .tabla1 thead th {
  background: #667f00;
}
.High_contrast .EQTensor_container .fila2 .tablas .tabla3 th {
  background: #667f00;
}
.High_contrast .reports-cdi p {
  color: #FFFFFF;
}
.High_contrast .styles_body span:hover {
  background: #000000;
  color: #FFFFFF;
}

@media (max-width: 400px) {
  .Vsection_slider {
    padding: 0 25px 0 20px !important;
  }
  .viewer-header {
    grid-gap: 0 !important;
  }
  .viewer-header .sgc-logo .logo img {
    width: 78px;
  }
  .container-events-list .summary_content {
    width: 90% !important;
  }
  .max_font .summary_content div {
    padding-left: 15px;
  }
  .max_font .overviewtable td {
    padding: 0 !important;
  }
}
.max_font .details_element {
  overflow-y: scroll !important;
}
.max_font .HomeSocialTabs div a {
  width: 200px;
}
.max_font .MuiPickersCalendarHeader-transitionContainer {
  height: 32px !important;
}
.max_font .search-filter {
  margin: 0 !important;
}
.max_font .volcanos-list-filter-main {
  padding: 0.8rem 0rem !important;
}
.max_font .EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div {
  height: 4.375rem;
}
.max_font .content .events-list .container-events-list {
  grid-template-rows: max-content auto !important;
}
.max_font .content .events-list .summary_content {
  height: max-content !important;
}
.max_font .summary_content {
  padding-left: 10px;
}

@media (min-width: 1200px) {
  .max_font .container-resumen {
    margin: 0.5rem 1rem !important;
  }
}
/***Accessibility */
.stations_list_download {
  text-decoration: none;
  color: #005833;
  padding: 5px 10px;
  margin: 10px;
  font-size: 10pt;
}
.stations_list_download:hover {
  background: #686868;
  color: #FFFFFF;
  border-radius: 5px;
}

.Settings_label {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Settings_label img {
  width: 20px;
  margin-right: 10px;
}
.Settings_label span {
  display: block;
}

.Settings_container {
  padding: 2px;
}
.Settings_container .Settings_header {
  background: #005833;
  color: #FFFFFF;
  display: block;
  text-align: left;
  padding: 10px;
}
.Settings_container .Settings_header div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0;
}
.Settings_container .Settings_header div span {
  margin-left: 3px;
}
.Settings_container .Body_settings {
  background: #f1f1f1;
  color: #646464;
  padding: 10px;
  position: relative;
}
.Settings_container .Body_settings::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  top: 100%;
  background: #FFFFFF;
}
.Settings_container .Body_settings div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Settings_container .Body_settings div span {
  margin-left: 3px;
}
.Settings_container .Footer_settings {
  background: #f1f1f1;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Settings_container .Footer_settings a {
  text-decoration: none;
  padding: 10px;
  text-align: center;
  background: #005833;
  color: #FFFFFF;
}

#accessibility_icon img, #accessibility_icon_popup img {
  width: 30px;
}

.SmLatlngText {
  position: absolute;
  top: -11px;
  left: -11px;
  font-size: 0.7em;
  font-weight: bold;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 0;
  color: #000000;
  background: transparent;
}
.SmLatlngText::before {
  border: none;
}

.leaflet-top {
  top: 0 !important;
}

.leaflet-right {
  right: 0 !important;
}

.leaflet-control .leaflet-control-zoom-fullscreen {
  width: 37px;
  height: 37px;
  outline: none;
}

.crrBtnBack, .crrBtnNext {
  width: 30px;
  border: 1px solid transparent;
  font-size: 70px;
  height: 100px;
  cursor: pointer;
}

.crrBtnBack {
  background: url(../assets/icons/Home/slider_back.svg);
  background-repeat: no-repeat;
}

.crrBtnNext {
  background: url(../assets/icons/Home/slider_front.svg);
  background-repeat: no-repeat;
}

.crrBtnBack, .crrBtnNext {
  top: calc(50% - 15px);
}

.MuiTable-root th div {
  color: #FFFFFF;
}
.MuiTable-root .MuiTableCell-root.MuiTableCell-head .MuiButtonBase-root > div {
  text-transform: none;
}
.MuiTable-root tfoot td.MuiTableCell-root.MuiTableCell-footer {
  padding: 0;
}

fieldset.MuiFormControl-root {
  padding: 16px 24px 16px 24px !important;
}

.SgcMUIDataTable .MuiPaper-root {
  color: inherit;
}
.SgcMUIDataTable table td, .SgcMUIDataTable table th {
  font: inherit;
}
.SgcMUIDataTable table thead th {
  background-color: #005833;
  border-right: 1px solid white;
  text-align: center;
}
.SgcMUIDataTable table thead th:last-child {
  border-right: none;
}
.SgcMUIDataTable table thead th > div, .SgcMUIDataTable table thead th button {
  line-height: 1.1rem;
  font-weight: bold;
  margin: 0;
  padding: 4px 8px;
}
.SgcMUIDataTable table thead th span {
  justify-content: center;
}
.SgcMUIDataTable table tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}
.SgcMUIDataTable table tbody td {
  text-align: center;
  border-right: 1px solid #005833;
  line-height: normal;
  height: 45px;
  padding: 7px 15px;
  color: inherit;
}
.SgcMUIDataTable table tbody td:last-child {
  border-right: none;
}
.SgcMUIDataTable table tbody td img {
  max-width: 40px;
  cursor: pointer;
}
.SgcMUIDataTable table tbody td img.dates-icons {
  margin: 0 auto;
}
.SgcMUIDataTable table tbody td.align-left {
  text-align: left;
}

#accessibility_icon img, #accessibility_icon_popup img {
  width: 30px;
}

.SmLatlngText {
  position: absolute;
  top: -11px;
  left: -11px;
  font-size: 0.7em;
  font-weight: bold;
  box-shadow: none;
  padding: 0;
  border: none;
  border-radius: 0;
  color: #000000;
  background: transparent;
}
.SmLatlngText::before {
  border: none;
}

.leaflet-top {
  top: 0 !important;
}

.leaflet-right {
  right: 0 !important;
}

.leaflet-control .leaflet-control-zoom-fullscreen {
  width: 37px;
  height: 37px;
  outline: none;
}

.ListContainer {
  padding: 8px;
  font-size: 12px;
}
.ListContainer.sm .RoundedAccordion .AccordionContent .ContentInfo {
  grid-column: 1/4;
}

.AccordionContainer {
  background-color: white;
  padding: 3px 5px;
  color: #686868;
  font-size: 1em;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
}
.AccordionContainer.Active {
  color: #FFFFFF;
}
.AccordionContainer.Active .RoundedAccordion {
  background-color: #005833;
}
.AccordionContainer.Active .AccordionContent .HeaderContainer .HeaderTitle {
  color: #FFFFFF;
}
.AccordionContainer.Active .AccordionContent .ImgLeft img.Animate {
  width: 81%;
  max-width: 120px;
}
.AccordionContainer.Active .AccordionContent .ContentInfo {
  padding: 10px;
  max-height: 1000px;
  opacity: 1;
}
.AccordionContainer.Active .AccordionMore {
  max-height: 1000px;
  opacity: 1;
  padding: 10px 25px;
}

.RoundedAccordion {
  border-radius: 15px;
  border: 2px solid;
  border-color: black;
  overflow: hidden;
}
.RoundedAccordion.Green {
  border-color: #005833;
}
.RoundedAccordion.Yellow {
  border-color: #dcdc1c;
}
.RoundedAccordion.Orange {
  border-color: #D7A53C;
}
.RoundedAccordion.Red {
  border-color: red;
}
.RoundedAccordion .AccordionContent {
  display: grid;
  grid-template-columns: 22% auto 15%;
  padding: 12px;
  cursor: pointer;
}
.RoundedAccordion .AccordionContent .ImgLeft {
  display: flex;
  grid-row: span 2;
  justify-content: center;
  align-items: center;
}
.RoundedAccordion .AccordionContent .ImgLeft img {
  display: block;
  border-radius: 50%;
  aspect-ratio: 1/1;
  width: 80%;
  max-width: 70px;
}
.RoundedAccordion .AccordionContent .ImgLeft img.Animate {
  transition: 0.35s ease;
}
.RoundedAccordion .AccordionContent .HeaderContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.RoundedAccordion .AccordionContent .HeaderContainer .HeaderText {
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 2;
}
.RoundedAccordion .AccordionContent .HeaderContainer .HeaderTitle {
  font-size: 1.25em;
  color: #005833;
  font-weight: 900;
  margin-bottom: 5px;
}
.RoundedAccordion .AccordionContent .IconRight {
  display: flex;
  justify-content: center;
  align-items: center;
}
.RoundedAccordion .AccordionContent .ContentInfo {
  cursor: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: 0px;
  padding: 0 10px;
  opacity: 0;
  transition: 0.3s ease;
  z-index: 1;
}
.RoundedAccordion .Buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: -5px;
}
.RoundedAccordion .AccordionMore {
  max-height: 0;
  opacity: 0;
  transition: 0.3s ease;
  padding: 0 20px;
}

.MapPanel {
  width: max-content;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #7f7f7f;
  min-width: 130px;
  max-width: 400px;
}
.MapPanel .PanelTitle {
  font-weight: 600;
  margin-bottom: 0.5rem;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.MapPanel .PanelTitle p {
  margin: 0;
  letter-spacing: 0.05rem;
  color: #005833;
}

.SettingsLegendContainer {
  z-index: 3;
  position: absolute;
  right: 10px;
  top: 10px;
  pointer-events: none;
}
.SettingsLegendContainer .SettingsItem {
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  pointer-events: visiblePainted;
  margin-bottom: 0.3rem;
  cursor: pointer;
}
.SettingsLegendContainer .SettingsItem .SettingsItemContainer {
  position: relative;
}
.SettingsLegendContainer .SettingsItem .SettingsItemContainer .SeismicityLegend {
  pointer-events: none;
  position: absolute;
  top: 38px;
  right: -1px;
  opacity: 0.95 !important;
  z-index: -1;
}
@media (max-height: 450px) {
  .SettingsLegendContainer .SettingsItem .SettingsItemContainer .SeismicityLegend {
    position: fixed;
    top: 10px;
    right: 52px;
  }
}
.SettingsLegendContainer .SettingsItem .SettingsIcon {
  width: 30px;
  height: 30px;
  background-color: #005833;
  color: #f4f4f4;
  text-align: center;
  line-height: 40px;
}
.SettingsLegendContainer .SettingsItem .SettingsIcon:hover {
  opacity: 0.85;
}
.SettingsLegendContainer .SettingsItem .SettingsIcon.active {
  background-color: #f4f4f4;
  color: #005833;
}
.SettingsLegendContainer .SettingsItem .SettingsPanel {
  position: absolute;
  right: 40px;
  top: 0;
  pointer-events: auto;
  cursor: auto;
  padding: 15px;
}
.SettingsLegendContainer .SettingsItem .SettingsPanel .SettingsPanelItem {
  padding: 10px 0 0px;
}
.SettingsLegendContainer .SettingsItem .SettingsPanel .SettingsPanelItem .SettingsItemTitle {
  color: #005833;
  font-weight: 700;
  font-size: 0.6875rem;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
  text-align: center;
}
.SettingsLegendContainer .SettingsItem .SettingsPanel .SettingsPanelItem .SettingsItemContent {
  padding: 5px 15px;
  font-size: 0.7rem;
}

button.CloseButton {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: -5px -5px 0px 0px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #667f00;
}
button.CloseButton:hover {
  background-color: #005833;
  color: #fff;
}

button.filterButton {
  color: #fff;
  background-color: #005833;
  border: 1px solid #667F00;
  box-shadow: 2px 2px 4px #555;
  font-size: 0.7rem;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
  border-radius: 8px;
  font-weight: 550;
}
button.filterButton:disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.6;
}
button.filterButton:hover {
  background-color: #286405;
}

hr.Divider {
  margin: 0px;
  flex-shrink: 0;
  border-width: 0px 0px thin;
  border-style: solid;
  border-color: rgba(102, 127, 0, 0.2);
}

hr.Divider.DividerWhite {
  border-color: rgba(255, 255, 255, 0.701);
  margin: 8px 0px;
}

span.CellDivider {
  display: block;
  margin-left: 1px solid white;
}

.animated-marker {
  border-radius: 50%;
  border: 0.2px solid white;
  pointer-events: none !important;
}

.relevant-marker {
  z-index: 999 !important;
  pointer-events: all !important;
  cursor: pointer;
}
.relevant-marker .relevant-marker-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: lighter 17px sans-serif;
  color: white;
}
.relevant-marker .marker-pulsate {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: pulsate 2s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px red;
  animation-delay: 0.5s;
}

.relevant-marker.blue::after {
  box-shadow: 0 0 2px 3px #0000FF;
}

.relevant-marker.red::after {
  box-shadow: 0 0 2px 3px #FF0000;
}

.relevant-marker.green::after {
  box-shadow: 0 0 2px 3px #FF0000;
}

.relevant-marker.yellow::after {
  box-shadow: 0 0 2px 3px #FF0000;
}

.relevant-marker.purple::after {
  box-shadow: 0 0 1px 2px #FF0000;
}

@keyframes fade {
  from {
    filter: brightness(3);
    width: 40px;
    height: 40px;
  }
}
@keyframes pulsate {
  0% {
    transform: scale(1.4, 1.4);
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}
@keyframes fade-small {
  from {
    filter: brightness(3);
    width: 20px;
    height: 20px;
  }
}
.blinking {
  animation-name: fade;
  animation-duration: 1.2s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

.blinking-small {
  animation-name: fade-small;
  animation-duration: 0.7s;
  animation-iteration-count: 1;
}

.seismicity-popup {
  margin-bottom: 25px;
}
.seismicity-popup .leaflet-popup-content-wrapper {
  border-radius: 5px !important;
}
.seismicity-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  padding: 0.7rem 0.8rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 11px;
}
.seismicity-popup .leaflet-popup-content-wrapper .leaflet-popup-content span.title {
  font-weight: 800;
  display: block;
  margin-bottom: 5px;
  transform: scale(1, 1.1);
}
.seismicity-popup .leaflet-popup-content-wrapper .leaflet-popup-content span {
  font-weight: 600;
}

.seismicity-panel-info {
  position: fixed;
  bottom: 15px;
  left: 25px;
  z-index: 1;
  width: 600px;
}
@media (max-width: 1600px) {
  .seismicity-panel-info {
    width: 450px;
  }
}
@media (max-width: 600px) {
  .seismicity-panel-info {
    left: 15px;
    width: calc(100% - 30px);
  }
}

.seismicity-table {
  scrollbar-color: #005833 #f1f1f1;
  scrollbar-width: thin;
}
.seismicity-table::-webkit-scrollbar {
  width: 5px;
}
.seismicity-table::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.seismicity-table::-webkit-scrollbar-thumb {
  background: #005833;
}

.seismicity-header {
  padding: 7px 10px 7px 6px;
  display: flex;
}
.seismicity-header img {
  height: 30px;
}
@media (max-width: 600px) {
  .seismicity-header img {
    height: 25px;
  }
}
.seismicity-header .seismicity-counter {
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #005833;
  font-size: 0.9rem;
  margin-left: 4px;
}
@media (max-width: 600px) {
  .seismicity-header .seismicity-counter {
    font-size: 0.8rem;
  }
}

#MiniMapLeft, #MiniMapRight {
  z-index: 1000;
  width: 50%;
  height: 150px;
  position: relative;
}
@media (max-width: 800px) {
  #MiniMapLeft, #MiniMapRight {
    height: 130px;
  }
}
#MiniMapLeft .leaflet-bottom, #MiniMapRight .leaflet-bottom {
  display: none;
}

#MiniMapLeft {
  border-right: 1px solid gray;
}

.SeismicityMiniMapContainer {
  position: absolute;
  top: 10px;
  right: 56px;
  z-index: 3;
}
@media (max-width: 800px) {
  .SeismicityMiniMapContainer {
    top: 60px;
    left: 12px;
    right: auto;
  }
}
.SeismicityMiniMapContainer .MuiAccordionSummary-content {
  margin: 10px 2px !important;
}

.SeismicityMiniMapContainer .MiniMapTitle, .MiniMapContainer .MiniMapTitle, .MiniMapOptions .MiniMapTitle {
  color: #005833;
  font-size: 0.9rem;
  padding-right: 4px;
  font-weight: bold;
}
@media (max-width: 800px) {
  .SeismicityMiniMapContainer .MiniMapTitle, .MiniMapContainer .MiniMapTitle, .MiniMapOptions .MiniMapTitle {
    font-size: 0.7rem;
  }
}

.MiniMapOptions .MuiPaper-elevation {
  margin-left: -10px;
}
.MiniMapOptions .MiniMapTitle {
  width: 220px;
  text-align: center;
}

.ExpansionPanel .ExpansionPanelPaper {
  background-color: #f1f1f1;
  box-shadow: none;
  border: 2.5px solid #f1f1f1;
}
.ExpansionPanel .ExpansionPanelPaper .ExpansionPanelSummary {
  color: #005833;
  font-weight: bold;
}
.ExpansionPanel .ExpansionPanelPaper .ExpansionPanelSummary span {
  display: inline-block;
}
.ExpansionPanel .ExpansionPanelPaper .ExpansionPanelSummary .MuiAccordionSummary-content {
  margin-right: 5px;
}
.ExpansionPanel .ExpansionPanelPaper .ExpansionPanelSummary .ExpandMoreIcon {
  color: #005833;
}
.ExpansionPanel .ExpansionPanelPaper .ExpansionPanelDetails {
  background-color: white;
}

.BasicTable {
  max-height: 450px;
}
.BasicTable th {
  color: #FFFFFF;
  background: #005833;
  font-weight: bold;
  border-right: 1px solid #FFFFFF;
  text-align: center;
  line-height: 1.2rem;
  height: 40px;
}
.BasicTable tr:nth-child(even) {
  background: #f1f1f1;
}
.BasicTable td {
  border-right: 1px solid #005833;
  text-align: center;
  height: 35px;
}
.BasicTable td:last-of-type {
  border-right: none;
}
.BasicTable td, .BasicTable th {
  padding: 7px 12px;
  font-size: inherit;
  font-family: inherit;
}
.BasicTable .MuiTableCell-sizeMedium {
  padding: 16px;
}
.BasicTable tbody.BodyLeft td {
  text-align: left;
}
.BasicTable.ResponsiveTable tbody {
  display: flex;
}
.BasicTable.ResponsiveTable tbody tr {
  display: flex;
  flex-direction: column;
}
.BasicTable.ResponsiveTable tbody tr:nth-child(even) {
  background: transparent;
}
.BasicTable.ResponsiveTable tbody tr th, .BasicTable.ResponsiveTable tbody tr td {
  display: flex;
  flex-grow: 1;
  height: unset;
}
.BasicTable.ResponsiveTable tbody tr th {
  border-bottom: 1px solid white;
}
.BasicTable.ResponsiveTable tbody tr td {
  border-right: none;
}
.BasicTable.ResponsiveTable tbody tr td:nth-child(even) {
  background: #f1f1f1;
}

.TabsBar {
  box-shadow: none !important;
}
.TabsBar .TabsGroup {
  background-color: #f1f1f1;
  color: #686868;
}
.TabsBar .TabsGroup button, .TabsBar .TabsGroup a {
  color: inherit;
  min-width: 130px;
  border: 1px solid #686868;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (max-width: 800px) {
  .TabsBar .TabsGroup button, .TabsBar .TabsGroup a {
    min-width: unset;
  }
}
.TabsBar .TabsGroup .MuiTabs-flexContainer {
  gap: 4px;
}
.TabsBar .TabsGroup .MuiTabs-flexContainer.centered {
  justify-content: center;
}
.TabsBar .TabsGroup .MuiTabs-indicator {
  background-color: #005833;
}
.TabsBar .TabsGroup .MuiTab-root.Mui-selected {
  color: #005833;
  font-weight: 700;
  border-color: #005833;
}
.TabsBar .TabsGroup .TabLink {
  color: inherit;
  font-family: inherit;
}
.TabsBar .TabsGroup .TabLink:hover {
  color: #005833;
}
.TabsBar .TabsGroup .TabLink .LinkBtn {
  height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
}

body .SgcContent .MuiInputBase-root {
  font-size: inherit;
  font-family: inherit;
}
body .SgcContent .Mui-focused, body .SgcContent label.Mui-focused {
  color: #005833;
}
body .SgcContent .Mui-focused.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline, body .SgcContent label.Mui-focused.MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #005833;
}
body .MuiPaper-root, body .MuiInputBase-root, body .MuiTypography-root, body .MuiFormHelperText-root,
body label.MuiInputLabel-root, body button.MuiButton-root, body .MuiTab-root {
  font-family: inherit;
}
body .MuiFormHelperText-root {
  font-size: 0.75rem;
}
body .MuiAutocomplete-popper .MuiAutocomplete-listbox {
  font-family: "Nunito Sans", sans-serif;
}
body .MuiPickersBasePicker-container .MuiPickersToolbar-toolbar {
  background-color: #005833;
}
body .MuiPickersBasePicker-container .MuiPickersDay-daySelected {
  background-color: #005833;
}
body .MuiPickersBasePicker-container .MuiPickersDay-daySelected:hover {
  background-color: #005833;
  opacity: 0.9;
}
body .MuiMenu-paper .MuiListItem-root {
  font-family: "Nunito Sans", sans-serif;
}

.SgcContent {
  font-size: 0.875rem;
  color: #3E3E33;
}

body .Title {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}
body .TitleText {
  font-size: 1rem;
}
body .SubText {
  font-size: 0.8125rem;
}
body .RegularText {
  font-size: 0.875rem;
}
body .Emphasis-Gray {
  color: #646464;
}
body a {
  text-decoration: none;
  color: #005833;
}
body a:hover {
  text-decoration: underline;
}
body button, body label {
  font-family: inherit;
}
@media (max-width: 800px) {
  body {
    font-size: 0.8125rem;
  }
  body .TitleText {
    font-size: 0.9375rem;
  }
  body .SubText {
    font-size: 0.75rem;
  }
}

.hidden {
  visibility: hidden;
}

.selectable {
  cursor: pointer;
}
.selectable:hover, .selectable:focus, .selectable:active {
  background-color: rgba(0, 0, 0, 0.04);
}

.pointer {
  cursor: pointer;
}

.SGCProgress {
  color: #D7A53C !important;
}

.mb-1 {
  margin-bottom: 8px;
}

.mt-1 {
  margin-top: 8px;
}

.GrayBordered {
  border: 2px solid #f2f2f2;
  border-radius: 8px;
}

.TextEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.SgcButton {
  font-weight: bold;
  background: #F6F6F6;
  margin: 0px 18px 0px 18px;
  min-width: 10rem;
  border-radius: 8px;
  min-height: 1.7rem;
  height: max-content;
  border: 1px solid #FFFFFF;
  outline: none;
}
button.SgcButton.Oxigen {
  font-family: Oxigen;
  font-size: 10px;
}
button.SgcButton.Nunito {
  font-family: "Nunito Sans", sans-serif;
  font-size: 11px;
}
button.SgcButton a {
  display: block;
  width: 100%;
  padding: 8px;
  color: #005833;
  text-decoration: none;
}
button.SgcButton:hover {
  border: 1px solid #FFFFFF;
  cursor: pointer;
  background: #FFFFFF;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
table tr {
  border-bottom: 1px solid #E0E0E0;
}

/*HomeHeader.jsx*/ /*HomeMenu*/
.HmMenuBurger {
  position: absolute;
  right: 20px;
  bottom: calc(50% - 40px);
  transform: translateY(-50%);
  display: none;
}
@media (max-width: 1200px) {
  .HmMenuBurger {
    display: unset;
  }
}

.HmProducts {
  list-style: none;
  margin: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.85rem;
}
.HmProducts ul {
  list-style: none;
}
.HmProducts ul a {
  text-decoration: none;
}

@media (min-width: 200px) {
  .HmProductsContent {
    display: none;
    background: #FFFFFF;
    width: 100%;
    z-index: 20;
    position: absolute;
  }
  .HmProductsContent .HmProducts {
    display: block;
    width: 100%;
  }
  .HmProductsContent .HmProducts p {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
  }
  .HmProductsContent .HmProducts p span {
    color: #6A6A6A;
  }
  .HmProductsContent .HmProducts p img {
    width: 15px;
    height: 8px;
  }
  .HmProductsContent .HmProducts .HmPrListHome .HomeIconMenu {
    display: none;
    width: 100%;
  }
  .HmProductsContent .HmProducts .HmPrListHome a {
    text-decoration: none;
    width: 100%;
  }
  .HmProductsContent .HmProducts .HmPrList {
    display: block;
    padding: 10px 20px;
    border-bottom: 0.1px solid #D1D1D1;
  }
  .HmProductsContent .HmProducts .HmPrList .HmPrListTXT {
    font-weight: 700;
  }
  .HmProductsContent .HmProducts .HmPrList .ArrowIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.4rem;
    color: #D7A53C;
  }
  .HmProductsContent .HmProducts .HmPrList .ArrowIcon:hover {
    opacity: 0.7;
  }
  .HmProductsContent .HmProducts .HmPrList a {
    color: #646464;
    text-decoration: none;
  }
  .HmProductsContent .HmProducts .Second_level {
    margin-top: 10px;
    display: none;
    background: #E6E6E6;
  }
  .HmProductsContent .HmProducts .Second_level .HmPrListTXT {
    font-weight: 500;
  }
  .HmProductsContent .HmProducts .Second_level .HmPrListIcon {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 12px;
  }
  .HmProductsContent .HmProducts .Second_level .HmPrListIcon a {
    width: 100%;
  }
  .HmProductsContent .HmProducts .Second_level .HmPrListIcon img {
    width: 15px;
    height: 8px;
    margin-left: 15px;
  }
  .HmProductsContent .HmProducts .Second_level li {
    padding: 10px 0px;
    border-bottom: 0.1px solid #D1D1D1;
  }
  .HmProductsContent .HmProducts .Second_level .Third_level {
    margin-top: 10px;
    display: none;
    background: #D4D2D2;
    padding: 0 15px;
  }
  .HmProductsContent .HmProducts .Second_level .Third_level li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .HmProductsContent .HmProducts .Second_level .Third_level li a {
    width: 100%;
  }
  .HmProductsContent .HmProducts .Second_level .Third_level li img {
    width: 15px;
    height: 8px;
  }
}
@media (min-width: 1201px) {
  .HmProductsContent {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: relative;
    background: #DFDFDF;
  }
  .HmProductsContent::before {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    background: #D7A53C;
    width: 100%;
    height: 3px;
  }
  .HmProductsContent .HmProducts {
    width: 100%;
    max-width: 1364px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media (min-width: 1201px) and (min-width: 1500px) {
  .HmProductsContent .HmProducts {
    width: 80%;
  }
  .HmProductsContent .HmProducts .HmPrList {
    flex-grow: 1;
  }
}
@media (min-width: 1201px) {
  .HmProductsContent .HmProducts .Second:hover {
    background: #f5f5f5;
  }
  .HmProductsContent .HmProducts .HmPrListIcon img, .HmProductsContent .HmProducts .ArrowIcon {
    display: none !important;
  }
  .HmProductsContent .HmProducts .HmPrListHome {
    border-right: none !important;
  }
  .HmProductsContent .HmProducts .HmPrListHome a {
    display: none;
  }
  .HmProductsContent .HmProducts .HmPrListHome .HomeIconMenu {
    display: unset;
  }
  .HmProductsContent .HmProducts .HmPrListWeare {
    border-left: 0.5px solid #FFFFFF;
  }
  .HmProductsContent .HmProducts .HmPrList {
    border-bottom: none;
    border-right: 2px solid #FFFFFF;
    padding: 3px 20px;
    margin: 5px 0;
    line-height: 19px;
  }
  .HmProductsContent .HmProducts .HmPrList > p > .HmPrListTXT {
    text-align: center;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level {
    display: block;
    padding: 5px 0;
    position: absolute;
    background: #FFFFFF;
    border-top: 7.5px solid #D9D9D9;
    margin: 4px 0 0 -20px;
    min-width: 160px;
    width: 200px;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level span {
    color: #646464;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level span:hover {
    cursor: pointer;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level li {
    padding: 5px 20px;
    width: 100%;
    border-bottom: 1px solid #f1f1f1;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level li:hover {
    cursor: pointer;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level a {
    color: #646464;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level a:hover {
    color: #D7A53C;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover {
    position: relative;
    background: #f5f5f5;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover span {
    color: #D7A53C;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover .Third_level {
    margin-top: 5px;
    border-top: 3.5px solid #D7A53C;
    background: #FFFFFF;
    display: block;
    padding: 5px 0;
    width: 160px;
    position: absolute;
    top: -13px;
    left: 100%;
    width: 200px;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover .Third_level li:hover {
    background: #f5f5f5;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover .Third_level li a {
    color: #646464;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover .Third_level li a:hover {
    color: #D7A53C;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .third:hover .Third_level li img {
    display: none;
  }
  .HmProductsContent .HmProducts .HmPrList:hover .Second_level .Second:hover {
    color: #D7A53C;
    background: #f5f5f5;
  }
  .HmProductsContent .HmProducts .HmPrList .HmPrListTXT {
    color: #686868;
    text-decoration: none;
  }
  .HmProductsContent .HmProducts .HmPrList p {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .HmProductsContent .HmProducts .HmPrList p span {
    color: #646464;
    line-height: 20px;
  }
  .HmProductsContent .HmProducts .HmPrList p span:hover {
    color: #c4d92d;
    cursor: pointer;
  }
  .HmProductsContent .HmProducts .HmPrList .HmPrListTXT:hover {
    color: #D7A53C;
    cursor: pointer;
  }
  .HmProductsContent .HmProducts .HmPrList .HmPrListHome a {
    display: none;
  }
}
/*.HmHeader */
.HmLogos {
  margin: auto;
  position: relative;
  width: 80%;
  height: 80px;
  max-width: 1800px;
  background: #FFFFFF;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
}
.HmLogos .HmIcon {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.HmLogos .HmIcon .logo-sgc-white {
  display: none;
}
.HmLogos .HmIcon .logo-sgc {
  display: unset;
}
.HmLogos .HmIcon img {
  width: 130px;
}

@media (max-width: 1200px) {
  .HmLogos {
    background: #D7A53C;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 8px;
  }
  .HmLogos .HmIcon .logo-sgc-white {
    display: unset;
  }
  .HmLogos .HmIcon .logo-sgc {
    display: none;
  }
}
@media (min-width: 1800px) {
  .HmLogos {
    width: 90%;
  }
}
/*HomeBody*/
/*HomeAside*/ /* HomeChat Button */
.HomeChat {
  background: #D7A53C;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 20;
  border-radius: 5px;
  padding: 10px 0.9rem;
  transition: 0.25s ease;
}
@media (max-width: 800px) {
  .HomeChat {
    font-size: 0.8rem;
    padding: 8px 11px;
    right: 5vw;
    bottom: 5vw;
  }
}
.HomeChat:hover {
  opacity: 0.9;
  transform: scale(98%);
}

/* Accesibility Side Menu */
.HmSideMenu {
  color: #FFFFFF;
  z-index: 40;
  position: fixed;
  -webkit-transition: 2s;
  -o-transition: 2s;
  transition: 2s;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  display: -ms-grid;
  display: grid;
}
@media (max-width: 1200px) {
  .HmSideMenu {
    display: none;
  }
}
.HmSideMenu .HmSideHref {
  position: relative;
  text-decoration: none;
  color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 38px;
  width: auto;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
  margin-bottom: 5px;
  background: transparent;
}
.HmSideMenu .HmSideHref:hover {
  width: 180px;
  -webkit-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}
.HmSideMenu .HmSideHref:hover div {
  width: 100%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.HmSideMenu .HmSideHref:hover span {
  display: unset;
  white-space: nowrap;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  padding-left: 10px;
}
.HmSideMenu .HmSideHref div {
  display: -webkit-box;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  width: 35px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: 0;
  background: #D7A53C;
}
.HmSideMenu .HmSideHref img {
  -webkit-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  padding: 2px 0 2px 3px;
  max-height: 35px;
}
.HmSideMenu .HmSideHref span {
  display: none;
  font-size: 0.84rem;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

/* HomeMainBanner*/
.HomeSearchContainer {
  height: 140px;
  display: flex;
}

.HomeSearchBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  z-index: 2;
  width: 80%;
  max-width: 1364px;
  font-size: 0.85rem;
}
.HomeSearchBar .HomeInput {
  display: -ms-grid;
  display: grid;
  width: 100%;
  min-height: 45px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 2px solid #D7A53C;
  background: white;
  -ms-grid-columns: 27% 73%;
  grid-template-columns: 27% 73%;
}
.HomeSearchBar .HomeInput .HomeSearchOption {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionText, .HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionIcon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionText {
  flex-grow: 1;
  padding: 10px 0 10px 20px;
  background: #DFDFDF;
  height: 100%;
  text-wrap: nowrap;
  overflow: hidden;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionText span {
  overflow: hidden;
  text-overflow: ellipsis;
  color: #686868;
  font-weight: 600;
  cursor: pointer;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionIcon {
  height: 100%;
  background: #DFDFDF;
  cursor: pointer;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionIcon svg {
  color: #D7A53C;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionIcon svg:hover {
  opacity: 0.7;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchList {
  position: absolute;
  top: calc(100% + 1.5px);
  width: 100%;
  background: #DFDFDF;
  padding: 10px 10px 10px 21px;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchList .radio-group label.control-label {
  width: 100%;
  transition: 0.25s ease;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchList .radio-group label.control-label:hover {
  opacity: 0.8;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchList .radio-group span.MuiFormControlLabel-label {
  font: inherit;
  color: #686868;
  font-weight: 600;
}
.HomeSearchBar .HomeInput .HomeSearchOption .SearchList .radio-group .MuiRadio-root, .HomeSearchBar .HomeInput .HomeSearchOption .SearchList .radio-group .Mui-checked {
  color: #D7A53C;
}
.HomeSearchBar .HomeInput .HomeInputBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.HomeSearchBar .HomeInput .HomeInputBar .SearchInput {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  border-top: 2px solid #DFDFDF;
}
.HomeSearchBar .HomeInput .HomeInputBar .SearchInput .SearchInputText {
  font-family: inherit;
  width: 100%;
  padding-left: 1rem;
}
.HomeSearchBar .HomeInput .HomeInputBar .SearchButton {
  cursor: pointer;
  height: 100%;
  padding: 7px;
  background: #D7A53C;
  display: flex;
}
.HomeSearchBar .HomeInput .HomeInputBar .SearchButton img {
  min-width: 2rem;
  transition: 0.25s ease;
}
.HomeSearchBar .HomeInput .HomeInputBar .SearchButton:hover img {
  transform: scale(95%);
}

@media (max-width: 1200px) {
  .HomeSearchBar {
    width: 90%;
  }
  .HomeSearchBar .HomeInput {
    -ms-grid-columns: 30% 70%;
    grid-template-columns: 30% 70%;
  }
}
@media (max-width: 800px) {
  .HomeSearchContainer {
    height: 120px;
  }
  .HomeSearchBar .HomeInput {
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
  }
  .HomeSearchBar .HomeInput .HomeSearchOption .SearchOptionText {
    display: none;
  }
  .HomeSearchBar .HomeInput .HomeSearchOption .SearchList {
    width: max-content;
  }
}
/*Home & Events Carousel*/
/*HomeCarousel */
.HomeCarouselContainer {
  background: #f1f1f1;
  width: 100%;
}

.HomeCarousel .CarouselSwip, .EventsCarousel .CarouselSwip {
  position: absolute;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 800px) {
  .HomeCarousel .CarouselSwip, .EventsCarousel .CarouselSwip {
    pointer-events: all;
  }
}
.HomeCarousel .CarouselSwip.Right, .EventsCarousel .CarouselSwip.Right {
  right: 0;
  top: 0;
}
.HomeCarousel .CarouselBtnBack, .HomeCarousel .CarouselBtnNext, .EventsCarousel .CarouselBtnBack, .EventsCarousel .CarouselBtnNext {
  opacity: 0.5;
  position: relative;
  pointer-events: all;
  z-index: 2;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.25s ease;
  padding: 1rem 0;
}
@media (max-width: 800px) {
  .HomeCarousel .CarouselBtnBack, .HomeCarousel .CarouselBtnNext, .EventsCarousel .CarouselBtnBack, .EventsCarousel .CarouselBtnNext {
    padding: 0;
  }
}
.HomeCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .HomeCarousel .CarouselBtnNext svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnNext svg.MuiSvgIcon-root {
  color: #D7A53C;
  font-size: 80px;
}
@media (max-width: 1200px) {
  .HomeCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .HomeCarousel .CarouselBtnNext svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnNext svg.MuiSvgIcon-root {
    font-size: 7vw;
  }
}
@media (max-width: 800px) {
  .HomeCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .HomeCarousel .CarouselBtnNext svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnBack svg.MuiSvgIcon-root, .EventsCarousel .CarouselBtnNext svg.MuiSvgIcon-root {
    font-size: 8vw;
  }
}
.HomeCarousel .CarouselBtnBack:hover, .HomeCarousel .CarouselBtnNext:hover, .EventsCarousel .CarouselBtnBack:hover, .EventsCarousel .CarouselBtnNext:hover {
  opacity: 1;
  transform: translateY(-50%) scale(105%);
}
.HomeCarousel .CarouselBtnBack, .EventsCarousel .CarouselBtnBack {
  left: 35px;
}
.HomeCarousel .CarouselBtnNext, .EventsCarousel .CarouselBtnNext {
  right: 35px;
}
@media (max-width: 1200px) {
  .HomeCarousel .CarouselBtnBack, .EventsCarousel .CarouselBtnBack {
    left: 1vw;
  }
  .HomeCarousel .CarouselBtnNext, .EventsCarousel .CarouselBtnNext {
    right: 1vw;
  }
}
@media (max-width: 800px) {
  .HomeCarousel .CarouselBtnBack, .EventsCarousel .CarouselBtnBack {
    left: 0px;
  }
  .HomeCarousel .CarouselBtnNext, .EventsCarousel .CarouselBtnNext {
    right: 0px;
  }
}

.HomeCarousel {
  padding-bottom: 100px;
  height: 100%;
}
@media (max-width: 800px) {
  .HomeCarousel {
    padding-bottom: 50px;
  }
}
.HomeCarousel .CarouselImg {
  min-height: 300px;
}
@media (max-width: 500px) {
  .HomeCarousel .CarouselImg .HomeImg {
    min-height: 50vw;
  }
}
@media (max-width: 800px) {
  .HomeCarousel .CarouselImg {
    min-height: 200px;
  }
}
@media (max-width: 500px) {
  .HomeCarousel .CarouselImg {
    min-height: 50px;
  }
}
.HomeCarousel .carousel {
  overflow: visible;
}
.HomeCarousel .carousel .control-dots {
  bottom: -70px;
}
@media (max-width: 800px) {
  .HomeCarousel .carousel .control-dots {
    bottom: -45px;
  }
}
.HomeCarousel .carousel .control-dots .dot {
  background: #D7A53C;
  box-shadow: none;
  width: 9px;
  height: 9px;
}

/* ---------------------------------- */
/* Events Carousel */
.EventsCarouselContainer {
  position: relative;
  width: 100%;
  background: url("../assets/img/Home/bg-volcanos.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.EventsCarouselContainer::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.551);
}

.EventsCarousel {
  padding-bottom: 2vw;
  min-height: 40vh;
}

.SlideEventContainer {
  width: 80%;
  max-width: 1364px;
  margin: auto;
  padding: 7vw 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.SlideEvent {
  width: 100%;
  padding: 45px 5vw;
  border: 3px solid white;
  border-radius: 15px;
  color: white;
  background-color: rgba(42, 42, 42, 0.147);
}
.SlideEvent .EventEmphasisText {
  display: block;
  font-weight: 700;
  color: #D7A53C;
  font-style: italic;
}
.SlideEvent .SlideEventTitle {
  border-top: 4px solid #D7A53C;
  border-bottom: 4px solid #D7A53C;
  padding: 0.6rem 2rem;
  text-align: center;
  width: max-content;
  margin: auto;
}
.SlideEvent .SlideEventTitle span {
  color: white;
}
.SlideEvent .SlideEventSubTitle {
  padding: 16px;
  padding-bottom: 0;
}
.SlideEvent .SlideEventSubTitle .EventSuperTitle {
  margin-bottom: 3px;
  color: #D7A53C;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 800;
}
.SlideEvent .SlideEventContent {
  width: 100%;
  padding: 3rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 1200px) {
  .SlideEvent .SlideEventContent {
    display: block;
  }
}
.SlideEvent .SlideEventContent .EventInfo {
  text-align: left;
  padding-right: 35px;
  max-width: 48%;
}
@media (max-width: 1200px) {
  .SlideEvent .SlideEventContent .EventInfo {
    max-width: none;
    margin-bottom: 2rem;
  }
}
.SlideEvent .SlideEventContent .EventInfo .EventTitle {
  display: inline-block;
  margin-top: 10px;
  font-size: 1.5rem;
  font-weight: 800;
}
.SlideEvent .SlideEventContent .EventInfo .EventSubTitle {
  font-size: 1.15rem;
  margin-bottom: 4px;
}
.SlideEvent .SlideEventContent .EventInfo .EventDescription {
  margin-top: 12px;
  font-size: 0.9rem;
}
.SlideEvent .SlideEventContent .VolcanoData {
  min-width: 50%;
}
.SlideEvent .SlideEventContent .EventData {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SlideEvent .SlideEventContent .EventTableContainer {
  display: flex;
  border: 1px solid white;
  border-radius: 10px;
  min-width: 400px;
  font-weight: 700;
}
@media (max-width: 800px) {
  .SlideEvent .SlideEventContent .EventTableContainer {
    flex-direction: column;
  }
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem {
  flex-grow: 1;
  flex-basis: 0;
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent {
  padding: 25px 35px;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1200px) {
  .SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent {
    padding: 15px 25px;
    font-size: 0.8rem;
  }
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent .TableListLink {
  text-decoration: none;
  color: inherit;
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent .TableListLink:hover {
  color: #D7A53C;
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent .TableListItem {
  margin: 5px 0;
  display: flex;
  align-items: center;
  font-weight: 900;
}
.SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent .TableListItem span {
  margin-left: 5px;
  text-align: left;
}

@media (max-width: 800px) {
  .SlideEvent {
    width: 100%;
    margin: 0 auto;
    padding: 40px 9vw;
  }
  .SlideEvent .SlideEventTitle {
    padding: 1vw 0;
    width: auto;
  }
  .SlideEvent .SlideEventTitle span.HomeTitle {
    font-size: 2.5vw;
  }
  .SlideEvent .SlideEventSubTitle {
    padding: 16px;
    padding-bottom: 0;
  }
  .SlideEvent .SlideEventSubTitle .EventSuperTitle {
    color: #D7A53C;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 800;
  }
  .SlideEvent .SlideEventContent {
    font-size: 15px;
    padding: 3.5vw 0;
  }
  .SlideEvent .SlideEventContent .EventInfo {
    padding-right: 0;
    margin-bottom: 23px;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventTitle {
    font-size: 20px;
    margin-bottom: 4px;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventSubTitle {
    font-size: 16px;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventDescription {
    margin-top: 8px;
    font-size: 14px;
  }
  .SlideEvent .SlideEventContent .VolcanoData {
    margin: 0 auto;
  }
  .SlideEvent .SlideEventContent .EventTableContainer {
    padding: 15px 5vw 0;
    width: 80%;
    min-width: auto;
    margin: 0 auto;
  }
  .SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent {
    padding: 0;
    padding-bottom: 15px;
  }
}
@media (max-width: 500px) {
  .SlideEventContainer {
    width: 100%;
    padding: 40px 9vw;
  }
  .SlideEvent {
    padding: 7vw 10vw;
  }
  .SlideEvent .EventEmphasisText {
    font-size: 2.5vw;
  }
  .SlideEvent .SlideEventTitle {
    border-width: 3.5px;
  }
  .SlideEvent .SlideEventTitle span.HomeTitle {
    font-size: 2.8vw;
  }
  .SlideEvent .SlideEventSubTitle {
    padding: 16px 0 0;
  }
  .SlideEvent .SlideEventSubTitle .EventSuperTitle {
    font-size: 4vw;
  }
  .SlideEvent .SlideEventContent {
    font-size: 3vw;
    padding: 4vw 0;
  }
  .SlideEvent .SlideEventContent .EventInfo {
    margin-bottom: 6vw;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventTitle {
    font-size: 3.8vw;
    margin-top: 1vw;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventSubTitle {
    font-size: 3vw;
  }
  .SlideEvent .SlideEventContent .EventInfo .EventDescription {
    margin-top: 2vw;
    font-size: 2.5vw;
  }
  .SlideEvent .SlideEventContent .EventTableContainer {
    padding: 15px 6vw 0;
    width: 100%;
  }
  .SlideEvent .SlideEventContent .EventTableContainer .EventTableItem .EventTableContent {
    font-size: 2.5vw;
    padding-bottom: 2vw;
  }
}
/*HomeCarousel */
/* Responsive Bordered Grid */
.ResponsiveGrid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  border: 1px solid white;
  border-radius: 8px;
}
.ResponsiveGrid.EventsGrid {
  grid-auto-columns: minmax(100px, 160px);
}
.ResponsiveGrid.EventsGrid .GridItem .GridItemTitle {
  padding: 0 8px;
}
.ResponsiveGrid.VolcanosGrid {
  grid-auto-columns: minmax(auto, 160px);
}
.ResponsiveGrid .GridItem.Flex {
  display: flex;
  flex-direction: column;
}
.ResponsiveGrid .GridItem:not(:last-child) {
  border-right: 1px solid white;
}
.ResponsiveGrid .GridItem .GridItemTitle {
  padding: 0 20px;
  height: 40px;
  border-bottom: 1px solid white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 900;
  line-height: 0.8rem;
  letter-spacing: -0.1pt;
}
.ResponsiveGrid .GridItem .GridItemTitle span {
  width: 100%;
}
.ResponsiveGrid .GridItem .GridItemTitle span p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ResponsiveGrid .GridItem .animate {
  transition: 0.25s;
}
.ResponsiveGrid .GridItem .animate:hover {
  transform: scale(95%);
}
.ResponsiveGrid .GridItem .GridItemContent {
  padding: 10px 16px;
  position: relative;
  text-align: center;
}
.ResponsiveGrid .GridItem .GridItemContent.Flex {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ResponsiveGrid .GridItem .GridItemContent img {
  max-height: 80px;
}
.ResponsiveGrid .GridItem .GridItemContent .ItemText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ResponsiveGrid .GridItem .GridItemContent .ItemText .ImgVolcano {
  width: 14vw;
}
.ResponsiveGrid .GridItem .GridItemContent .Big {
  font-size: 24px;
}
.ResponsiveGrid .GridItem .GridItemContent .Small {
  font-size: 0.85rem;
}
.ResponsiveGrid .GridItem .GridItemContent span {
  display: block;
  white-space: nowrap;
}
.ResponsiveGrid .GridItem .GridItemContent img.dates-icons {
  height: 100%;
  max-height: 50px;
  margin: 0;
  min-width: 40px;
}
.ResponsiveGrid .GridItem .GridItemContent .TextMedium {
  font-size: 0.8rem;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .ResponsiveGrid.EventsGrid {
    grid-template-rows: auto auto;
    grid-auto-flow: row;
  }
  .ResponsiveGrid.EventsGrid.FourItems {
    grid-template-columns: repeat(2, minmax(120px, 160px));
  }
  .ResponsiveGrid.EventsGrid.FourItems .GridItem {
    grid-column: auto;
  }
  .ResponsiveGrid.EventsGrid.FullItems {
    grid-template-columns: repeat(6, minmax(60px, 80px));
  }
  .ResponsiveGrid.EventsGrid.FullItems .col-3 {
    grid-column: auto/span 3;
  }
  .ResponsiveGrid.EventsGrid .GridItem {
    grid-column: auto/span 2;
  }
  .ResponsiveGrid.EventsGrid .GridItem:nth-child(n+1) {
    border-right: 1px solid white;
  }
  .ResponsiveGrid.EventsGrid .GridItem:last-child {
    border-right: none;
  }
  .ResponsiveGrid.EventsGrid.FullItems .GridItem:nth-child(n+4), .ResponsiveGrid.EventsGrid.FourItems .GridItem:nth-child(n+3) {
    border-top: 1px solid white;
  }
  .ResponsiveGrid.EventsGrid.FullItems .GridItem:nth-child(3), .ResponsiveGrid.EventsGrid.FourItems .GridItem:nth-child(2) {
    border-right: none;
  }
}
@media (max-width: 800px) {
  .ResponsiveGrid.EventsGrid {
    grid-template-rows: auto auto;
    grid-auto-flow: row;
  }
  .ResponsiveGrid.EventsGrid.FourItems {
    margin: 0 20px;
    grid-template-columns: repeat(2, minmax(100px, 160px));
  }
  .ResponsiveGrid.EventsGrid.FourItems .GridItem {
    grid-column: auto;
  }
  .ResponsiveGrid.EventsGrid.FullItems {
    grid-template-columns: repeat(6, minmax(50px, 80px));
  }
  .ResponsiveGrid.EventsGrid.FullItems .col-3 {
    grid-column: auto/span 3;
  }
  .ResponsiveGrid.EventsGrid .GridItem {
    grid-column: auto/span 2;
  }
  .ResponsiveGrid.EventsGrid .GridItem:nth-child(n+1) {
    border-right: 1px solid white;
  }
  .ResponsiveGrid.EventsGrid .GridItem:last-child {
    border-right: none;
  }
  .ResponsiveGrid.EventsGrid.FullItems .GridItem:nth-child(n+4), .ResponsiveGrid.EventsGrid.FourItems .GridItem:nth-child(n+3) {
    border-top: 1px solid white;
  }
  .ResponsiveGrid.EventsGrid.FullItems .GridItem:nth-child(3), .ResponsiveGrid.EventsGrid.FourItems .GridItem:nth-child(2) {
    border-right: none;
  }
  .ResponsiveGrid .GridItem .GridItemTitle {
    padding: 3px 6px;
    font-size: 10px;
  }
  .ResponsiveGrid .GridItem .GridItemContent img {
    max-height: 70px;
  }
  .ResponsiveGrid .GridItem .GridItemContent span {
    font-size: 14px;
  }
  .ResponsiveGrid .GridItem .GridItemContent .Big {
    font-size: 21px;
  }
  .ResponsiveGrid .GridItem .GridItemContent .Small {
    font-size: 12px;
  }
}
@media (max-width: 500px) {
  .ResponsiveGrid.EventsGrid.FourItems {
    margin: 0;
    grid-template-columns: repeat(2, minmax(50px, 120px));
  }
  .ResponsiveGrid.EventsGrid.FullItems {
    grid-template-columns: repeat(6, minmax(25px, 60px));
  }
  .ResponsiveGrid .GridItem.Flex .GridItemContent {
    padding-left: 1vw;
    padding-right: 1vw;
  }
  .ResponsiveGrid .GridItem .GridItemTitle {
    height: 8vw;
    font-size: 2vw;
    line-height: 2.4vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent {
    height: 16vw;
    padding: 2vw 3vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent .ItemText.Img {
    height: 60%;
  }
  .ResponsiveGrid .GridItem .GridItemContent .ItemText .ImgVolcano {
    width: 10vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent span {
    font-size: 2.5vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent .Big {
    font-size: 3.5vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent .Small {
    font-size: 2.1vw;
  }
  .ResponsiveGrid .GridItem .GridItemContent img {
    height: 100%;
    max-height: none;
  }
}

/**_HomeServices**/
.HMservices {
  overflow: hidden;
  position: relative;
}
.HMservices .HmServicesContainer {
  background-color: gray;
}
.HMservices img.HmServicesBg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.HMservices .HmServicesContent {
  padding: 6vw 0;
  margin: 0 auto;
  width: 80%;
  max-width: 1364px;
}
@media (min-width: 801px) and (max-width: 1200px) {
  .HMservices .HmServicesContent {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .HMservices .HmServicesContent {
    width: 90%;
  }
}

.CardGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(120px, 1fr));
  gap: 20px;
  align-items: center;
  grid-auto-rows: minmax(100px, auto);
}

.SgcCard {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 17vw;
  min-height: 200px;
  max-height: 280px;
  position: relative;
  overflow: hidden;
}
.SgcCard a {
  text-decoration: none;
}
.SgcCard a:hover {
  text-decoration: none;
}
.SgcCard .SgcCardContent {
  position: relative;
  height: 100%;
  transition: 0.4s ease;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 20px 20px;
  display: flex;
  flex-direction: column;
}
.SgcCard .SgcCardImg {
  display: flex;
  justify-content: center;
  height: 100%;
}
.SgcCard .SgcCardImg img {
  margin: 0 2.2vw;
  max-height: 200px;
}
.SgcCard .SgcCardTitle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D7A53C;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1rem;
  transition: transform 0.5s linear;
}
.SgcCard .SgcCardText {
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  line-height: 120%;
  width: 100%;
  color: black;
  bottom: -50%;
  background-color: white;
  left: 0;
  transition: 0.5s ease;
  padding: 15px;
}
.SgcCard:hover {
  background-color: #D7A53C;
  cursor: pointer;
}
.SgcCard:hover .SgcCardContent {
  height: 80%;
  transition: 0.5s ease;
}
.SgcCard:hover .SgcCardImg {
  height: 80%;
}
.SgcCard:hover .SgcCardTitle {
  color: white;
  height: unset;
  transform: scale(90%);
  transition: 0.5s ease;
}
.SgcCard:hover .SgcCardText {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  transition: 0.5s ease;
}

@media (max-width: 1500px) {
  .SgcCard {
    min-height: 150px;
  }
  .SgcCard .SgcCardImg img {
    max-height: 12vw;
  }
  .SgcCard .SgcCardTitle {
    font-size: 1.2vw;
  }
  .SgcCard .SgcCardText {
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .SgcCard {
    height: 17vw;
    min-height: 130px;
  }
  .SgcCard .SgcCardImg img {
    margin: 0 3vw;
  }
  .SgcCard .SgcCardContent {
    padding: 12px 10px 15px;
  }
  .SgcCard .SgcCardTitle {
    font-size: 1.2vw;
  }
  .SgcCard .SgcCardText {
    font-size: 0.8vw;
    padding: 1.2vw;
  }
  .SgcCard:hover .SgcCardContent {
    height: 78%;
  }
}
@media (max-width: 800px) {
  .CardGrid {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
    gap: 4vw;
  }
  .SgcCard {
    height: 38vw;
    max-height: none;
  }
  .SgcCard .SgcCardImg img {
    max-height: 24vw;
  }
  .SgcCard .SgcCardContent {
    padding-bottom: 3vw;
    padding-top: 3vw;
  }
  .SgcCard .SgcCardTitle {
    font-size: 2.4vw;
  }
  .SgcCard .SgcCardText {
    padding: 2.2vw;
    font-size: 1.7vw;
  }
  .SgcCard:hover .SgcCardContent {
    height: 80%;
  }
}
@media (max-width: 500px) {
  .SgcCard {
    height: 40vw;
    min-height: 120px;
  }
  .SgcCard .SgcCardImg img {
    margin: 0;
    max-height: 25vw;
  }
  .SgcCard .SgcCardContent {
    padding: 3vw;
  }
  .SgcCard .SgcCardTitle {
    font-size: 2.7vw;
  }
  .SgcCard .SgcCardText {
    padding: 3vw;
  }
  .SgcCard:hover .SgcCardContent {
    height: 75%;
  }
}
/**_HomeNews**/
.HomeNewsContainer p a {
  text-decoration: none;
  color: inherit;
}
.HomeNewsContainer p a:hover {
  color: #D7A53C;
}

.HomeNewsContent {
  background-color: #F6F6F6;
  padding: 4vw 0;
}
.HomeNewsContent .MainNew, .HomeNewsContent .NewsGrid {
  width: 80%;
  margin: 0 auto;
  max-width: 1364px;
}
.HomeNewsContent .MainNew {
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.HomeNewsContent .MainNew .MainImgContainer {
  margin-right: -20px;
  z-index: 1;
  max-height: 525px;
}
.HomeNewsContent .MainNew .MainImgContainer img.MainImg {
  width: 100%;
  height: 100%;
}
.HomeNewsContent .MainNew .MainImgContainer img.MainImg:hover {
  opacity: 0.9;
}
.HomeNewsContent .MainNew .MainNewInfoBg {
  background-color: #005833;
  color: white;
  padding: 40px;
  overflow: hidden;
  height: 100%;
  margin-left: 20px;
}
.HomeNewsContent .MainNew .MainNewInfoBg .MainNewInfo {
  height: 100%;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
}
.HomeNewsContent .MainNew .MainNewInfoBg .MainNewInfo .NewTitle {
  transition: 0.25s ease;
}
.HomeNewsContent .MainNew .MainNewInfoBg .MainNewInfo .NewTitle:hover {
  transform: scale(101%);
}
.HomeNewsContent .MainNew .MainNewInfoBg .NewsText {
  font-size: 0.9rem;
}
.HomeNewsContent .NewsGrid {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
}
.HomeNewsContent .NewsGrid .NewsItem {
  transition: 0.25s ease;
}
.HomeNewsContent .NewsGrid .NewsItem img.HomeNewImg {
  width: 100%;
  max-height: 200px;
  display: block;
}
.HomeNewsContent .NewsGrid .NewsItem .NewsItemLink {
  text-decoration: none;
}
.HomeNewsContent .NewsGrid .NewsItem .SubInfoText {
  margin-top: 8px;
  font-size: 0.8rem;
  color: #686868;
}
.HomeNewsContent .NewsGrid .NewsItem .InfoText {
  font-size: 0.9rem;
  color: #005833;
  margin-top: 5px;
}
.HomeNewsContent .NewsGrid .NewsItem .TitleText {
  margin-top: 8px;
  font-weight: 700;
  font-size: 0.9rem;
  color: #686868;
}
.HomeNewsContent .NewsGrid .NewsItem:hover {
  transform: scale(101%);
}
.HomeNewsContent .NewsGrid .NewsItem:hover .TitleText {
  color: #D7A53C;
}

.SectionAction {
  padding-top: 3vw;
  text-align: center;
}

.ShadowBoxGray {
  width: 100%;
  height: 80px;
  background: #F6F6F6;
}

@media (max-width: 1200px) {
  .HomeNewsContent .MainNew, .HomeNewsContent .NewsGrid {
    width: 90%;
  }
  .HomeNewsContent .MainNew {
    grid-template-columns: 1fr;
  }
  .HomeNewsContent .MainNew .MainImgContainer {
    margin-right: 0;
  }
  .HomeNewsContent .MainNew .MainImgContainer img.MainImg {
    max-height: 500px;
  }
  .HomeNewsContent .MainNew .MainNewInfoBg {
    margin-left: 0;
    padding: 40px;
    height: unset;
  }
  .HomeNewsContent .NewsGrid .NewsItem img.HomeNewImg {
    height: 16vw;
  }
}
@media (max-width: 800px) {
  .HomeNewsContent .MainNew, .HomeNewsContent .NewsGrid {
    width: 80%;
  }
  .HomeNewsContent .NewsGrid {
    grid-template-columns: 1fr;
  }
  .HomeNewsContent .NewsGrid .NewsItem .TitleText {
    font-size: 1.1rem;
  }
  .HomeNewsContent .NewsGrid .NewsItem img.HomeNewImg {
    max-height: 300px;
    height: auto;
  }
  .HomeNewsContent .MainNew .MainNewInfoBg {
    font-size: 15px;
  }
  .HomeNewsContent .MainNew .MainNewInfoBg .NewsText {
    font-size: 14px;
  }
  .SectionAction {
    padding-top: 6vw;
  }
}
@media (max-width: 500px) {
  .HomeNewsContent .MainNew, .HomeNewsContent .NewsGrid {
    width: 90%;
  }
  .HomeNewsContent .NewsGrid .NewsItem .TitleText {
    font-size: 14px;
  }
  .HomeNewsContent .MainNew .MainNewInfoBg .NewsText {
    font-size: 3.2vw;
  }
  .SectionAction {
    padding-top: 8vw;
  }
}
/**HomeTopic**/
.HmTopic {
  overflow: hidden;
}

.HmTopicContainer {
  overflow: hidden;
  position: relative;
}
.HmTopicContainer img.HmTopicBg {
  width: 100%;
  transform: scale(1.03);
  filter: brightness(100%);
  transition: 0.3s ease;
  object-fit: cover;
  max-height: 530px;
}
.HmTopicContainer:hover img.HmTopicBg {
  transform: scale(1);
  filter: brightness(98%);
  transition: 0.3s ease;
}
.HmTopicContainer .HmTopicContent {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  padding: 7vw;
  padding-bottom: 5vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HmTopicContainer .HmTopicContent .HmTopicTitle {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  margin-bottom: 5rem;
}
@media (max-width: 1200px) {
  .HmTopicContainer .HmTopicContent .HmTopicTitle {
    margin-bottom: 4rem;
    font-size: 3rem;
  }
}
@media (max-width: 800px) {
  .HmTopicContainer img.HmTopicBg {
    min-height: 250px;
  }
  .HmTopicContainer .HmTopicContent .HmTopicTitle {
    margin-bottom: 2.5rem;
    font-size: 2.1rem;
  }
}
@media (max-width: 500px) {
  .HmTopicContainer img.HmTopicBg {
    min-height: 50vw;
  }
  .HmTopicContainer .HmTopicContent .HmTopicTitle {
    margin-bottom: 5vw;
    font-size: 6vw;
  }
}

#SGCTitle {
  height: 0;
  width: 0;
}

/* Main Banner (SearchBar & Carousel) */
.HmMainBanner {
  position: relative;
  background: #DFDFDF;
  background-image: url("../assets/img/Home/textures/Textura5.png");
  background-size: cover;
}
.HmMainBanner::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

/**
 *  667f00 : $PGreen
 *  646464 : $Grey6
 *  535353 : $Grey7
 *  696969 : $Grey12
 *  c75346 : $red
 *  **/
.normal_contrast .contrast_green {
  background: #667f00;
}
.normal_contrast .contrast_grey {
  background: #646464;
}
.normal_contrast .contrast_greys7 {
  background: #535353;
}
.normal_contrast .contrast_greys12 {
  background: #696969;
}
.normal_contrast .contrast_red {
  background: #c75346;
}

.black_contrast .contrast_green {
  background: #000000;
}
.black_contrast .contrast_grey {
  background: #000000;
}
.black_contrast .contrast_greys7 {
  background: #000000;
}
.black_contrast .contrast_greys12 {
  background: #000000;
}
.black_contrast .contrast_red {
  background: #000000;
}

.normal_font {
  font-size: 14px;
}
.normal_font .size_standard {
  font-size: 14px;
}
.normal_font .size_standard a, .normal_font .size_standard span {
  font-size: 14px;
}
.normal_font .size_services h2 {
  font-size: 0.95em;
}
.normal_font .size_services span {
  font-size: 1.1em;
}
.normal_font .size_social h3 {
  font-size: 1.2rem;
}
.normal_font .size_social p {
  font-size: initial;
}
.normal_font .size_social a {
  font-size: initial;
}
.normal_font .size_social span {
  font-size: initial;
}
.normal_font .size_social .civil {
  font-size: 1.1rem;
}
.normal_font .size_position span {
  font-size: 1rem;
}
.normal_font .size_position p {
  font-size: 1rem;
}
.normal_font .size_position a {
  font-size: initial;
}

.high_font {
  font-size: 18px;
}
.high_font .size_standard {
  font-size: 18px;
}
.high_font .size_standard a, .high_font .size_standard span {
  font-size: 18px;
}
.high_font .size_services h2 {
  font-size: 12px;
}
.high_font .size_services span {
  font-size: 1.2em;
}
.high_font .size_social h3 {
  font-size: 1.5rem;
}
.high_font .size_social p {
  font-size: 18px;
}
.high_font .size_social a {
  font-size: 18px;
}
.high_font .size_social span {
  font-size: 18px;
}
.high_font .size_social .civil {
  font-size: 1.5rem;
}
.high_font .size_position span {
  font-size: 1.3rem;
}
.high_font .size_position p {
  font-size: 1.1rem;
}
.high_font .size_position a {
  font-size: 18px;
}

.HmContainer {
  width: 100%;
  overflow: hidden;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
}

.HomeTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.HomeTitle {
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1.3rem;
}

div .EventButton, div .EventButtonFilled {
  transition: 0.25s;
  display: inline-block;
  color: white;
  padding: 10px 2rem;
  text-decoration: none;
  font-size: 1rem;
  border: 2px solid #D7A53C;
  border-radius: 10px;
}
@media (max-width: 500px) {
  div .EventButton, div .EventButtonFilled {
    padding: 8px 1.5rem;
    font-size: 3vw;
  }
}
div .EventButton:hover {
  background: #D7A53C;
  transform: scale(98%);
}
div .EventButtonFilled {
  border: none;
  background-color: #D7A53C;
}
div .EventButtonFilled.Green {
  background-color: #005833;
}
div .EventButtonFilled:hover {
  opacity: 0.9;
  transform: scale(98%);
  text-decoration: none;
}

section .SectionTitleContainer {
  max-width: 1364px;
  margin: 0 auto;
  min-height: 180px;
  padding: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  -ms-flex-direction: column;
}
section .SectionTitleContainer .SectionTitle {
  color: #005833;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-bottom: 3px solid #005833;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.7rem;
}
section .SectionTitleContainer .SectionTitleInfo {
  padding: 0.5rem;
  text-align: center;
}
section .SectionTitleContainer .SectionTitleInfo span {
  display: inline-block;
}
@media (max-width: 800px) {
  section .SectionTitleContainer {
    min-height: unset;
    padding: 1.5rem 2rem;
  }
  section .SectionTitleContainer .SectionTitle {
    font-size: 5vw;
  }
  section .SectionTitleContainer .SectionTitleInfo {
    font-size: 14px;
  }
}
@media (max-width: 500px) {
  section .SectionTitleContainer {
    padding: 8vw 0;
    width: 85%;
    margin: 0 auto;
  }
  section .SectionTitleContainer .SectionTitle {
    font-size: 6.5vw;
  }
  section .SectionTitleContainer .SectionTitleInfo {
    font-size: 3.2vw;
  }
  section .SectionTitleContainer .SectionTitleInfo span {
    display: inline;
  }
}
section .SectionContent {
  width: 100%;
}

.HomeHeader, .HomeMain {
  width: 100%;
  background: #FFFFFF;
  position: relative;
}

span.no-wrap {
  text-wrap: nowrap;
  white-space: nowrap;
}

/**_Query.sass**/
.mode-catalog {
  height: auto !important;
}

.QueryContainerModal {
  height: 100vh;
}

.QueryContainer {
  overflow-x: hidden;
  transition-duration: 0.5s;
}
.QueryContainer .LegalLogo a {
  background: transparent !important;
}
.QueryContainer .Hmmenu_burger {
  display: none;
}
.QueryContainer .QueryBody {
  margin: auto;
}

.TableContainer {
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 69px);
}

@media (min-width: 200px) {
  .QueryBody {
    width: 90%;
  }
}
@media (min-width: 1300px) {
  .QueryBody {
    width: 70%;
  }
}
/**_Query.sass**/
/**_QueryBody.sass* */
.QBodyDisabled {
  opacity: 0.5;
  position: relative;
}
.QBodyDisabled::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 1000;
}

#QrangeCircle {
  text-align: center;
  padding: 10px 10px 0;
}
#QrangeCircle .radio-input input {
  width: 100%;
}

@media (min-width: 200px) {
  .Qselections {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
}
@media (min-width: 800px) {
  .Qselections {
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
  }
  .Qselections .selector1 {
    padding-right: 10px;
  }
}
.QmodalOpen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.QmodalOpen .QmodalContainer {
  height: 300px;
  width: 70%;
  margin: auto;
  border: 8px solid #696969;
  background: #FFFFFF;
  color: #696969;
}
.QmodalOpen .QmodalContainer .QmodalHead {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.QmodalOpen .QmodalContainer .QmodalBody {
  padding: 20px;
}
.QmodalOpen .QmodalContainer .QmodalBody p {
  text-align: justify;
}
.QmodalOpen .QmodalContainer .QmodalBody button {
  float: right;
  color: #2d66d1;
  border: none;
  background: transparent;
  padding: 20px 20px 0 0;
}

.QMap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#map {
  width: 100%;
  height: 400px;
}
#map .leaflet-popup-content-wrapper {
  border-radius: 20px !important;
  padding: 8px;
}
#map .leaflet-popup-content-wrapper #QPopup {
  color: #000000;
  text-align: center;
  min-width: unset;
}
#map a.leaflet-popup-close-button {
  top: 2px;
  right: 2px;
}
#map a.leaflet-popup-close-button:hover {
  text-decoration: none;
}
#map .QMapContent {
  background: #FFFFFF;
  font-family: Roboto, sans-serif;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(253, 148, 148, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: 2px solid #000000;
  padding: 10px 10px 10px;
}
#map .QMapContent .QMapHeader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  padding: 0.2rem;
}
#map .QMapContent .QMapHeader h5 {
  font-weight: 300;
  font-size: 1.25rem;
}
#map .QMapContent .QMapHeader button {
  padding: 0.4rem 0.6rem;
  cursor: pointer;
  opacity: 0.5;
  color: #000000;
  text-shadow: 0 1px 0 #FFFFFF;
}
#map .QMapContent .QMapBody {
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 465px;
  height: 480px;
  padding: 1rem;
}
#map .QMapContent .QMapBody #map {
  margin: 10px 0;
}
#map .QMapContent .QMapBody #QueryMapModal {
  width: 100%;
  height: 100%;
}
#map .QMapContent .QMapBody #QrangeCircle {
  padding: 10px 0;
  display: block;
}
#map .QMapContent .QMapBody #QrangeCircle .radio-input {
  display: grid;
  grid-template-areas: "slider radio";
  grid-template-columns: 90% 10%;
  grid-template-rows: 100%;
}
#map .QMapContent .QMapBody #QrangeCircle .radio-input span {
  width: 45px;
  height: 45px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #636363;
  color: #FFFFFF;
  text-align: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#map .QMapContent .QMapBody #QrangeCircle .radio-input span::before {
  content: "";
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  left: -5px;
  width: 10px;
  height: 10px;
  background: #eae8ea;
}
#map .QMapContent .QMapBody #QrangeCircle .radio-input .slider {
  display: flex;
  align-items: center;
}
#map .QMapContent .QMapBody #QrangeCircle .radio-input .slider input {
  width: 100%;
  display: flex;
  align-items: center;
  background: #111111;
  border-radius: 2px;
  outline: none;
}
#map .QMapContent .QMapFooter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 4rem 0 0;
  border-top: 1px solid #dee2e6;
}
#map .QMapContent .QMapFooter #close_map {
  background: #535353;
  height: 30px;
}
#map .QMapContent .QMapFooter #save_changes {
  background: #4285f4;
  margin-left: 0.25rem;
  height: 30px;
}
#map .QMapContent .QMapFooter button {
  border: none;
  color: #FFFFFF;
  cursor: pointer;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0.125rem;
  font-size: 0.81rem;
  padding: 0.84rem 2.14rem;
}

.QueryBody .Qerror {
  -webkit-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
  transition-duration: 0.7s;
  color: red;
}
.QueryBody .Qerror small {
  display: unset !important;
  margin: -20px 0;
}
.QueryBody .Qerror .QWarning {
  visibility: visible !important;
  color: red;
  position: absolute;
  top: 0;
  left: 0;
}
.QueryBody .Qerror label {
  color: red !important;
}
.QueryBody .Qerror input {
  color: red !important;
}
.QueryBody .QBodyContent {
  padding: 50px 0 2rem;
}
.QueryBody .QBodyContent .infoMessageContent h4 {
  color: #667f00;
}
.QueryBody .QBodyContent .infoMessageContent p {
  color: #646464;
}
.QueryBody .QBodyContent .infoBodyContent {
  margin: 0rem 0rem 2rem 0rem;
}
.QueryBody .QBodyContent .infoBodyContent h4 {
  color: #667f00;
}
.QueryBody .QBodyContent .infoBodyContent p {
  color: #646464;
}
.QueryBody .QBodyContent .Q_main {
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 15px;
}
.QueryBody .QBodyContent .Q_advanced {
  margin-top: 1rem;
}
.QueryBody .QBodyContent .Q_advanced .disabledInput {
  opacity: 0.5;
  z-index: -1;
}
.QueryBody .QBodyContent .Q_advanced .MuiAccordionDetails-root {
  display: -ms-grid;
  display: grid;
  background-color: #F2F2F2;
  padding-left: 21px;
  padding-right: 21px;
}
.QueryBody .QBodyContent .Q_advanced .MuiAccordionDetails-root .MuiOutlinedInput-root {
  background-color: #FFFFFF;
}
.QueryBody .QBodyContent .Q_advanced h4 {
  color: #646464;
}
.QueryBody .QBodyContent .Q_action_form {
  display: flex;
}
.QueryBody .QBodyContent .Q_action_form .Q_action_form_text {
  width: 60%;
  margin-top: 2rem;
  font-size: 10pt;
  color: #BABABA;
}
.QueryBody .QBodyContent .Q_action_form .Q_action_form_buttons {
  width: 40%;
  margin-top: 2rem;
  display: flex;
}
.QueryBody .QBodyContent .Q_action_form .Q_action_form_buttons button {
  width: 100%;
  margin: 0rem 0.3rem;
  font-weight: bold;
}
.QueryBody .QBodyContent .Q_action_form .Q_action_form_buttons #QConsult {
  background-color: #005833;
  color: #FFFFFF;
}
.QueryBody .QBodyContent .Q_action_form .Q_action_form_buttons .QConsultDiabled {
  opacity: 0.6;
}
.QueryBody .QBodyContent .QselectionsArea {
  display: flex;
}
.QueryBody .QBodyContent .QselectionsArea .coordinatesArea {
  width: 35%;
}
.QueryBody .QBodyContent .QselectionsArea .mapArea {
  width: 65%;
}
.QueryBody .QBodyContent #Qselectionsarea {
  margin-top: 0.5rem;
}
.QueryBody .QBodyContent .Qselections {
  width: 100%;
}
.QueryBody .QBodyContent .QselectLayer {
  height: max-content !important;
}
.QueryBody .QBodyContent .Qselector-error {
  padding: 1rem;
  color: red;
}
.QueryBody .QBodyContent .Qselector {
  margin: 8px 0;
  height: max-content;
  width: 100%;
  display: grid;
}
.QueryBody .QBodyContent .Qselector .radio_area {
  flex-direction: row;
}
.QueryBody .QBodyContent .Qselector small {
  display: none;
}
.QueryBody .QBodyContent .Qselector .Qselector_input {
  height: 5.1rem;
  position: relative;
  display: grid;
  display: -ms-grid;
  align-items: flex-end;
  width: 100%;
  height: 100%;
}
.QueryBody .QBodyContent .Qselector .Qselector_input .MuiInputLabel-shrink {
  transform: translate(14px, -10px) scale(1.3);
}
.QueryBody .QBodyContent .Qselector .Qselector_input.QTime .MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(1.3);
}
.QueryBody .QBodyContent .Qselector .Qselector_input .Qlabel_inputs {
  position: relative;
  background: #FFFFFF;
  width: max-content;
  color: #212529;
}
.QueryBody .QBodyContent .Qselector #QLoading {
  height: max-content;
}
.QueryBody .QBodyContent .Qselector .QWarning {
  visibility: hidden;
  position: absolute;
}
.QueryBody .QBodyContent .Qselector legend {
  padding-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 1rem !important;
  color: #212529;
}
.QueryBody .QBodyContent .Qselector #Qdraw_map {
  background: #33b5e5 !important;
  color: #FFFFFF;
  width: max-content;
  border-radius: 0.125rem;
  border: none;
  height: 50px;
  margin: 15px auto;
  cursor: pointer;
  padding: 0.84rem 2.14rem;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.QueryBody .QBodyContent .Qselector #Qdraw_map:hover {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 10px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 10px 10px 0 rgba(0, 0, 0, 0.12);
}
.QueryBody .QBodyContent .QDate {
  display: block !important;
}
.QueryBody .QBodyContent .QDate div {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.QueryBody .QBodyContent .QDate label {
  padding-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 1rem !important;
  color: #212529;
  position: initial !important;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

@media (max-width: 500px) {
  .QMapContent {
    width: 100%;
  }
  .QMapContent .QMapBody {
    padding: 0 !important;
    width: 100% !important;
    height: 300px !important;
  }
  .QMapContent .QMapFooter {
    padding: 2.6rem 0 0 !important;
  }
  .Qselector .radio_area {
    flex-direction: column !important;
  }
}
@media (max-width: 825px) {
  .QselectionsArea {
    display: grid !important;
  }
  .QselectionsArea .coordinatesArea {
    width: 100% !important;
  }
  .QselectionsArea .mapArea {
    width: 100% !important;
    height: 400px;
  }
  .Q_action_form {
    display: block !important;
  }
  .Q_action_form .Q_action_form_text {
    width: 100% !important;
  }
  .Q_action_form .Q_action_form_buttons {
    width: 100% !important;
  }
}
.flexItemCatalog .dates-icons-option {
  margin-right: 8px;
  margin-left: 0px !important;
  width: 20px;
  height: 20px;
  border: none;
}

/**_QueryBody.sass* */
/**_QueryModal */
.QmodalOpen {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.QmodalOpen .QmodalContainer {
  height: 160px;
  width: 70%;
  margin: auto;
  border: 8px solid #696969;
  background: #FFFFFF;
  color: #696969;
}
.QmodalOpen .QmodalContainer .QmodalHead {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.QmodalOpen .QmodalContainer .QmodalHead h2 img {
  width: 20px;
  padding: 2px 0;
}
.QmodalOpen .QmodalContainer .QmodalBody {
  padding: 20px;
}
.QmodalOpen .QmodalContainer .QmodalBody p {
  text-align: justify;
}
.QmodalOpen .QmodalContainer .QmodalBody button {
  float: right;
  color: #2d66d1;
  border: none;
  background: transparent;
  padding: 20px 20px 0 0;
  cursor: pointer;
}

@media (max-width: 420px) {
  .QmodalBody p {
    font-size: 8pt;
  }
}
.modalPresentation {
  scroll-behavior: smooth;
}

.CatalogModalContainer {
  background: #FFFFFF;
  width: 100%;
}
.CatalogModalContainer .container-header {
  position: fixed;
  width: 100%;
  height: 70px;
  z-index: 1000;
  background: #667f00;
}
.CatalogModalContainer .queryModalContent {
  padding-top: 70px;
}

/**_QueryModal */
.ExcelModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
}
.ExcelModal .QExcelModal {
  display: grid;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  padding: 20px;
  position: relative;
}
.ExcelModal .QExcelModal::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 6px solid #646464;
}
.ExcelModal .QExcelModal .QExcelIcon {
  display: flex;
  align-items: center;
}
.ExcelModal .QExcelModal .QExcelIcon span {
  margin: 0 10px;
}
.ExcelModal .QExcelModal .QExcelClose {
  z-index: 1000;
  padding: 20px 10px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.ExcelModal .QExcelModal .QExcelClose span {
  cursor: pointer;
  color: #646464;
}
.ExcelModal .QExcelModal .QExcelBody {
  z-index: 1000;
  min-width: 700px;
}
.ExcelModal .QExcelModal .QExcelBody p {
  padding: 10px 0;
}
.ExcelModal .QExcelModal .QExcelBody small {
  font-size: 10px;
}
.ExcelModal .QExcelModal .QExcelBody .QDownload {
  display: block;
  cursor: pointer;
}
.ExcelModal .QExcelModal .QExcelBody .QDownload span {
  display: block;
  text-decoration: underline;
  color: #0700ED;
}
.ExcelModal .QExcelModal .QExcelBody .QDownload .QDownloadLink {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
}
.ExcelModal .QExcelModal .QExcelBody .QDownload:hover .QDownloadLink {
  display: block;
  background: #f1f1f1;
  width: 100%;
  color: #000000;
}
.ExcelModal .QExcelModal .QExcelBody .QDownloadDesc {
  opacity: 0.5;
  color: #646464;
}

.flexItemCatalog {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexItemCatalog img {
  width: auto;
  margin-left: 10px;
}

.QTableModal_loading {
  display: grid;
  align-items: center;
  justify-items: center;
  margin: auto;
  width: 200px;
}
.QTableModal_loading .MuiLinearProgress-barColorPrimary {
  background-color: #005833 !important;
}
.QTableModal_loading h3 {
  display: block;
  text-align: center;
  color: #FFFFFF;
}

.container-table-consult td, .container-table-consult th {
  font: inherit;
}
.container-table-consult .MuiInput-underline::after {
  border-bottom: none !important;
}
.container-table-consult .TensorMinHeightImg {
  max-width: 35px;
}
.container-table-consult .MoreInfoTableConsult {
  background: #005833;
  color: #FFFFFF;
  text-decoration: none;
  height: max-content;
  display: block;
  border-radius: 5px;
  padding: 5px;
}
.container-table-consult .MoreInfoTableConsult:hover {
  text-decoration: underline;
  cursor: pointer;
}
.container-table-consult tr td {
  text-align: center;
  padding: 12px;
}
.container-table-consult tr td:hover {
  cursor: pointer;
}
.container-table-consult tr.selected {
  background-color: #F1F7F2 !important;
}
.container-table-consult tr.selected td {
  cursor: auto !important;
}
.container-table-consult th {
  background: #005833 !important;
  border-right: 1px solid #FFFFFF;
  border-left: 1px solid #FFFFFF;
}
.container-table-consult th span {
  justify-content: center;
}
.container-table-consult h6 {
  font-size: 20px;
  font-weight: normal;
}
.container-table-consult .tableConsultCell {
  display: flex;
  justify-content: center;
  padding: 0 24px 0 24px;
}
.container-table-consult .tableConsultFooter {
  display: flex;
  align-items: center;
}
.container-table-consult .title-table-consult {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 24px;
  height: 55px;
}
.container-table-consult .content-table-consult {
  overflow: scroll;
}
.container-table-consult .content-table-consult table td, .container-table-consult .content-table-consult table th {
  min-width: 100px;
  height: 50px;
  font-size: 15px;
}
.container-table-consult .content-table-consult table .id {
  min-width: 129px;
}
.container-table-consult .content-table-consult table .place {
  width: 150px;
}
.container-table-consult .content-table-consult table .MajorTable {
  min-width: max-content !important;
  padding: 0 10px;
}
.container-table-consult .content-table-consult table .time {
  min-width: 150px;
}
.container-table-consult .content-table-consult table .Qactions_button {
  min-width: 180px;
  outline: none;
  color: #667f00;
  text-decoration: underline;
  border: none;
  cursor: pointer;
}
.container-table-consult .content-table-consult table tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.container-table-consult .content-table-consult table tbody tr:nth-child(even) {
  background-color: #FFFFFF;
}
.container-table-consult .content-table-consult table #encabezado {
  background-color: #598000;
  color: #FFFFFF;
}
.container-table-consult .content-table-consult table #encabezado th {
  border: 0.5px solid #FFFFFF;
}
.container-table-consult .pagination-table-consult {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}
.container-table-consult .pagination-table-consult .pagination_loading {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0 10px;
}
.container-table-consult .pagination-table-consult .pagination_loading .pages_loaded {
  font-size: 14px;
  padding-left: 5px;
}
.container-table-consult .pagination-table-consult .pagination-results p {
  font-size: 14px;
}
.container-table-consult .pagination-table-consult .pagination-buttons {
  margin: 0px 0px 0px 20px;
}

.container-table-consult-movil h6 {
  font-size: 20px;
  font-weight: normal;
}
.container-table-consult-movil .title-table-consult-movil {
  display: block;
  padding: 0.5rem 1rem;
}
.container-table-consult-movil .title-table-consult-movil .container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.container-table-consult-movil .title-table-consult-movil .filter-inputs {
  padding-top: 0 !important;
  padding-left: 5px;
}
.container-table-consult-movil .content-table-consult-movil {
  width: 100%;
  overflow-y: scroll;
}
.container-table-consult-movil .content-table-consult-movil .tableHeader {
  width: 100%;
  display: grid;
  grid-template-areas: "tableColumnHeader" "tableColumnHeader";
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}
.container-table-consult-movil .content-table-consult-movil .tableHeader .tableColumnHeader {
  padding: 0.5rem;
  text-align: center;
}
.container-table-consult-movil .content-table-consult-movil .tableItem {
  width: 100%;
  display: grid;
  grid-template-areas: "tableColumnItem" "tableColumnItem";
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
}
.container-table-consult-movil .content-table-consult-movil .tableItem .tableColumnItem {
  padding: 0.5rem;
  text-align: center;
}
.container-table-consult-movil .content-table-consult-movil .tableHeaderTablet {
  width: 100%;
  display: grid;
  grid-template-areas: "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet" "tableColumnHeaderTablet";
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 100%;
}
.container-table-consult-movil .content-table-consult-movil .tableHeaderTablet .tableColumnHeaderTablet {
  padding: 0.5rem;
  text-align: center;
}
.container-table-consult-movil .content-table-consult-movil .tableItemTablet {
  width: 100%;
  display: grid;
  grid-template-areas: "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet" "tableColumnItemTablet";
  grid-template-columns: 20% 20% 20% 20% 20%;
  grid-template-rows: 100%;
  align-items: center;
}
.container-table-consult-movil .content-table-consult-movil .tableItemTablet .tableColumnItemTablet {
  padding: 0.5rem;
  text-align: center;
}
.container-table-consult-movil .content-table-consult-movil .content-accordion .Qactions_button {
  text-decoration: none;
  color: #667F00;
}
.container-table-consult-movil .content-table-consult-movil .content-accordion div {
  padding: 0.5rem 0rem;
}
.container-table-consult-movil .pagination-table-consult-movil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}
.container-table-consult-movil .pagination-table-consult-movil .pagination_loading {
  width: max-content;
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.container-table-consult-movil .pagination-table-consult-movil .pagination_loading .pages_loaded {
  font-size: 14px;
  padding-left: 5px;
}
.container-table-consult-movil .pagination-table-consult-movil .pagination-results p {
  font-size: 14px;
}
.container-table-consult-movil .pagination-table-consult-movil .pagination-buttons {
  margin: 0px 0px 0px 20px;
}

@media (max-width: 840px) {
  .title-table-consult-movil {
    display: block !important;
  }
  .title-table-consult-movil h6 {
    display: block;
    padding-left: 10px;
  }
}
@media (max-width: 445px) {
  .pagination-table-consult-movil {
    padding: 10px 0 !important;
    display: grid !important;
    position: relative;
    height: 120px;
  }
  .pagination-table-consult-movil .pagination-results {
    position: absolute;
    left: 30%;
    top: 10px;
  }
  .pagination-table-consult-movil .pagination-results p {
    display: inline;
  }
  .pagination-table-consult-movil .pagination_loading {
    position: absolute;
    left: 25%;
  }
  .pagination-table-consult-movil .pagination-buttons {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0 !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}
@media (max-width: 374px) {
  #chip {
    max-width: 220px;
    font-size: 7pt;
  }
  .container-table-consult-movil h6 {
    font-size: 20px;
    font-weight: normal;
  }
  .container-table-consult-movil .content-table-consult-movil {
    font-size: 10pt;
  }
  .container-table-consult-movil .content-table-consult-movil table tr td {
    width: 70%;
    height: 80px;
    padding: 0rem 0.5rem;
  }
  .container-table-consult-movil .content-table-consult-movil table tr td a {
    font-size: 8pt;
  }
  .container-table-consult-movil .content-table-consult-movil table tr th {
    width: 30%;
    height: 80px;
    padding: 0rem 0.5rem;
  }
}
@media (max-width: 800px) {
  .QExcelBody {
    min-width: 90% !important;
  }
}
.bannerQuery {
  width: 100%;
  height: 240px;
  background-image: url("../assets/icons/Query/temp.jpg");
  background-size: cover;
  background-position: center center;
  position: relative;
}
.bannerQuery .titleBanner {
  position: absolute;
  background-color: #005833;
  color: #FFFFFF;
  font-weight: bold;
  max-width: 350px;
  padding: 0px 30px;
  min-height: 40px;
  bottom: -10px;
  left: 12.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerQuery .titleBanner h3 {
  margin: 10px;
  font-size: 17px;
}

/**EQHeader.jsx**/
.EQLegalHd {
  grid-area: EQLegalHd;
  margin-bottom: 5px;
}

#accessibility {
  cursor: pointer;
}
#accessibility span {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.3s;
}
#accessibility span img {
  width: 100%;
  height: 100%;
}
#accessibility .global_view {
  width: 80px;
}
#accessibility .global_view .button_header {
  opacity: 0.5;
}

.EQButtonActions {
  grid-area: EQButtonActions;
  position: relative;
  display: -ms-grid;
  display: grid;
  height: 70px;
  -ms-grid-rows: 100%;
  grid-template-rows: 100%;
  -ms-grid-columns: 6% 74% 20%;
  grid-template-columns: 6% 84% 10%;
  grid-template-areas: "menubutton sgc-logo-container container-buttons";
  background: #005833;
  z-index: 100;
}
.EQButtonActions #MapServices {
  top: 100% !important;
}
.EQButtonActions .container-buttons .list-buttons .item-buttons-list .button-header {
  filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
}
.EQButtonActions .container-buttons .activate_services {
  width: 30px;
  filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
}
.EQButtonActions .sgc-logo-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.EQButtonActions .sgc-logo-container .sgc-logo-a {
  display: none;
}
.EQButtonActions .sgc-logo-container .sgc-logo-b {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 50px;
}
.EQButtonActions .sgc-logo-container .sgc-logo-b img {
  width: 135px;
}
.EQButtonActions .container-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
  margin-right: 50px;
  height: 70px;
}
.EQButtonActions .container-buttons ul .list-buttons {
  margin: 0rem 50px;
}
.EQButtonActions .container-buttons ul .list-buttons .item-buttons-list {
  margin: 0rem 0.1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQButtonActions .menubutton {
  grid-area: menubutton;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-self: center;
  justify-self: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.EQButtonActions .menubutton .burger_hamburger-inner__14XT2 {
  background: #FFFFFF;
}
.EQButtonActions .menubutton .burger_hamburger-inner__14XT2::after, .EQButtonActions .menubutton .burger_hamburger-inner__14XT2::before {
  background: #FFFFFF;
}

.container-buttons ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.container-buttons ul #ViInfo {
  visibility: hidden;
}
.container-buttons ul li {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  cursor: pointer;
}
.container-buttons ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
.container-buttons ul li #mapLink {
  padding: 0px;
}
.container-buttons ul li .material-icons {
  color: #FFFFFF;
  font-size: 28px;
  height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 28px;
}
.container-buttons ul li + li {
  margin-left: 0.5em;
  height: 30px;
}
.container-buttons ul li .button-header {
  -webkit-filter: none;
  filter: none;
  background: none;
}
.container-buttons ul li.selected .button-header {
  -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
  filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
}

@media (min-width: 1200px) {
  .EQButtonActions {
    background: #005833;
  }
  .EQButtonActions .container-buttons .list-buttons .item-buttons-list .button-header {
    filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important;
  }
  .EQButtonActions .menubutton .burger_hamburger-inner__14XT2 {
    background: white;
  }
  .EQButtonActions .menubutton .burger_hamburger-inner__14XT2::after, .EQButtonActions .menubutton .burger_hamburger-inner__14XT2::before {
    background: white;
  }
  .EQButtonActions .sgc-logo-container {
    grid-area: sgc-logo-container;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: space-between;
    height: 100%;
  }
  .EQButtonActions .sgc-logo-a {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .EQButtonActions .sgc-logo-a img {
    width: 150px;
  }
  .EQButtonActions .sgc-logo-b {
    display: none !important;
  }
  .EQButtonActions .sgc-logo-b img {
    width: 135px;
  }
}
@media (max-width: 1024px) {
  .EQheader {
    height: 70px;
    -ms-grid-rows: 20px 50px;
    grid-template-rows: 20px 50px;
  }
  .EQheader .EQButtonActions {
    -ms-grid-columns: 16% 64% 30%;
    grid-template-columns: 16% 54% 30%;
  }
}
@media screen and (max-width: 767px) {
  .EQheader .EQButtonActions .sgc-logo-container .sgc-logo-a {
    display: none;
  }
  .EQheader .EQButtonActions .sgc-logo-container .sgc-logo-b {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    align-items: center;
  }
  .EQheader .EQButtonActions .sgc-logo-container .sgc-logo-b img {
    width: 100px;
    height: 55px;
  }
  .EQheader .EQButtonActions .container-buttons {
    margin-right: 30px;
  }
  .EQheader .EQsummary-container .EQsummary .summarydata {
    font-size: 12pt;
  }
  .EQheader .EQsummary-container .EQsummary .summarydata .space-line1, .EQheader .EQsummary-container .EQsummary .summarydata .space-line {
    display: block;
  }
  .EQheader .EQsummary-container .EQsummary .summaryplace {
    font-size: 16pt;
  }
}
/**EQHeader.jsx**/
/**EQMenu.jsx**/
/**_EQMenu */
.EQmenu {
  width: 100%;
  height: 100%;
}
.EQmenu .products-list {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
.EQmenu .products-list .menuheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 100%;
  height: 120px;
  background-image: url("../assets/icons/EQDetail/pruebaVisores-01.png");
  background-size: cover;
  border-bottom: 2px solid #FFFFFF;
  font-family: "Montserrat", sans-serif;
}
.EQmenu .products-list .menuheader h2 {
  font-size: 16pt;
  color: #FFFFFF;
  padding-left: 20px;
}
.EQmenu .products-list .menuheader h5 {
  font-size: 10pt;
  margin-top: 20px;
  margin-left: 20px;
  color: #FFFFFF;
}
.EQmenu .products-list .menuheader hr {
  height: 4px;
  width: 100%;
  border-top: 1px dotted #FFFFFF;
  background-color: #FFFFFF;
  margin-top: 5px;
  margin-bottom: 5px;
}
.EQmenu .products-list .volcanos-filter-container {
  background: #f1f1f1;
  padding: 1rem;
  border: 1px solid #005833;
}
.EQmenu .products-list .EQMenu-list-container {
  background: #3E3E33;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content {
  display: block;
  padding: 0 20px 12px;
  overflow-y: scroll;
  height: calc(100vh - 220px);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content::-webkit-scrollbar {
  width: 10px;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content::-webkit-scrollbar-track {
  background: #33362d;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content::-webkit-scrollbar-thumb {
  background: #005833;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content::-webkit-scrollbar-thumb:hover {
  background: #636363;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content li {
  list-style: none;
  height: 55px;
  border-bottom: 1px solid #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content li:hover p, .EQmenu .products-list .EQMenu-list-container .EQMenu_list_content li.active p {
  color: #D7A53C;
  font-weight: bolder;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content a.EQMenu-list-item {
  width: 100%;
  display: -ms-grid;
  display: grid;
  text-decoration: none;
  color: #FFFFFF;
  -ms-grid-columns: 40px 15px auto;
  grid-template-columns: 40px 15px auto;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1rem;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content a.EQMenu-list-item > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content a.EQMenu-list-item > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content a.EQMenu-list-item > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content a.EQMenu-list-item img {
  width: 100%;
}
.EQmenu .products-list .EQMenu-list-container .EQMenu_list_content hr {
  width: 1px;
  height: 27px;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1.5px solid #F6F6F6;
  padding-left: 6px;
}
.EQmenu .products-list .EQMenu-list-container #EQMenu_list_content_volcano {
  overflow-y: scroll;
  height: calc(100vh - 330px);
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  padding: 5px 0;
  font-size: 11px;
  background-color: white;
}
.EQmenu .products-list .EQMenu-list-container #EQMenu_list_content_volcano a {
  text-decoration: none;
}

@media (min-width: 1238px) {
  .products-list {
    overflow: hidden;
    position: fixed;
    width: 22% !important;
  }
}
@media (max-width: 600px) {
  #EQMenu_list_content_detail {
    height: calc(100vh - 200px) !important;
  }
}
/**_EQMenu */
/**EQMenu.jsx**/
/**EQMain.jsx**/
/*EQDetails Menu */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.sgc-logo-a img {
  width: 120px;
  height: 50px;
}

/*EQDetails Menu */
/* Slide Menu */
/* EQsummry CSS */
/**SummayGrid */
.EQSummary {
  padding: 8px 40px 0.2rem 40px;
  background: #f1f1f1;
  -webkit-box-shadow: 10px 0px 13px -7px rgba(0, 0, 0, 0.5) inset, 5px 5px 15px 5px rgba(0, 0, 0, 0) inset;
  box-shadow: 10px 0px 13px -7px rgba(0, 0, 0, 0.5) inset, 5px 5px 15px 5px rgba(0, 0, 0, 0) inset;
}
@media (max-width: 800px) {
  .EQSummary {
    padding: 6px 5vw;
  }
}
.EQSummary .EQSummaryTitle {
  color: #005833;
  text-align: center;
  font-size: 18pt;
  border-bottom: 2px solid #005833;
  padding-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}
@media (max-width: 800px) {
  .EQSummary .EQSummaryTitle {
    font-size: 15pt;
    padding: 7px 0;
  }
}
.EQSummary .EQSummaryTitle h2 {
  font-weight: 800;
}

.DetailsInfoGrid {
  display: grid;
  border: 1px solid white;
  margin: 16px 0;
  border-radius: 8px;
  overflow: hidden;
}
.DetailsInfoGrid.Summary {
  grid-template-columns: 1fr 1fr 0.75fr;
}
.DetailsInfoGrid.Summary .InfoRow {
  grid-template-columns: 0.5fr 1fr;
}
@media (max-width: 1050px) and (min-width: 801px) {
  .DetailsInfoGrid.Summary {
    grid-template-columns: 1fr 1fr;
  }
  .DetailsInfoGrid.Summary .InfoColumn:nth-child(3) {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .DetailsInfoGrid.Summary .InfoColumn:nth-child(3) .InfoRow {
    background-color: transparent;
  }
}
.DetailsInfoGrid.Volcano {
  grid-template-columns: 1fr 1fr;
}
.DetailsInfoGrid.Volcano .InfoRow {
  grid-template-columns: 0.7fr 1fr;
}
@media (max-width: 800px) {
  .DetailsInfoGrid.Volcano .InfoColumn:nth-child(2) .InfoRow:nth-child(odd) {
    background-color: white;
  }
  .DetailsInfoGrid.Volcano .InfoColumn:nth-child(2) .InfoRow:nth-child(even) {
    background-color: transparent;
  }
}
.DetailsInfoGrid .InfoColumn {
  overflow: hidden;
}
@media (max-width: 800px) {
  .DetailsInfoGrid {
    display: block;
  }
  .DetailsInfoGrid .InfoRow .InfoTitle {
    padding: 10px 12px;
  }
}
@media (max-width: 500px) {
  .DetailsInfoGrid .InfoRow {
    grid-template-columns: 0.75fr 1fr;
  }
}

.InfoRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1px;
}
.InfoRow.Small {
  grid-template-columns: 0.75fr 1fr;
}
.InfoRow .InfoTitle {
  background-color: #005833;
  color: white;
  font-weight: bold;
  padding: 13px 10px;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  align-content: center;
}
.InfoRow .InfoText {
  padding: 5px 12px;
  display: flex;
  align-items: center;
}
.InfoRow .InfoText .level_volcano {
  justify-content: flex-start;
}
.InfoRow:nth-child(even) {
  background-color: white;
}

@media (min-width: 200px) {
  .EQSummary .summary_hidden {
    display: none;
  }
  .EQSummary .SummaryGrid {
    overflow-x: hidden;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 38% 32% 30%;
    grid-template-columns: 38% 32% 30%;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    padding: 10px 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: "Oxigen";
    font-size: 12pt;
  }
  .EQSummary .SummaryGrid > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .EQSummary .SummaryGrid > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
  .EQSummary .SummaryGrid > *:nth-child(3) {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .EQSummary .SummaryGrid p {
    display: -ms-grid;
    display: grid;
  }
  .EQSummary .SummaryGrid p strong {
    color: #005833;
  }
}
@media (min-width: 1200px) {
  .EQSummary .EQSummaryTitle {
    margin: 10px 0 10px 0;
  }
  .EQSummary .summary_hidden {
    display: unset;
    padding-right: 5px;
    color: #3E3E33;
  }
  .EQSummary .SummaryGrid p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: inline;
    font-size: 13pt;
  }
  .EQSummary .SummaryGrid p span:nth-child(1) strong {
    color: #535353;
  }
  .EQSummary .SummaryGrid p span:nth-child(2) {
    color: #646464;
  }
}
/**SummayGrid */
.EQContentManager {
  padding: 35px 40px 0;
}
.EQContentManager .EQSectionContainer {
  margin-bottom: 16px;
}
.EQContentManager .EQSectionContainer:last-child {
  margin-bottom: 0;
}
@media (max-width: 800px) {
  .EQContentManager {
    padding: 4vw;
  }
}

/* EQsummry CSS */
/* EQoverview */
.EQoverview {
  /* EQoverview */
  /* ---------------------PRODUCT --------------------- */
}
.EQoverview .CardsTitle h3 {
  color: #005833;
  margin: 1rem 1rem 0.5rem;
}
.EQoverview .CardsContent {
  display: flex;
  margin-bottom: 15px;
}
.EQoverview .CardsContent .overViewGrid {
  width: 40%;
  margin: 0 auto;
  overflow: auto;
}
.EQoverview .CardsContent .container-eventmap-overview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  padding-right: 20px;
}
.EQoverview .CardsContent .container-eventmap-overview .eventmap {
  width: 100%;
  max-height: 1200px;
  -webkit-box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  -moz-box-shadow: -7px 10px 14px rgb(235, 235, 235);
  box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  padding-bottom: 20px;
  background: #FFFFFF;
}
.EQoverview .CardsContent .overviewtable {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.EQoverview .CardsContent .overviewtable td a {
  color: #000000;
  text-decoration: none;
}
.EQoverview .CardsContent .overviewtable th {
  border-right: 2px solid #005833;
}
.EQoverview .CardsContent .overviewtable td, .EQoverview .CardsContent .overviewtable th {
  text-align: left;
  padding: 16px;
  height: 38px;
}
.EQoverview .CardsContent .overviewtable .EQoverHref {
  cursor: pointer;
}
.EQoverview .CardsContent .overviewtable .EQoverHref td img {
  height: 30px;
}
.EQoverview .CardsContent .overviewtable .EQoverHref .short_icon .dates-icons {
  height: 30px;
}
.EQoverview .CardsContent .overviewtable .EQoverHref .short_icon img {
  width: 1.875rem !important;
  height: 30px;
  margin-left: 0;
}
.EQoverview .CardsContent .overviewtable tr:nth-child(odd) {
  background-color: #f2f2f2;
}
.EQoverview .CardsContent .overviewtable tr:nth-child(odd) .dates-icons {
  height: 20px;
  max-width: 100%;
}
.EQoverview .CardsContent .overviewtable .EQoverviewcell {
  width: 10%;
  text-align: left;
}
.EQoverview .Mui-selected span {
  color: #005833;
}
.EQoverview .MuiTabs-indicator {
  background-color: #005833 !important;
}

@media (max-width: 1400px) {
  .EQoverview .EQSectionContainer .CardsContent {
    display: block;
  }
  .EQoverview .EQSectionContainer .CardsContent .container-eventmap-overview {
    margin-bottom: 20px;
  }
  .EQoverview .EQSectionContainer .eventmap, .EQoverview .EQSectionContainer .overViewGrid {
    width: 90% !important;
    margin: auto;
  }
  .EQoverview .EQSectionContainer .eventmap .overViewGrid, .EQoverview .EQSectionContainer .eventmap .container-eventmap-overview, .EQoverview .EQSectionContainer .overViewGrid .overViewGrid, .EQoverview .EQSectionContainer .overViewGrid .container-eventmap-overview {
    width: 100%;
  }
  .EQoverview #container-resumen-products {
    margin: 0.5rem 0rem;
    padding: 0rem;
  }
  .EQoverview #container-resumen-1 {
    margin: 0.5rem 0rem;
  }
}
@media (max-width: 900px) {
  .eventmap {
    padding-right: 0 !important;
  }
  .EQoverview .container-eventmap-overview {
    padding-right: 0 !important;
  }
  .EQoverview #container-resumen-products {
    margin: 0.5rem 0rem;
    padding: 0rem;
  }
  .EQoverview #container-resumen-1 {
    margin: 0.5rem 0rem;
  }
  .EQoverview .EQCardsContainer {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .EQoverview .CardsContent .overviewtable td, .EQoverview .CardsContent .overviewtable th {
    padding: 7px 15px;
  }
}
/**EQMain.jsx**/
/**EQOrigen.jsx**/
.OriginTable tr th:nth-child(4) {
  min-width: 200px;
}

.container-trace {
  text-align: center;
  height: 450px;
  overflow-y: scroll;
  scrollbar-color: #005833 #f1f1f1;
  scrollbar-width: thin;
}

.container-trace::-webkit-scrollbar {
  width: 10px;
}

.container-trace::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.container-trace::-webkit-scrollbar-thumb {
  background: #005833;
}

.container-trace::-webkit-scrollbar-thumb:hover {
  background: #636363;
}

/* EQOrigen */
.EQorigen .container-origin-tabs {
  margin: 22px 0px;
}
.EQorigen .preferedorigin {
  color: #005833;
}
.EQorigen .vieworigen {
  font-size: 30px;
  cursor: pointer;
}
.EQorigen .TableDetails {
  width: 60%;
  margin: auto;
  overflow-x: auto;
}
.EQorigen .TableDetails .EQHide {
  display: none;
}
.EQorigen .TableDetails td {
  padding: 5px 20px;
  width: 50%;
  line-height: 1.4;
}
.EQorigen .origincontributors #OriginInvisibleData {
  display: none;
}
.EQorigen .origincontributors .subtitle {
  color: #535353;
  font-family: "Oxigen";
}

/* EQOrigen */
@media (max-width: 800px) {
  .EQorigen .TableDetails {
    width: 100%;
  }
  .EQorigen .TableDetails td {
    padding: 5px 15px;
    width: auto;
  }
}
/**EQOrigen.jsx**/
/**EQMmi.jsx**/
/**EQmmiTabs* */
.EQintensity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQintensity .container-img {
  height: auto;
  width: auto;
  min-height: 200px;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  -moz-box-shadow: -7px 10px 14px rgb(235, 235, 235);
  box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
}
.EQintensity .container-img img {
  width: 90%;
}

.EQpga, .EQpgv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQpga .container-img, .EQpgv .container-img {
  height: auto;
  width: auto;
  padding: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  -moz-box-shadow: -7px 10px 14px rgb(235, 235, 235);
  box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
}
.EQpga .container-img img, .EQpgv .container-img img {
  width: 100%;
}

@media (max-width: 767px) {
  .EQintensity .container-img, .EQpga .container-img, .EQpgv .container-img {
    height: auto;
    width: auto;
  }
}
@media (min-width: 768px) and (max-width: 1250px) {
  .EQintensity .container-img, .EQpga .container-img, .EQpgv .container-img {
    height: auto;
    width: auto;
  }
}
.EQDownloads {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.EQDownloads .EQDownloads-main {
  text-align: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 2rem 30% 2rem 30%;
  grid-template-columns: 30% 30% 30%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-gap: 2rem;
}
.EQDownloads .EQDownloads-main > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.EQDownloads .EQDownloads-main > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.EQDownloads .EQDownloads-main > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.EQDownloads .EQDownloads-main .DownloadMmi {
  padding: 1rem 1rem;
  border-radius: 8px;
  background: #f1f1f1;
  min-width: 200px;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 20% 1rem 80%;
  grid-template-rows: 20% 80%;
  grid-template-areas: "tittle-option" "DownloadMmi_content";
  grid-gap: 1rem;
}
.EQDownloads .EQDownloads-main .DownloadMmi .tittle-option {
  width: 100%;
  height: 100%;
  grid-area: tittle-option;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-bottom: 2px solid #FFFFFF;
  font-size: 10pt;
}
.EQDownloads .EQDownloads-main .DownloadMmi .tittle-option h2 {
  color: #005833;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content {
  grid-area: DownloadMmi_content;
  border-radius: 8px;
  width: 100%;
  border: 2px solid #005833;
  font-size: 0.75rem;
  background: #FFFFFF;
  padding: 24px 16px;
  margin-bottom: 21px;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content .container-content {
  width: 100%;
  height: 100%;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content .container-content .container-option {
  min-height: 24px;
  width: 100%;
  margin-bottom: 20px;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content .container-content .container-option:last-child {
  margin-bottom: 0;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content .container-content .container-option a {
  text-decoration: none;
}
.EQDownloads .EQDownloads-main .DownloadMmi .DownloadMmi_content .container-content .container-option a .btn_href {
  text-align: center;
  border-radius: 6px;
  overflow: auto;
  background: #005833;
  color: #FFFFFF;
  padding: 7px;
}

@media (max-width: 720px) {
  .EQDownloads .EQDownloads-main {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    -ms-grid-rows: auto 2rem auto;
    grid-template-rows: auto auto;
  }
  .EQDownloads .EQDownloads-main > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .EQDownloads .EQDownloads-main > *:nth-child(2) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
  }
}
/**EQMmi.jsx**/
/**EQmf.jsx**/
.mf-container-main {
  margin: 0rem 1.5rem 1.5rem 1.5rem;
  border: 2px solid #f1f1f1;
  padding-top: 2rem;
}
.mf-container-main .EQmf-main {
  height: 425px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: 100px auto;
  grid-template-rows: 100px auto;
  grid-template-areas: "EQmfTable-container" "EQBeachBall";
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.mf-container-main .EQmf-main .EQmfTable-container {
  width: 820px;
  height: 425px;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable {
  width: 100%;
  height: 100px;
  -webkit-box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  -moz-box-shadow: -7px 10px 14px rgb(235, 235, 235);
  box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable .EQmfTable-head {
  background: #667f00;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable tr {
  height: 33.3px;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable tr:nth-child(even) {
  background: #eae8ea;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable th {
  color: #FFFFFF;
  border-right: 1px solid #FFFFFF;
  font-family: "Roboto-Bold";
  font-size: 12pt;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable .right-item {
  border-right: none;
}
.mf-container-main .EQmf-main .EQmfTable-container .EQmfTable td {
  border-right: 1px solid #667f00;
  font-family: "Roboto";
  font-size: 11pt;
}
.mf-container-main .EQmf-main .EQBeachBall {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mf-container-main .EQmf-main .EQBeachBall img {
  height: 250px;
}

@media (max-device-width: 1023px) {
  .mf-container-main .EQmf-main {
    height: 415px;
  }
  .mf-container-main .EQmf-main .EQmfTable-container {
    width: 730px;
    height: 415px;
  }
}
@media (max-device-width: 823px) {
  .mf-container-main .EQmf-main {
    height: 415px;
  }
  .mf-container-main .EQmf-main .EQmfTable-container {
    width: 530px;
    height: 415px;
  }
}
@media (max-device-width: 623px) {
  .mf-container-main .EQmf-main {
    height: 415px;
  }
  .mf-container-main .EQmf-main .EQmfTable-container {
    width: 330px;
    height: 415px;
  }
}
@media (max-device-width: 423px) {
  .mf-container-main .EQmf-main {
    height: 415px;
  }
  .mf-container-main .EQmf-main .EQmfTable-container {
    width: 250px;
    height: 415px;
  }
}
/**EQmf.jsx**/
/**EQtm.jsx**/
/**EQtm.jsx* */
.container-tmpTensor {
  height: 100%;
  width: 100%;
  padding: 30px 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.EQTensorContainer {
  width: 70%;
  min-width: 600px;
  max-width: 1000px;
}

.EQTmItem {
  margin-bottom: 25px;
}
.EQTmItem .EQTmTitle {
  text-align: center;
  background-color: #005833;
  color: white;
  padding: 8px 12px;
}
.EQTmItem .EQTmTitle.Left {
  text-align: left;
}
.EQTmItem .EQTmContent {
  display: flex;
  gap: 15px;
}
.EQTmItem .EQTmContent .TmBeachball {
  display: flex;
  padding: 8px 8px 0px;
  min-width: 216px;
}
.EQTmItem .EQTmContent .TmBeachball img {
  object-fit: contain;
  overflow: auto;
}

.EQTmTable {
  display: flex;
  width: 100%;
  overflow: auto;
}
.EQTmTable table {
  text-align: left;
}
.EQTmTable table td, .EQTmTable table th {
  padding: 8px;
}
.EQTmTable table th {
  background-color: #f1f1f1;
}
.EQTmTable table.MainTmTable th {
  width: 42%;
}
.EQTmTable table.TmLastTable th {
  width: 25%;
}

@media (max-width: 800px) {
  .EQTensorContainer {
    width: 100%;
    min-width: unset;
  }
  .EQTmItem .EQTmContent .TmBeachball {
    min-width: 20vw;
  }
}
@media (max-width: 600px) {
  .EQTmItem .EQTmContent {
    display: block;
  }
  .EQTmItem .EQTmContent .TmBeachball {
    padding: 20px;
    justify-content: center;
    min-width: unset;
  }
  .EQTmItem .EQTmContent .TmBeachball img {
    width: 170px;
  }
}
.EQTensor_container {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-areas: "fila1" "fila2";
  gap: 45px;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
}
.EQTensor_container .container-table-tm {
  -webkit-box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
  -moz-box-shadow: -7px 10px 14px rgb(235, 235, 235);
  box-shadow: -7px 10px 14px -7px rgb(235, 235, 235);
}
.EQTensor_container .container-table-tm #EQTmTable {
  border-bottom: 0.5px solid #cccccc;
  box-shadow: -7px 10px 14px -7px #ebebeb;
  overflow-x: auto;
}
.EQTensor_container .container-table-tm #EQTmTable table tbody {
  display: flex;
  width: 100%;
}
.EQTensor_container .container-table-tm #EQTmTable table tr {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.EQTensor_container .container-table-tm #EQTmTable table tr:nth-child(even) {
  background: #f2f2f2;
}
.EQTensor_container .container-table-tm #EQTmTable table tr:first-child {
  min-width: 135px;
}
.EQTensor_container .container-table-tm #EQTmTable table tr th {
  background: #005833;
  color: #FFFFFF;
  border: none;
  border-right: 1px solid #FFFFFF;
  display: flex;
  flex-grow: 1;
  padding: 10px;
  justify-content: center;
  align-items: center;
}
.EQTensor_container .container-table-tm #EQTmTable table tr td {
  border-right: 1px solid #005833;
  display: flex;
  padding: 10px;
  height: 80px;
  justify-content: center;
  align-items: center;
}
.EQTensor_container .container-table-tm #EQTmTable table tr:last-child td {
  border-right: none;
}
.EQTensor_container .fila2 .fila2-container {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "tablas bola";
  -ms-grid-columns: 55% 45%;
  grid-template-columns: 55% 45%;
  -ms-grid-rows: 100%;
  grid-template-rows: 100%;
}
.EQTensor_container .fila2 .tablas {
  grid-area: tablas;
  grid-template-areas: "tabla1" "tabla2" "tabla3";
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: 10px 120px 80px;
  grid-template-rows: 10px 120px 80px;
}
.EQTensor_container .fila2 .tablas .tabla1, .EQTensor_container .fila2 .tablas .tabla3 {
  padding: 1rem 0rem;
}
.EQTensor_container .fila2 .tablas .tabla1 thead th:first-child, .EQTensor_container .fila2 .tablas .tabla3 thead th:first-child {
  width: 20%;
}
.EQTensor_container .fila2 .tablas .tabla2 {
  padding: 1rem 0rem;
}
.EQTensor_container .fila2 .tablas .tabla2 tbody th {
  border-right: 2px solid #005833;
  background: #005833;
  color: white;
  width: 20%;
  padding: 8px;
}
.EQTensor_container .fila2 .tablas .tabla2 tbody td {
  padding: 8px;
}
.EQTensor_container .fila2 .tablas .tabla2 tbody tr {
  height: 28px;
}
.EQTensor_container .fila2 .tablas .tabla2 tbody .no-border {
  border-right: none;
}
.EQTensor_container .fila2 .bola {
  grid-area: bola;
  padding: 1rem;
}
.EQTensor_container .fila2 .bola .container-bola {
  height: 100%;
  border: 2px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQTensor_container .fila2 .bola .container-bola img {
  width: 200px;
}
.EQTensor_container table tr:nth-child(even) {
  background: #f2f2f2;
}

@media (max-width: 500px) {
  .container-tmpTensor {
    padding: 25px 0 10px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
@media (max-width: 800px) {
  .EQTensor_container {
    gap: 30px;
  }
  .EQTensor_container .container-table-tm #EQTmTable table tbody {
    display: block;
  }
  .EQTensor_container .container-table-tm #EQTmTable table tbody tr {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 40px;
    width: 100%;
  }
  .EQTensor_container .container-table-tm #EQTmTable table tbody tr td {
    width: 100%;
    height: 100%;
    border: none;
  }
  .EQTensor_container .container-table-tm #EQTmTable table tbody tr tr {
    width: 100%;
    height: 100%;
    border: none;
  }
  .EQTensor_container .container-bola img {
    width: 70% !important;
  }
  .EQTensor_container .fila2 .fila2-container {
    display: -ms-grid;
    display: grid;
    grid-template-areas: "tablas" "bola";
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }
}
/**EQtm.jsx**/
/* EQCdi */
.EQCdi .intensity-info {
  padding: 1rem;
  border: 2px solid #005833;
  display: flex;
  align-items: center;
}

.max-intensity-cdi {
  display: flex;
  align-items: center;
  color: #005833;
}
.max-intensity-cdi .dates-icons {
  margin: 5px 8px;
  width: 25px;
  height: 25px;
}

.reports-cdi {
  display: inline-block;
  margin: 15px 0;
}

/* EQCdiTabs */
.main-content-cdi {
  display: block;
}
.main-content-cdi .intensity-table {
  grid-area: intensity-table;
  display: flex;
  margin-bottom: 1rem;
}
.main-content-cdi .intensity-table .ems {
  width: 50px;
}
.main-content-cdi .intensity-table table th {
  background: #cccccc;
  border: 1px solid #000000;
}
.main-content-cdi .intensity-table table td {
  border: 1px solid #000000;
  padding: 8px 16px;
}
.main-content-cdi .intensity-table .dates-icons {
  width: 35px;
  height: 35px;
  margin: 0 auto;
}
.main-content-cdi .intensity-icon {
  display: flex;
  justify-content: center;
}
.main-content-cdi .intensity-description {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.main-content-cdi .infographic-info {
  padding: 1rem;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
}
.main-content-cdi .map-table-cdi {
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: auto;
  grid-template-areas: "map-cdi intensity-table";
  gap: 16px;
}

.map-cdi {
  margin-bottom: 1rem;
}
.map-cdi .LegendButton {
  background-color: white;
  z-index: 1000;
  margin-top: 60px;
  margin-left: 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  line-height: normal;
  padding: 5px;
}
.map-cdi .epicenter-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.map-cdi .epicenter-icon {
  height: 0.9rem;
  margin-right: 5px;
}

#map-cdi {
  width: 100%;
  height: 700px;
  position: relative;
}

.cdi-tables {
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "intensity-legal";
  grid-gap: 1rem;
}

.intensity-legal {
  padding: 1rem;
  border: 2px solid #005833;
  background: #f1f1f1;
  color: #636363;
}

.ans-vs-time {
  display: block;
}
.ans-vs-time .apexcharts-xaxis-texts-g .apexcharts-xaxis-label:nth-child(n):nth-last-child(2n+2) {
  display: none;
}
.ans-vs-time .ans-graph {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.dist-vs-intensity {
  display: block;
}
.dist-vs-intensity .apexcharts-xaxis-texts-g .apexcharts-xaxis-label:nth-child(3n):nth-last-child(2n-5) {
  display: none;
}
.dist-vs-intensity .dist-graph {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

@media screen and (max-device-width: 375px) {
  .main-content-cdi .map-table-cdi {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "map-cdi" "intensity-table";
    grid-gap: 0.5rem;
  }
  .main-content-cdi .intensity-table {
    overflow-x: scroll;
    margin-bottom: 0.5rem;
  }
  .main-content-cdi .intensity-table .dates-icons {
    width: 25px;
    height: 25px;
  }
  .cdi-tables {
    display: block;
    margin-bottom: 0.5rem;
  }
  .intensity-legal {
    padding: 0.5rem;
  }
  .table-reports-info {
    padding: 0.5rem;
  }
  .ans-vs-time .ans-graph {
    margin-bottom: 0.5rem;
    overflow-x: scroll;
    word-wrap: break-word;
  }
  .dist-vs-intensity .dist-graph {
    margin-bottom: 0.5rem;
    overflow-x: scroll;
  }
}
@media screen and (min-device-width: 376px) and (max-device-width: 767px) {
  .main-content-cdi .map-table-cdi {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "map-cdi" "intensity-table";
    grid-gap: 0.5rem;
  }
  .cdi-tables {
    display: block;
    margin-bottom: 0.5rem;
  }
  .intensity-table {
    overflow-x: scroll;
    margin-bottom: 0.5rem;
  }
  .intensity-table .dates-icons {
    width: 30px;
    height: 30px;
  }
  .ans-vs-time .ans-graph {
    margin-bottom: 0.5rem;
    overflow-x: scroll;
  }
  .dist-vs-intensity .dist-graph {
    margin-bottom: 0.5rem;
    overflow-x: scroll;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .main-content-cdi .map-table-cdi {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "map-cdi" "intensity-table";
    grid-gap: 0.5rem;
  }
  .cdi-tables {
    display: block;
    margin-bottom: 1rem;
  }
  .intensity-table {
    overflow-x: scroll;
    margin-bottom: 1rem;
  }
  .intensity-legal {
    padding: 1rem;
  }
  .ans-vs-time .ans-graph {
    margin-bottom: 1rem;
    overflow-x: scroll;
  }
  .dist-vs-intensity .dist-graph {
    margin-bottom: 1rem;
    overflow-x: scroll;
  }
}
/* EQCdi */
/**EQDinamicMap.jsx**/
.EQDinamicMap {
  margin-bottom: 1rem;
}

#dinamic_map {
  width: 100%;
  height: 840px;
  position: relative;
}

.leaflet-top {
  top: 15rem;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) {
  #dinamic_map {
    width: 100%;
    height: 375px;
    position: relative;
  }
}
@media (max-device-width: 767px) {
  #dinamic_map {
    width: 100%;
    height: 555px;
    position: relative;
  }
}
/**EQsm.jsx**/
.EQsm .EQSmTabImg {
  cursor: pointer;
}

/**EQNearbyseismos.jsx**/
.shLegend .StarEpicenter {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.shLegend .StarEpicenter span {
  font-weight: bold;
  font-size: 80%;
  font-family: Roboto;
}
.shLegend .StarEpicenter img {
  width: 25px;
  height: 25px;
}

.EQsh {
  height: auto;
}
.EQsh .ShMap {
  height: auto;
  padding: 10px 0;
  border: 2px solid #f1f1f1;
}
.EQsh .ShMap .ShMapContent {
  position: relative;
}
.EQsh .ShMap .ShMapContent .SHtextHistory {
  display: block;
  margin: 0 20px;
}
.EQsh .ShMap .ShMapContent .ShDisplayMap {
  height: 650px;
  margin: 10px 20px;
}
.EQsh .ShMap .ShMapContent .ShDisplayMap .epicenter_popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.EQsh .ShMap .ShMapContent .ShDisplayMap .epicenter_popup span {
  margin: 0 10px;
}
.EQsh .ShMap .ShMapContent .ShLegendContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: max-content;
  margin: auto;
}
.EQsh .ShMap .ShMapContent .ShLegendContainer .ShLegend {
  position: absolute;
  bottom: 20px;
  padding: 2.5px 10px;
  cursor: pointer;
  z-index: 400;
  width: max-content;
}
.EQsh .ShMap .EQShTable {
  color: #000000;
  margin: 40px 0;
  padding: 0 20px;
  overflow: hidden;
  -webkit-box-shadow: -7px 10px 14px -7px #ebebeb;
  box-shadow: -7px 10px 14px -7px #ebebeb;
}
.EQsh .ShMap .EQShTable td {
  text-align: left;
  padding: 14px;
}
.EQsh .ShMap .EQShTable .MuiTableCell-head, .EQsh .ShMap .EQShTable .MUIDataTableHeadCell-root-253, .EQsh .ShMap .EQShTable .MUIDataTableHeadCell-fixedHeaderCommon-255, .EQsh .ShMap .EQShTable .MUIDataTableHeadCell-fixedHeaderXAxis-256, .EQsh .ShMap .EQShTable .MUIDataTableHeadCell-fixedHeaderYAxis-257 {
  background: #667f00;
  color: #FFFFFF;
  border-right: 1px solid #FFFFFF;
}
.EQsh .ShMap .EQShTable .MuiPaper-root, .EQsh .ShMap .EQShTable .MuiPaper-elevation4, .EQsh .ShMap .EQShTable .MuiPaper-rounded {
  overflow-x: auto;
}
.EQsh .ShMap .EQShTable .shCell img {
  width: 20px;
}
.EQsh .ShMap .EQShTable svg {
  opacity: 1 !important;
}
.EQsh .ShMap .EQShTable a {
  text-decoration: none;
  background: #667f00;
  color: #FFFFFF;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
}
.EQsh .ShMap .EQShTable a:hover {
  padding: 6px 12px;
}

.ContainerAge {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ContainerAge .Cont_icons {
  width: 20%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ContainerAge .Cont_icons .CicleAge {
  width: 15px;
  height: 15px;
  border: 1px solid #000000;
}
.ContainerAge .Cont_icons span {
  font-family: Roboto;
  font-size: 11.5px;
}

@media (min-width: 650px) {
  .ShMap {
    margin: 22px 40px 22px 40px;
  }
}
/**EQNearbyseismos.jsx**/
/**EQoriginHistory.jsx**/
@media (min-width: 200px) {
  .EQHistoric .MuiTableCell-head, .EQHistoric .MUIDataTableHeadCell-root-253, .EQHistoric .MUIDataTableHeadCell-fixedHeaderCommon-255, .EQHistoric .MUIDataTableHeadCell-fixedHeaderXAxis-256, .EQHistoric .MUIDataTableHeadCell-fixedHeaderYAxis-257 {
    background: #667f00;
    color: #FFFFFF;
    border-right: 1px solid #FFFFFF;
  }
  .EQHistoric .HistoricContent {
    padding: 0 10px;
  }
  .EQHistoric .HistoricTable {
    padding: 0 20px;
    overflow-x: auto;
  }
  .EQHistoric .HistoricTable div {
    overflow-x: auto;
  }
  .EQHistoric .HistoricTable .EQHistoricHr {
    padding: 10px 0;
  }
  .EQHistoric .HistoricTable .HmTableRowd {
    background: #FFFFFF;
  }
  .EQHistoric .HistoricTable .HmTableRowd td {
    text-align: left;
    padding: 14px;
  }
  .EQHistoric .HistoricTable .HmTableRow:nth-child(even) {
    background-color: #f2f2f2;
  }
  .EQHistoric .HistoricTable .HmGds {
    background: #8FBC8F;
  }
  .EQHistoric .HistoricTable .HmGds td {
    padding: 14px;
    text-align: center;
  }
  .EQHistoric .HistoricTable .EQHisFtTable {
    margin: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .EQHistoric .HistoricTable .EQHisFtTable div {
    width: 20px;
    margin-right: 20px;
    height: 20px;
    background: #8FBC8F;
  }
  .EQHistoric .HistoricTable .EQHisFtTable span {
    color: #000000;
  }
}
@media (min-width: 1200px) {
  .EQHistoric .HistoricContent {
    margin: 22px 10px 22px 10px;
    border: 2px solid #f1f1f1;
  }
  .EQHistoric .HistoricContent .HistoricGrid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }
  .EQHistoric .HistoricContent .HistoricGrid > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }
  .EQHistoric .HistoricContent .HistoricGrid > *:nth-child(2) {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
  }
}
/**EQoriginHistory.jsx**/
/**EQDownloads.jsx**/
.EQUploads {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 20px;
}
.EQUploads a:hover {
  text-decoration: none;
}
.EQUploads .EQCard {
  width: 165px;
  margin: 0;
}
.EQUploads .EQCard .EQCardImg {
  min-height: 130px;
}
.EQUploads .EQCard .EQCardImg img {
  width: 87%;
}
.EQUploads .EQCard .EQCardImg:hover {
  opacity: 0.7;
}
.EQUploads .EQCard .EQCardImg img.FullWidth {
  width: 100%;
}

@media (max-width: 800px) {
  .EQUploads .EQCard {
    width: 25vw;
  }
}
@media (max-width: 500px) {
  .EQUploads {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
  }
  .EQUploads .EQCard {
    margin: 0;
    max-width: unset;
    min-width: 100px;
    width: 100%;
  }
  .EQUploads .EQCard .EQCardImg {
    height: 120px;
  }
  .EQUploads .EQCard .EQCardImg img {
    height: 100%;
  }
}
/**EQDownloads.jsx**/
/**EQsmTabs.jsx**/
.apexcharts-toolbar {
  margin: 1.3rem 0rem;
}

#highcharts .highcharts-root {
  font-family: inherit !important;
}
#highcharts .highcharts-root .highcharts-title {
  color: #005833 !important;
  fill: #005833 !important;
}
#highcharts > div {
  margin: 1rem 0rem;
}

.SmContainer {
  border-radius: 8px;
  overflow: hidden;
  margin: 20px 0;
  display: flex;
  border-bottom: 2px solid #005833;
  border-top: 2px solid #005833;
  border-right: 2px solid #005833;
  border-left: 2px solid #005833;
}
.SmContainer .SmColumns {
  display: grid;
  width: 100%;
}
.SmContainer .SmColumns span {
  display: inline-block;
  padding: 5px 10px;
  text-wrap: nowrap;
  border-left: 1px solid #005833;
  border-bottom: 1px solid #005833;
  text-align: center;
  align-content: center;
}
.SmContainer .SmColumns span:last-child {
  border-bottom: none;
}
.SmContainer .SmColumns:nth-child(odd) {
  background-color: #f1f1f1;
}
.SmContainer .SmColumns:first-child {
  background-color: #005833;
  color: white;
}
.SmContainer .SmColumns:first-child span:first-child {
  border-bottom: 1px solid white;
}

@media (max-width: 950px) {
  .SmContainer {
    margin: 20px 0;
    display: grid;
  }
  .SmContainer .SmColumns {
    width: 100%;
    display: flex;
  }
  .SmContainer .SmColumns span {
    width: 100%;
    text-wrap: wrap;
  }
  .SmContainer .SmColumns span:last-child {
    border-bottom: 1px solid #005833;
  }
  .SmContainer .SmColumns span:first-child {
    border-left: none;
  }
  .SmContainer .SmColumns:last-child span {
    border-bottom: none;
  }
  .SmContainer .SmColumns:first-child span:first-child {
    border-bottom: none;
  }
  .SmContainer .SmColumns:first-child span:last-child {
    border-left: 1px solid white;
  }
}
.descripcion-sm ul {
  padding: 0rem 2rem;
  margin-bottom: 1rem;
}
.descripcion-sm ul table {
  margin: 0.5rem;
  border: 2px solid Green;
}
.descripcion-sm ul table td {
  border: 1px solid Green;
}
.descripcion-sm p {
  margin-bottom: 0.5rem;
}

#interactive_map {
  width: 100%;
  height: 840px;
  position: relative;
}

#interactiveMapLegendContainer {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  margin-bottom: 30px;
}

.leaflet-div-icon {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
}

.close-popup-sm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  color: black;
}
.close-popup-sm .icon-close {
  cursor: pointer;
}

.main-interactive-legend {
  width: 350px;
  height: 180px;
  display: grid;
  grid-template-areas: "title-interactive-legend" "colors-interactive-legend";
  grid-template-columns: 100%;
  grid-template-rows: 20% 80%;
  padding: 1rem;
}
.main-interactive-legend .title-interactive-legend {
  grid-area: title-interactive-legend;
  font-size: 15pt;
}
.main-interactive-legend .title-interactive-legend h6 img {
  margin: 0rem 0.5rem;
  width: 15px;
}
.main-interactive-legend .colors-interactive-legend {
  grid-area: colors-interactive-legend;
  font-size: 10pt;
  display: grid;
  grid-template-areas: "colorsOne colorsTwo";
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  margin-top: 1rem;
}
.main-interactive-legend .colors-interactive-legend ul {
  list-style: none;
}
.main-interactive-legend .colors-interactive-legend ul li {
  display: flex;
}
.main-interactive-legend .colors-interactive-legend ul li div {
  width: 15px;
  height: 15px;
  border: 1px solid black;
  margin-right: 0.5rem;
  background: red;
}

/* EQCards  */
.EQCardsContainer {
  padding: 20px 0;
  width: 100%;
  overflow: hidden;
}
.EQCardsContainer .EQCards {
  display: grid;
  margin: auto;
  width: 80%;
  grid-template-columns: 20% 20% 20% 20% 20%;
  gap: 20px;
}

.EQCard {
  display: grid;
  width: 100%;
  min-height: 225px;
  border-radius: 8px;
  overflow: hidden;
  background: #f5f5f5;
  margin: 10px auto;
  cursor: pointer;
  -webkit-box-shadow: -5px 6px 4px 0px rgb(203, 203, 203);
  -moz-box-shadow: -5px 6px 4px 0px rgb(203, 203, 203);
  box-shadow: -5px 6px 4px 0px rgb(203, 203, 203);
}
.EQCard:hover {
  border: 2px solid #cccccc;
}
.EQCard .EQCardImg {
  display: flex;
  justify-content: center;
  padding: 8px;
  align-items: center;
}
.EQCard .EQCardImg img {
  width: 90%;
}
.EQCard .EQCardContent {
  background: #FFFFFF;
  padding: 20px 8px;
  color: #005833;
  text-align: center;
  height: 100%;
}

@media (max-width: 1200px) {
  .EQCardsContainer .EQCards {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    width: 90%;
  }
  .EQCard {
    width: 100%;
    min-height: unset;
    margin: 0 auto;
  }
}
@media (max-width: 500px) {
  .EQCardsContainer .EQCards {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    gap: 18px;
  }
}
/* EQCards  */
/*EQDetail sass to css*/
@media (min-width: 200px) {
  .EQcontainer {
    width: 100%;
    min-height: 100vh;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 70px auto;
    grid-template-areas: "EQheader" "EQmain";
    overflow: hidden;
  }
  .EQcontainer .EQheader {
    grid-template-rows: 70px;
  }
}
.EQcontainer {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 70px auto 30px;
  grid-template-areas: "EQheader" "EQmain" "footer";
  overflow: hidden;
}
.EQcontainer .EQheader {
  grid-area: EQheader;
  width: 100%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  background: #FFFFFF;
  position: fixed;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  grid-template-areas: "EQButtonActions";
}
.EQcontainer .EQmain {
  height: 100%;
  grid-area: EQmain;
  display: grid;
  grid-template-columns: 22% 78%;
  grid-template-rows: auto;
  grid-template-areas: "EQmenu EQproduct";
  transition: all 0.5s;
  z-index: 5;
  overflow-y: auto;
}
.EQcontainer .EQmain #MapServices {
  z-index: 1000;
  position: fixed;
}
.EQcontainer .EQmain #MapServices a {
  display: flex;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
.EQcontainer .EQmain .EQmenu {
  grid-area: EQmenu;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  background: #33362d;
  display: block;
  transition: all 0.3s;
}
.EQcontainer .EQmain .EQproduct {
  grid-area: EQproduct;
  width: 100%;
}
.EQcontainer .active {
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas: "EQproduct";
}
.EQcontainer .active > .EQmenu {
  width: 0;
  height: 0;
  position: relative;
}
.EQcontainer .EQfooter {
  grid-area: EQfooter;
  z-index: 4;
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 1237px) {
  .EQcontainer .EQmain {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "EQmenu";
  }
  .EQcontainer .EQmain .EQmenu {
    grid-area: EQmenu;
    display: block;
    width: 100%;
    position: relative;
  }
  .EQcontainer .EQmain .EQproduct {
    display: none;
  }
  .EQcontainer .active {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-template-areas: "EQproduct";
  }
  .EQcontainer .active > .EQmenu {
    display: none;
    width: 0;
    height: 0;
  }
  .EQcontainer .active > .EQproduct {
    display: block;
  }
}
/* Mixin para dar estilo a los textos dentro del acordeón de las tarjetas de eventos (sismos y volcanes) */
/* Para el Encabezado */
.header-text {
  font-family: Roboto;
  font-size: 10pt;
  font-weight: bold;
  color: "";
  display: flex;
  align-items: center;
  justify-content: auto;
}

/* Para los Indicadores (Magnitude, Depth) */
.indicators-text {
  font-family: Roboto;
  font-size: 12pt;
  font-weight: bold;
  color: "";
  display: flex;
  align-items: center;
  justify-content: auto;
}

/* Para el texto "Superficial" que se muestra en la Profundidad (Depth) */
.depth-superficial {
  font-family: Roboto;
  font-size: 6pt;
  font-weight: bold;
  color: "";
  display: flex;
  align-items: center;
  justify-content: auto;
}

/* Para las medidas de los indicadores (Magnitude (Ml), Depth (Km) */
.measures-text {
  font-family: Roboto;
  font-size: 8pt;
  font-weight: bold;
  color: "";
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Para la fecha */
.date-text {
  font-family: Oxigen;
  font-size: 8pt;
  font-weight: normal;
  color: #000000;
  display: flex;
  align-items: auto;
  justify-content: auto;
}

/* Para la información del evento (Cuando se abre el acordeon) */
.info-text {
  font-family: Oxigen;
  font-size: 8pt;
  font-weight: normal;
  color: #FFFFFF;
  display: "";
  align-items: "";
  justify-content: "";
}

/* Para la información del evento, resaltado con negrita */
.info-text-bold {
  font-family: Roboto;
  font-size: 10pt;
  font-weight: bold;
  color: #FFFFFF;
  display: "";
  align-items: "";
  justify-content: "";
}

/* Para los botones ¿Sintió este sismo? y Mas información */
.buttons-text {
  font-family: Oxigen;
  font-size: 7pt;
  font-weight: bold;
  color: #000000;
  display: "";
  align-items: "";
  justify-content: "";
}

/* Para alinear y justificar al centro un div FLEX*/
.align-justify-center, .accordion .item-volcano-container .icon-volcano, .accordion .item-container .depth .depth-background, .accordion .item-container .depth, .accordion .item-container .magnitude .magnitude-background, .accordion .buttons, .notification-card .notification-item .info-notification .intensity-notification .magnitude-container, .notification-card .notification-item .info-notification .depth-notification .depth-container, .notification-card-volcano .notification-volcano-item .info-notification-volcano .intensity-notification-volcano {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Para alinear y al centro un div FLEX*/
.align-center, .accordion .item-volcano-container .info-volcano .dates-volcano, .accordion .item-volcano-container .info-volcano .place-volcano, .accordion .item-container .main .place, .notification-card-volcano .notification-volcano-item .info-notification-volcano .level-download-volcano {
  display: flex;
  align-items: center;
}

/* Para justificar al centro un div FLEX*/
.justify-center, .accordion .item-container .main .dates .container-icons .dates-tm, .accordion .item-container .main .dates .container-icons .dates-sm, .accordion .item-container .main .dates .container-icons .dates-cdi, .accordion .item-container .main .dates .container-icons .dates-mmi, .accordion .item-container .magnitude, .notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-mmi, .notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-cdi, .notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-sm, .notification-card .notification-item .info-notification .main .status-icons-notification .container-icons .dates-tm {
  display: flex;
  justify-content: center;
}

/* Mixin para los iconos de mmi, cdi, depth (Acordeón) */
/* Para los iconos de mmi, cdi, depth (Acordeón) */
.dates-icons {
  display: flex;
  align-items: center;
  width: 25px;
  margin: 6px auto;
  height: 25px;
  border: none;
  justify-content: center;
}

/* Acordeón Event Cards */
.accordion {
  display: block;
  overflow: hidden;
}
.accordion .buttons {
  justify-content: space-evenly;
  max-width: 500px;
  margin: 0 auto;
}
.accordion .buttons button {
  background: #F6F6F6;
  margin: 0px 5px;
  padding: 8px;
  min-width: 10rem;
  border-radius: 8px;
  min-height: 1.7rem;
  height: max-content;
  border: 1px solid #FFFFFF;
  outline: none;
}
.accordion .buttons button a {
  color: #005833;
  text-decoration: none;
}
.accordion .buttons button:hover {
  border: 1px solid #FFFFFF;
  cursor: pointer;
  background: #FFFFFF;
}
.accordion .item {
  background: #FFFFFF;
  color: #000000;
}
.accordion .item.active {
  background: #005833;
  color: #FFFFFF;
}
.accordion .item.active .date-text {
  color: #FFFFFF;
}
.accordion .item2 {
  background-color: #f1f1f1;
  color: #000000;
}
.accordion .item-container {
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 65px;
  grid-template-areas: "magnitude main depth";
  border-bottom: 1px solid;
  border-color: #FFFFFF;
  align-items: center;
  justify-content: space-between;
}
.accordion .item-container .magnitude {
  grid-area: magnitude;
  border-right: #FFFFFF 1px solid;
}
.accordion .item-container .magnitude .magnitude-background {
  width: 45px;
  height: 45px;
  background-size: cover;
  background-image: url("../assets/icons/EQViewer/Indicators/Magnitude-Background.svg");
}
.accordion .item-container .main {
  grid-area: main;
  padding: 0rem 0.5rem;
}
.accordion .item-container .main .place {
  height: 50%;
}
.accordion .item-container .main .place h2 {
  width: 100%;
}
.accordion .item-container .main .place h2 div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accordion .item-container .main .dates {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  grid-template-areas: "container-date container-icons";
  align-items: center;
}
.accordion .item-container .main .dates .container-date {
  display: flex;
}
.accordion .item-container .main .dates .container-icons {
  display: flex;
  align-items: center;
}
.accordion .item-container .main .dates .container-icons .dates-mmi {
  grid-area: dates-mmi;
}
.accordion .item-container .main .dates .container-icons .dates-cdi {
  grid-area: dates-cdi;
}
.accordion .item-container .main .dates .container-icons .dates-sm {
  grid-area: dates-sm;
}
.accordion .item-container .main .dates .container-icons .dates-tm {
  grid-area: dates-tm;
}
.accordion .item-container .main .dates .container-icons .dates-tm img {
  max-height: 20px;
  max-width: 20px;
  width: 100%;
}
.accordion .item-container .depth {
  grid-area: depth;
  border-left: #FFFFFF 1px solid;
}
.accordion .item-container .depth .depth-background {
  width: 45px;
  height: 45px;
  background-size: cover;
  background-image: url("../assets/icons/EQViewer/Indicators/Depth-Background.svg");
}
.accordion .item-content {
  background: #005833;
  overflow: hidden;
  max-height: 0px;
  opacity: 0;
  transition: 0.3s ease;
  display: block;
}
.accordion .active + .item-content {
  max-height: 1000px;
  padding-top: 10px;
  padding-bottom: 14px;
  opacity: 1;
  border-bottom: 2px solid #005833;
}
.accordion .active + .item-content .info-content {
  margin: 0px 0px 10px 0px;
  padding: 0px 15px;
}
.accordion .item-volcano-container {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 65px;
  grid-template-areas: "icon-volcano dates-volcano";
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #FFFFFF;
}
.accordion .item-volcano-container .icon-volcano {
  grid-area: icon-volcano;
  margin-top: 10px;
  height: 100%;
}
.accordion .item-volcano-container .info-volcano {
  padding: 0rem 0.5rem;
}
.accordion .item-volcano-container .info-volcano .dates-volcano {
  grid-area: dates-volcano;
  margin-top: 10px;
}

@media (max-device-width: 360px) {
  .dates-icons {
    display: flex;
    align-items: center;
    width: 20px;
    margin: 6px auto;
    height: 20px;
    border: none;
    justify-content: center;
  }
  .main .place h2 {
    width: 100%;
  }
  .main .place h2 div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media (max-device-width: 375px) {
  .accordion .item .item-container {
    grid-template-columns: 20% 60% 20%;
  }
  .accordion .item-volcano-container {
    grid-template-columns: 20% 80%;
  }
}
@media (max-device-width: 770px) and (min-device-width: 720px) {
  .accordion .item .item-container {
    grid-template-columns: 20% 60% 20%;
  }
  .accordion .item-volcano-container {
    grid-template-columns: 20% 80%;
  }
  .accordion .dates-icons {
    display: flex;
    align-items: center;
    width: 15px;
    margin: 6px auto;
    height: 15px;
    border: none;
    justify-content: center;
  }
  .accordion .main .dates {
    grid-template-columns: 62% 38%;
  }
}
@media (max-device-width: 1024px) and (min-device-width: 770px) {
  .accordion .item .item-container {
    grid-template-columns: 20% 60% 20%;
  }
  .accordion .item-volcano-container {
    grid-template-columns: 20% 80%;
  }
  .accordion .dates-icons {
    display: flex;
    align-items: center;
    width: 15px;
    margin: 6px auto;
    height: 15px;
    border: none;
    justify-content: center;
  }
  .accordion .main .dates {
    grid-template-columns: 60% 40%;
  }
  .accordion .header-text {
    font-size: 9pt;
  }
  .accordion .date-text {
    font-size: 7pt;
  }
}
@media (max-device-width: 1400px) and (min-device-width: 1024px) {
  .accordion .item .item-container {
    grid-template-columns: 20% 60% 20%;
  }
  .accordion .item-volcano-container {
    grid-template-columns: 20% 80%;
  }
  .accordion .dates-icons {
    display: flex;
    align-items: center;
    width: 20px;
    margin: 6px auto;
    height: 20px;
    border: none;
    justify-content: center;
  }
  .accordion .main .dates {
    grid-template-columns: 60% 40%;
  }
}
@media (max-device-width: 1600px) and (min-device-width: 1400px) {
  .dates-icons {
    display: flex;
    align-items: center;
    width: 20px;
    margin: 6px auto;
    height: 20px;
    border: none;
    justify-content: center;
  }
  .main .dates {
    grid-template-columns: 60% 40%;
  }
}
.Vsection_slider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0 15px 0 20px;
  border-bottom: 1px solid #FFFFFF;
}

/**_index.sass* */
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Nunito Sans", sans-serif;
}

.ui-viewer-container {
  height: 100vh;
  margin: 0 0;
  display: -ms-grid;
  display: grid;
  overflow-y: hidden;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: 70px auto 30px;
  grid-template-rows: 70px auto 30px;
  grid-template-areas: "container-header" "content" "footer";
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*------------------- HEADER --------------- */
.container-header {
  grid-area: container-header;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  display: -ms-grid;
  display: grid;
  grid-template-areas: "viewer-header";
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 3;
}

.map_icon_access {
  height: max-content !important;
}

.legal-header {
  grid-area: legal-header;
}

.viewer-header {
  grid-area: viewer-header;
  background-color: #005833;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-columns: 1fr 20px 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-gap: 20px;
  grid-template-areas: "sgc-log earthquakes-modes-view";
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 3;
  height: 70px;
}

.sgc-logo {
  grid-area: sgc-log;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.sgc-logo .logo img {
  border: none;
  height: 60px;
  width: 120px;
  margin-left: 10px;
}

.earthquakes-modes-view {
  grid-area: earthquakes-modes-view;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 10px;
  height: 70px;
}
.earthquakes-modes-view .modes-view-item .icon_display {
  width: 30px;
  display: flex;
  align-items: center;
}
.earthquakes-modes-view .modes-view-item .icon_display img {
  width: 30px;
}

.container-header .earthquakes-modes-view ul {
  height: 70px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.container-header .earthquakes-modes-view ul #ViInfo {
  visibility: hidden;
}
.container-header .earthquakes-modes-view ul li {
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  height: 70px;
}
.container-header .earthquakes-modes-view ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}
.container-header .earthquakes-modes-view ul li .material-icons {
  color: #FFFFFF;
  font-size: 28px;
  height: 28px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 28px;
}
.container-header .earthquakes-modes-view ul li + li {
  margin-left: 0.5em;
  height: 30px;
}
.container-header .earthquakes-modes-view ul li .button-header {
  -webkit-filter: none;
  filter: none;
  background: none;
}
.container-header .earthquakes-modes-view ul li.selected .button-header {
  -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
  filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%);
}

/*------------------- HEADER --------------- */
.contentv {
  grid-area: content;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 450px auto;
  grid-template-columns: 450px auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-areas: "events-list map";
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 14px 10px -6px #000000;
  box-shadow: 0px 14px 10px -6px #000000;
  position: relative;
  z-index: 2;
}

.content {
  grid-area: content;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 450px auto;
  grid-template-columns: 450px auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  grid-template-areas: "events-list map";
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0px 14px 10px -6px #000000;
  box-shadow: 0px 14px 10px -6px #000000;
  position: relative;
  z-index: 2;
}
.content .events-list {
  grid-area: events-list;
  overflow-x: hidden;
  background: #FFFFFF;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 2;
  height: 100%;
}
.content .events-list .container-events-list {
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid-template-areas: "summary-container" "events-container";
  -ms-grid-columns: auto;
  grid-template-columns: 100%;
  -ms-grid-rows: 150px auto;
  grid-template-rows: 150px auto;
}
.content .events-list .container-events-list .summary-container {
  grid-area: summary-container;
  height: 100%;
  background: #ececec;
  -webkit-box-shadow: 0px 0px 10px #000000;
  box-shadow: 3px 0px 10px #000000;
  z-index: 1;
}
.content .events-list .container-events-list .summary-container .filter-container {
  padding: 0rem 0.5rem;
}
.content .events-list .container-events-list .summary-container .filter-container .MuiInput-underline::after {
  border-bottom: #667f00;
}
.content .events-list .container-events-list .summary-container .filter-container .MuiInput-underline:after {
  border-bottom: 2px solid #667f00;
}
.content .events-list .container-events-list .summary-container .filter-container .MuiFormLabel-root.Mui-focused {
  color: #667f00;
}
.content .events-list .container-events-list .summary-container .summary_content {
  width: 100%;
  height: 50px;
  padding-top: 20px;
  background: #ececec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 0;
}
.content .events-list .container-events-list .summary-container .summary_content .summary_h6 {
  font-weight: bold;
}
.content .events-list .container-events-list .summary-container .summary_content .summary_p {
  font-size: 70%;
}
.content .events-list .container-events-list .events-container {
  grid-area: events-container;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.content .events-list .container-events-list .events-container::-webkit-scrollbar {
  width: 10px;
}
.content .events-list .container-events-list .events-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.content .events-list .container-events-list .events-container::-webkit-scrollbar-thumb {
  background: #667f00;
}

.button-header {
  width: 30px;
}

.map {
  grid-area: map;
  height: 100%;
  width: 100%;
  background: #813e03;
  z-index: 2;
}

.leafletMap {
  position: absolute;
  bottom: 10px;
  left: 50%;
}

#leafletMapId {
  width: 100%;
  height: 100%;
  position: relative;
}
#leafletMapId .leaflet-popup-content {
  min-width: 300px;
}
#leafletMapId .leafletDivIcon {
  margin-top: -0.7rem;
}

#leafletMapIdVolcano {
  width: 100%;
  height: 100%;
  position: relative;
}
#leafletMapIdVolcano .leafletDivIcon {
  margin-top: -0.7rem;
}
#leafletMapIdVolcano .leaflet-map-pane .leaflet-popup-pane .leaflet-popup .leaflet-popup-content-wrapper {
  width: 500px;
}
#leafletMapIdVolcano .leaflet-map-pane .leaflet-popup-pane .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 500px;
}

#leafletMiniMapId {
  z-index: 1000;
  width: 100%;
  height: 135px;
  position: relative;
}

.leafletDivIcon {
  border: none;
  height: 25px;
  width: 25px;
  background-color: transparent;
  margin-left: -13px;
}

#mapLegendContainer {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
}

#selectedMarker {
  z-index: 2000;
}

.earthquakePoint {
  border-radius: 50%;
  border: 2px solid #213551;
  background-color: #FFFFFF;
}

.legendVolcanoIcon {
  border: none;
  height: 35px;
  width: 35px;
  background-color: transparent;
}

.leaflet-popup-content-wrapper {
  padding: 0;
}

.leaflet-popup-content {
  margin: 0px 0px 0px 0px;
  color: inherit;
}
.leaflet-popup-content p {
  margin: 0px;
}

#startDate, #endDate {
  font-size: 10px;
}

.MuiFormLabel-root {
  font-size: 12px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.MuiPopover-root .MuiPopover-paper {
  min-width: 200px !important;
  max-width: 90% !important;
}

.MuiButton-textPrimary {
  color: #005833 !important;
}

.MuiButton-textSecondary {
  color: #FFFFFF !important;
}

.leaflet-popup-tip {
  background-color: #F6F6F6;
}

/* * Map section */
/* * Map Settings Section */
.mapSettingsScroll {
  overflow-y: scroll;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  z-index: 2;
  padding: 0;
}

/* * Map Settings Section */
.settings {
  grid-area: settings;
  background: #f1f1f1;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 2;
  height: 100%;
}
.settings .settings_content {
  height: calc(100vh - 110px);
  overflow-y: scroll;
}
.settings .settings_content::-webkit-scrollbar {
  width: 10px;
}
.settings .settings_content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.settings .settings_content::-webkit-scrollbar-thumb {
  background: #005833;
}
.settings .settings_content::-webkit-scrollbar-thumb:hover {
  background: #636363;
}

/*--------------- Footer --------------- */
.footer {
  grid-area: footer;
  background: #636363;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 3;
}
.footer p {
  color: #FFFFFF;
  font-size: 12px;
}

.MuiSnackbar-anchorOriginBottomCenter {
  opacity: 0.8;
  bottom: 35px;
}
.MuiSnackbar-anchorOriginBottomCenter .MuiAlert-filledSuccess {
  opacity: 0.5;
  transform: none;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #000000;
}

/* -----Display configuration settings */
@media (max-width: 2900px) {
  .contentv > .map, .contentv > .events-list, .contentv > .settings {
    display: none;
  }
  .contentv.mode-list {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "events-list";
    overflow-y: hidden;
  }
  .contentv.mode-list .events-list {
    display: block;
  }
  .content > .map, .content > .events-list, .content > .settings {
    display: none;
  }
  .content.mode-list {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "events-list";
    overflow-y: hidden;
  }
  .content.mode-list .events-list {
    display: block;
  }
  .mode-list > .map, .mode-list > .settings {
    display: none;
  }
  .contentv.mode-map {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "map";
  }
  .contentv.mode-map .map {
    display: block;
  }
  .content.mode-map {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "map";
  }
  .content.mode-map .map {
    display: block;
  }
  .mode-map > .events-list, .mode-map > .settings {
    display: none;
  }
  .contentv.mode-settings {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "settings";
  }
  .contentv.mode-settings .settings {
    display: block;
  }
  .content.mode-settings {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    grid-template-areas: "settings";
  }
  .content.mode-settings .settings {
    display: block;
  }
  .mode-settings > .events-list, .mode-settings > .map {
    display: none;
  }
  .contentv.mode-map.mode-list {
    -ms-grid-columns: 18% auto;
    grid-template-columns: 18% auto;
    grid-template-areas: "events-list map";
  }
  .contentv.mode-map.mode-list > .events-list, .contentv.mode-map.mode-list > .map {
    display: block;
  }
  .contentv.mode-settings.mode-list {
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "events-list settings";
  }
  .contentv.mode-settings.mode-list > .events-list, .contentv.mode-settings.mode-list > .settings {
    display: block;
  }
  .contentv.mode-settings.mode-map {
    -ms-grid-columns: auto 25%;
    grid-template-columns: auto 25%;
    grid-template-areas: "map settings";
  }
  .contentv.mode-settings.mode-map .events-list {
    display: none;
  }
  .contentv.mode-settings.mode-map > .map, .contentv.mode-settings.mode-map > .settings {
    display: block;
  }
  .contentv.mode-settings.mode-list.mode-map {
    -ms-grid-columns: 18% auto 25%;
    grid-template-columns: 18% auto 25%;
    grid-template-areas: "events-list map settings";
  }
  .contentv.mode-settings.mode-list.mode-map > .events-list, .contentv.mode-settings.mode-list.mode-map > .map, .contentv.mode-settings.mode-list.mode-map > .settings {
    display: block;
  }
  .content.mode-map.mode-list {
    -ms-grid-columns: 26% auto;
    grid-template-columns: 26% auto;
    grid-template-areas: "events-list map";
  }
  .content.mode-map.mode-list > .events-list, .content.mode-map.mode-list > .map {
    display: block;
  }
  .content.mode-settings.mode-list {
    -ms-grid-columns: 50% 50%;
    grid-template-columns: 50% 50%;
    grid-template-areas: "events-list settings";
  }
  .content.mode-settings.mode-list > .events-list, .content.mode-settings.mode-list > .settings {
    display: block;
  }
  .content.mode-settings.mode-map {
    -ms-grid-columns: auto 25%;
    grid-template-columns: auto 25%;
    grid-template-areas: "map settings";
  }
  .content.mode-settings.mode-map .events-list {
    display: none;
  }
  .content.mode-settings.mode-map > .map, .content.mode-settings.mode-map > .settings {
    display: block;
  }
  .content.mode-settings.mode-list.mode-map {
    -ms-grid-columns: 25% auto 25%;
    grid-template-columns: 25% auto 25%;
    grid-template-areas: "events-list map settings";
  }
  .content.mode-settings.mode-list.mode-map > .events-list, .content.mode-settings.mode-list.mode-map > .map, .content.mode-settings.mode-list.mode-map > .settings {
    display: block;
  }
}
@media (max-width: 1500px) {
  .content.mode-map.mode-list {
    -ms-grid-columns: 30% auto;
    grid-template-columns: 30% auto;
  }
}
@media (max-width: 1250px) {
  .content.mode-map.mode-list {
    -ms-grid-columns: 33% auto;
    grid-template-columns: 33% auto;
  }
}
@media (max-width: 1000px) {
  .content.mode-map.mode-list {
    -ms-grid-columns: 35% auto;
    grid-template-columns: 35% auto;
    grid-template-areas: "events-list map";
  }
}
/* -----Display configuration settings */
@media (max-width: 720px) {
  .content > .map, .content > .settings, .content > .events-list {
    display: none;
  }
  .content.mode-list > .events-list {
    display: block;
  }
  .settings, .map {
    display: none;
  }
  .content.mode-settings {
    grid-template-areas: "settings";
  }
  .content.mode-settings .settings {
    display: block;
  }
  .content.mode-settings > .map, .content.mode-settings > .events-list {
    display: none;
  }
  .content.mode-map {
    grid-template-areas: "map";
  }
  .content.mode-map .map {
    display: block;
  }
  .content.mode-map > .settings, .content.mode-map > .events-list {
    display: none;
  }
  .footer {
    grid-area: footer;
    background: #636363;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .footer p {
    color: #FFFFFF;
    font-size: 8px;
  }
  #leafletMapIdVolcano .leaflet-map-pane .leaflet-popup-pane .leaflet-popup .leaflet-popup-content-wrapper {
    width: auto;
  }
  #leafletMapIdVolcano .leaflet-map-pane .leaflet-popup-pane .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
    width: auto;
  }
}
@media (max-width: 500px) {
  .MuiMenuItem-root {
    white-space: normal !important;
  }
}
@media (max-width: 403px) {
  .button-header {
    width: 32.5px;
  }
}
@media (max-width: 375px) {
  .container-header .earthquakes-modes-view ul li .material-icons {
    font-size: 25px;
    height: 25px;
    width: 25px;
  }
  .sgc-logo .logo img {
    border: none;
    height: 60px;
    width: 120px;
    margin-left: 10px;
  }
}
@media (max-width: 360px) {
  .container-header .earthquakes-modes-view ul li .material-icons {
    font-size: 25px;
    height: 25px;
    width: 25px;
  }
  .button-header {
    width: 26px;
  }
}
@media (max-width: 320px) {
  .container-header .earthquakes-modes-view ul li .material-icons {
    font-size: 20px;
    height: 20px;
    width: 20px;
  }
  .sgc-logo .logo img {
    border: none;
    height: 50px;
    width: 100px;
    margin-left: 10px;
  }
}
/**_index.sass* */
.EQPopup {
  margin: 0px;
  padding: 0px;
  border-radius: 10px;
  overflow: hidden;
  min-width: 180px;
  color: #3E3E33;
  background-color: #F6F6F6;
  font-size: 11px;
}
.EQPopup .EQPopupHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #005833;
  color: #FFFFFF;
  padding: 10px 15px;
}
.EQPopup .EQPopupHeader .EQTitle {
  font-size: 16px;
  font-weight: bold;
}
.EQPopup .EQPopupHeader .EQTimeContainer {
  display: flex;
  flex-direction: row;
}
.EQPopup .EQPopupHeader .EQTimeContainer p {
  text-wrap: nowrap;
}
.EQPopup .EQPopupHeader .EQTimeContainer .EQTimeText {
  margin-left: 5px;
}
.EQPopup .EQPopupContent {
  padding: 10px 15px;
  line-height: normal;
}
.EQPopup .EQPopupContent .EQInfoTable {
  display: table;
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #FFFFFF;
}
.EQPopup .EQPopupContent .EQInfoCell {
  display: table-cell;
  border: 1px solid #FFFFFF;
}
.EQPopup .EQPopupContent .EQInfoCell .CellTitleContainer {
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 2px solid;
  border-color: #FFFFFF;
}
.EQPopup .EQPopupContent .EQInfoCell .CellTitleContainer p.CellTitle {
  color: #005833;
  text-align: center;
  font-size: 10px;
  line-height: 9px;
  font-weight: bold;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer {
  padding: 7px 0;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle {
  border: 1.5px solid #686868;
  padding: 2px;
  border-radius: 50%;
  height: 57px;
  width: 57px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle .DataValue {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle .DataValue.Small {
  font-size: 11px;
  padding-top: 2px;
  line-height: 11px;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle .DataValue.DepthText {
  font-size: 9.5px;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle .DataMeasure {
  font-size: 9px;
  text-align: center;
}
.EQPopup .EQPopupContent .EQInfoCell .CellDataContainer .CellDataCircle .DataMeasure.Small {
  font-size: 8px;
  line-height: 8px;
}
.EQPopup .EQPopupContent .EQLocationData {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.EQPopup .EQPopupContent .EQLocationData .EQLocationText {
  padding-top: 7px;
}
.EQPopup .EQButtons {
  display: flex;
  justify-content: center;
  padding: 5px 5px 15px;
}
.EQPopup .EQButtons .InfoButton {
  color: #FFFFFF !important;
  line-height: 1.5;
  background-color: #005833;
  border: 1px solid #005833;
  border-radius: 8px;
  width: 130px;
  height: 100%;
  font-weight: bold;
  font-size: 10px;
  padding: 7px 2px;
  margin: 4px 3px 0;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}
.EQPopup .EQButtons .InfoButton:hover {
  background-color: #FFFFFF;
  color: #005833 !important;
}

.MapPopup {
  font-size: 13px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 300;
  min-width: 250px;
}
.MapPopup .PopupHeader {
  font-size: 14px;
  background-color: #005833;
  color: #FFFFFF;
  padding: 15px 18px;
}
.MapPopup .PopupHeader .PopupTitleContainer {
  margin-bottom: 5px;
}
.MapPopup .PopupHeader .PopupTitle {
  font-size: 16px;
  line-height: 1.2;
}
.MapPopup .PopupInfoGrid > div {
  display: grid;
  grid-template-columns: 80px auto;
  border-bottom: 1.5px solid white;
  padding: 7px 0;
}
.MapPopup .PopupInfoGrid > div:last-child {
  border-bottom: none;
}
.MapPopup .PopupInfoGrid > div span {
  align-content: center;
}
.MapPopup .EQButtons .InfoButton {
  font-size: 12px;
  width: 50%;
}

.basic-info-volcanos .table-info div:nth-child(odd) span:nth-child(odd) {
  background: #f1f1f1;
}

.level_volcano {
  display: flex;
  justify-content: center;
}

/**.High_contrast */
.EQContentDetail #show_location {
  position: absolute;
  bottom: 10px;
  left: 40%;
  z-index: 1000;
  background: #fafafa;
  opacity: 0.6;
  padding: 0 10px;
}
.EQContentDetail #show_location:hover {
  opacity: 1;
}
.EQContentDetail #show_location span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.00938em;
}
.EQContentDetail .VContentManager {
  margin: 20px 30px;
  padding: 16px;
}
@media (max-width: 800px) {
  .EQContentDetail .VContentManager {
    margin: 4vw;
  }
}
.EQContentDetail .VContentManager .VTopSlider img {
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
}
.EQContentDetail .VContentManager .carousel-slider {
  height: 400px;
}
.EQContentDetail .VContentManager .carousel-slider .slide .carousel-news {
  width: 100%;
  height: 450px;
}
.EQContentDetail .VContentManager .carousel-slider .slide .carousel-news img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.EQContentDetail .EQSummary {
  margin-bottom: 0 !important;
}
.EQContentDetail h6 {
  font-size: 1.2rem !important;
  color: #005833;
  font-size: 13pt;
  font-weight: 600;
  margin: 0.5rem 0rem;
}
.EQContentDetail .slider-images-volcanos {
  background: #FFFFFF;
  padding: 1rem;
  text-align: center;
}
.EQContentDetail .basic-info-volcanos {
  padding: 15px 0;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info {
  display: grid;
  background-color: white;
  border: 1px solid white;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info {
  width: 100%;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-bottom: 1px;
  min-height: 50px;
  height: max-content;
  overflow-y: hidden;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div span {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div:nth-child(odd) {
  background: #f1f1f1;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div.level_volcano {
  min-height: 0;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  padding: 0 7px;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .item-table-basic-info {
  background: #005833;
  color: #FFFFFF;
  font-weight: 600;
}
.EQContentDetail .basic-info-volcanos .tables-basic-info .item-table-grey {
  background: #f1f1f1;
  color: #000000;
}
.EQContentDetail .localization-volcanos {
  margin: 20px 0;
}
.EQContentDetail .localization-volcanos p {
  text-align: justify;
}
.EQContentDetail .generalities-volcanos {
  margin: 20px 0;
}
.EQContentDetail .generalities-volcanos p {
  text-align: justify;
}
.EQContentDetail .see-more-volcan {
  display: flex;
  justify-self: center;
  justify-content: center;
  height: max-content;
  min-height: 50px;
  margin: 2rem 0 1rem;
}
.EQContentDetail .see-more-volcan button {
  border-radius: 8px;
  text-decoration: none;
  height: 100%;
  background: #005833;
  color: #FFFFFF;
  border: 1px solid #005833;
  font-weight: bold;
  padding: 0 15px;
}
.EQContentDetail .see-more-volcan button:hover {
  cursor: pointer;
  background: #FFFFFF;
  color: #005833;
  border: 1px solid #005833;
}
.EQContentDetail .news-volcanos {
  color: #000000;
}
.EQContentDetail .news-volcanos .carousel .slide {
  background: #FFFFFF;
}
.EQContentDetail .newsletters-volcanos {
  position: relative;
}
@media (max-width: 800px) {
  .EQContentDetail .newsletters-volcanos tbody td:nth-child(1) {
    min-width: 60vw;
  }
}
.EQContentDetail .newsletters-volcanos tbody td:nth-child(1) div {
  text-align: left;
}
.EQContentDetail .carousel-cards-news {
  padding: 10px 0;
  display: grid;
  grid-template-areas: "carousel-news-container cards-news-container";
  grid-template-rows: max-content;
  grid-template-columns: 65% 35%;
}
.EQContentDetail .carousel-cards-news .carousel-news-container {
  position: relative;
}
.EQContentDetail .carousel-cards-news .carousel-news-container .carousel .carousel-status {
  display: none;
}
.EQContentDetail .carousel-cards-news .carousel-news-container .carousel .slide {
  text-align: left;
}
.EQContentDetail .carousel-cards-news .carousel-news-container .carousel .control-dots {
  bottom: 10px;
}

@media screen and (max-width: 800px) {
  .EQContentDetail .carousel-cards-news {
    display: block !important;
  }
  .EQContentDetail .cards-news {
    padding: 0.5rem 0;
  }
  .EQContentDetail .control-dots {
    bottom: 30px;
  }
}
@media (max-width: 630px) {
  .tables-basic-info {
    grid-template-areas: none !important;
    grid-template-columns: 100% !important;
    grid-auto-rows: auto;
  }
  .hidden_legend_volcano {
    height: 220px !important;
  }
  .VTopSlider .carousel-slider {
    height: 100% !important;
  }
}
@media screen and (max-width: 411px) {
  .EQContentDetail .slider-images-volcanos {
    background: #FFFFFF;
    padding: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
  }
  .EQContentDetail .slider-images-volcanos img {
    width: 100%;
  }
}
.High_contrast .level_volcano {
  background: #FFFFFF;
}
.High_contrast .volcano_latitude {
  background: #FFFFFF;
  height: max-content;
}

@media (max-width: 600px) {
  .EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div span {
    padding: 20px 10px;
  }
}
.volcano_map {
  margin-top: 20px;
}
.volcano_map h6 {
  color: #005833;
}
.volcano_map #VolcanoMap {
  height: 400px;
}

.volcan3D_model {
  padding: 20px 0 15px;
}
.volcan3D_model h6 {
  color: #005833;
}
.volcan3D_model #volcan3D_img {
  height: 400px;
  width: 100%;
  overflow-y: hidden;
}

.cards-news {
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
}
.cards-news .Vcard-selected {
  position: relative;
  transition-duration: 0.4s;
  background: #646464 !important;
}
.cards-news .card-new {
  display: grid;
  grid-template-areas: "img-new-card content-new-card";
  grid-template-columns: 20% 80%;
  grid-template-rows: 80px;
  background: #D9D9D9;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.cards-news .card-new .img-new-card {
  background-size: cover;
  background-position: center center;
}
.cards-news .card-new p, .cards-news .card-new h6 {
  color: #FFFFFF;
  margin: 0px;
}
.cards-news .card-new .content-new-card {
  display: grid;
  grid-template-areas: "content-new circles";
  grid-template-columns: 60% 40%;
  grid-template-rows: 100%;
  padding: 0.5rem;
}
.cards-news .card-new .content-new-card .content-new .content-trunk {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cards-news .card-new .circles {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-news .card-new .circles img {
  width: 30px;
}
.cards-news .card-new .circles .circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #646464;
  margin: 0rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards-news .card-new .circles .circle img {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: 1000px) {
  .cards-news .card-new .content-new-card {
    display: grid;
    grid-template-areas: "content-new circles";
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    padding: 0.5rem;
  }
}
.VDivSection {
  position: absolute;
  bottom: 50px;
  height: max-content !important;
  width: 100%;
}
.VDivSection .VNewsPfd {
  padding: 10px 0;
  overflow: initial !important;
}
.VDivSection .VNewsPfd img {
  width: 30px;
}
.VDivSection .hidden_legend_volcano {
  height: 170px;
}
.VDivSection .show_legend_volcano {
  height: 400px !important;
}
.VDivSection #div-content-new {
  background: #000000;
  opacity: 0.7;
  position: relative;
  transition-duration: 0.4s;
}
.VDivSection #div-content-new .VNewsContainer {
  height: 100% !important;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.VDivSection #div-content-new .VNewsContainer .VLogoNews {
  background: url(../assets/icons/Reusable/logoWhite.svg);
  height: 100% !important;
  width: 50px;
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 200%;
  margin-right: 20px;
  position: relative;
}
.VDivSection #div-content-new .VNewsContainer .VLogoNews::before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  border: 1px solid #FFFFFF;
}
.VDivSection #div-content-new .VNewsContainer .VLogoNews::after {
  content: "";
  position: absolute;
  height: 100%;
  right: 0;
  border: 1px solid #FFFFFF;
}
.VDivSection #div-content-new .VNewsContainer div {
  height: 100%;
  overflow-y: auto;
}
.VDivSection #div-content-new .VNewsContainer .VNewsContent .VNewsDescription {
  display: grid;
  height: max-content;
}
.VDivSection #div-content-new .VNewsContainer .VNewsContent .VNewsDescription .VNewsDate {
  width: max-content;
  font-size: 14px;
  position: relative;
}
.VDivSection #div-content-new .VNewsContainer .VNewsContent .VNewsDescription .VNewsDate::before {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  border: 1px solid #FFFFFF;
}
.VDivSection #div-content-new .VNewsContainer .VNewsContent span, .VDivSection #div-content-new .VNewsContainer .VNewsContent h2 {
  padding: 5px 0;
  color: #FFFFFF;
}
.VDivSection #div-content-new .VArrow {
  position: absolute;
  top: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 30px;
}
.VDivSection #div-content-new .VArrow div {
  width: 20px;
  height: 100%;
  cursor: pointer;
}
.VDivSection #div-content-new .VArrow div img {
  width: 20px;
  height: 100%;
}

/**_NotFound**/
.page-404 {
  display: grid;
  grid-template-areas: "menu-404 not-404";
  grid-template-columns: 20% 80%;
  grid-template-rows: 100%;
}
.page-404 .menu-404-tablet {
  display: none;
}
.page-404 .menu-404 {
  height: 100vh;
  display: grid;
  grid-template-areas: "title-menu-404" "content-menu-404";
  grid-template-columns: 100%;
  grid-template-rows: 15% auto;
}
.page-404 .menu-404 .title-menu-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15pt;
  font-weight: bold;
  color: #FFFFFF;
  background: #667f00;
}
.page-404 .menu-404 .content-menu-404 {
  display: grid;
  grid-template-areas: "lines-404" "question-404" "options-menu-404";
  grid-template-columns: 100%;
  grid-template-rows: 7% 7% auto;
  color: #FFFFFF;
  background: #33362D;
}
.page-404 .menu-404 .content-menu-404 .lines-404 hr {
  margin: 0.3rem 0rem;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: 2px solid #FFFFFF;
}
.page-404 .menu-404 .content-menu-404 .question-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #667f00;
}
.page-404 .menu-404 .content-menu-404 .question-404 p {
  margin: 0px;
}
.page-404 .menu-404 .content-menu-404 .options-menu-404 div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem;
  border-bottom: 1px solid #667f00;
}
.page-404 .menu-404 .content-menu-404 .options-menu-404 div a {
  text-align: center;
  text-decoration: none;
  color: #FFFFFF;
}
.page-404 .menu-404 .content-menu-404 .options-menu-404 div a p {
  margin: 0.3rem 0rem;
}
.page-404 .menu-404 .content-menu-404 .options-menu-404 div img {
  width: 70px;
}
.page-404 .not-404 {
  display: grid;
  grid-template-areas: "bg-404" "info-404";
  grid-template-columns: auto;
  grid-template-rows: 90% 10%;
}
.page-404 .not-404 .bg-404 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-404 .not-404 .bg-404 img {
  width: 60%;
}
.page-404 .not-404 .info-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20pt;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .page-404 {
    height: 100vh;
    display: grid;
    grid-template-areas: "not-404" "menu-404-tablet";
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
  .page-404 .menu-404-tablet {
    display: grid;
    grid-template-areas: "question-404-tablet" "options-menu-404";
    grid-template-columns: 100%;
    grid-template-rows: 20% auto;
    background: black;
    color: #667f00;
  }
  .page-404 .menu-404-tablet .question-404-tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25pt;
    font-weight: 600;
  }
  .page-404 .menu-404-tablet .options-menu-404 {
    display: grid;
    grid-template-areas: "home-option-404-tablet sismos-option-404-tablet volcanos-option-404-tablet";
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: 100%;
  }
  .page-404 .menu-404-tablet .options-menu-404 div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem 0rem;
    border-right: 1px solid #667f00;
    border-left: 1px solid #667f00;
  }
  .page-404 .menu-404-tablet .options-menu-404 div a {
    text-align: center;
  }
  .page-404 .menu-404-tablet .options-menu-404 div a p {
    margin: 0.3rem 0rem;
    font-size: 25pt;
    font-weight: 600;
  }
  .page-404 .menu-404-tablet .options-menu-404 div img {
    width: 150px;
  }
  .page-404 .menu-404 {
    display: none;
  }
  .page-404 .not-404 {
    display: grid;
    grid-template-areas: "bg-404" "info-404";
    grid-template-columns: 100%;
    grid-template-rows: 90% 10%;
  }
  .page-404 .not-404 .bg-404 img {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .page-404 {
    height: max-content;
    display: grid;
    grid-template-areas: "not-404" "menu-404-tablet";
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
  .page-404 .menu-404-tablet {
    display: grid;
    grid-template-areas: "question-404-tablet" "options-menu-404";
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
  .page-404 .menu-404-tablet .question-404-tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15pt;
    font-weight: 600;
  }
  .page-404 .menu-404-tablet .options-menu-404 {
    display: grid;
    grid-template-areas: "home-option-404-tablet" "sismos-option-404-tablet" "volcanos-option-404-tablet";
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
  }
  .page-404 .menu-404-tablet .options-menu-404 div {
    margin: 0rem;
  }
  .page-404 .menu-404-tablet .options-menu-404 div a {
    text-align: center;
  }
  .page-404 .menu-404-tablet .options-menu-404 div a p {
    margin: 0.3rem 0rem;
    font-size: 15pt;
    font-weight: 600;
  }
  .page-404 .menu-404-tablet .options-menu-404 div img {
    width: 120px;
  }
  .page-404 .not-404 .info-404 {
    font-size: 10pt;
  }
  .page-404 .not-404 .bg-404 img {
    width: 100%;
  }
}
/**_NotFound**/
/**loader.sass */
.block {
  width: 100%;
  padding: 80px 0;
  border-bottom: 2px solid #333333;
  background: #ececec;
  height: 100vh;
  font-family: lato, helvetica, arial, sans-serif;
}
.block .block-in {
  width: 300px;
  margin: 80px auto;
}
.block .gear::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-style: solid;
  border-top-color: #C9D743;
  border-right-color: #a6bb3f;
  border-bottom-color: #8ca448;
  border-left-color: #667f00;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: rotate 1.5s infinite ease-in-out;
  animation: rotate 1.5s infinite ease-in-out;
  border-width: 10vh;
}
.block .gear::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-style: solid;
  border-top-color: #C9D743;
  border-right-color: #a6bb3f;
  border-bottom-color: #8ca448;
  border-left-color: #667f00;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation: rotate 1.5s infinite ease-in-out;
  animation: rotate 1.5s infinite ease-in-out;
  width: 30vh;
  height: 30vh;
  border-width: 2.5vh;
  animation-direction: reverse;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/**loader.sass */
.volcanos-list-filter {
  -webkit-box-shadow: 0px 0px 10px #000000;
  box-shadow: 3px 0px 10px #000000;
  z-index: 1;
  position: relative;
}
.volcanos-list-filter .volcanos-list-filter-container {
  height: 100%;
  grid-area: volcanos-list-filter-container;
  display: grid;
  grid-template-areas: "volcanos-list-filter-title" "volcanos-list-filter-main";
  grid-template-columns: auto;
  grid-template-rows: auto 70%;
}
.volcanos-list-filter .volcanos-list-filter-container .volcanos-list-filter-title {
  background: #005833;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0rem 1rem;
}
.volcanos-list-filter .volcanos-list-filter-main {
  padding: 5px 15px 10px;
  background: #f1f1f1;
  display: grid;
  grid-template-columns: 100%;
  min-height: 110px;
}
.volcanos-list-filter .volcanos-list-filter-main .order-filter {
  font-size: 12px;
}
.volcanos-list-filter .volcanos-list-filter-main .order-filter .MuiInput-underline::after {
  border-bottom: #667f00;
}
.volcanos-list-filter .volcanos-list-filter-main .search-filter .MuiFormLabel-root.Mui-focused {
  color: #005833;
  -webkit-transform: translate(0, 1px) scale(0.75);
  -moz-transform: translate(0, 1px) scale(0.75);
  -ms-transform: translate(0, 1px) scale(0.75);
}
.volcanos-list-filter .volcanos-list-filter-main .search-filter .MuiInputBase-root.MuiInput-root::after {
  border-bottom: 2px solid #005833;
}
.volcanos-list-filter .volcanos-list-filter-main .search-filter .MuiInputBase-root.MuiInput-root input {
  padding: 6px 0 7px;
}
.volcanos-list-filter .volcanos-list-filter-main .search-filter label + .MuiInputBase-root.MuiInput-root {
  margin-top: 8px;
}

.volcanos-list-levels-container {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "volcanos-list-levels-title" "volcanos-list-levels-icons";
  grid-template-columns: 100%;
  grid-template-rows: 40px 60px;
}
.volcanos-list-levels-container .volcanos-list-levels-title {
  width: 100%;
  grid-area: volcanos-list-levels-title;
  display: grid;
  grid-template-areas: "title-list" "title-icon";
  grid-template-columns: 85% auto;
  grid-template-rows: auto;
}
.volcanos-list-levels-container .volcanos-list-levels-title .title-list {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10pt;
  text-align: center;
}
.volcanos-list-levels-container .volcanos-list-levels-title .title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.volcanos-list-levels-container .volcanos-list-levels-title .title-icon .modal-level-activity {
  display: flex;
  justify-content: center;
  align-items: center;
}
.volcanos-list-levels-container .volcanos-list-levels-title h3 {
  font-size: 100%;
}
.volcanos-list-levels-container .volcanos-list-levels-icons {
  grid-area: volcanos-list-levels-icons;
  display: flex;
  justify-content: center;
  align-items: center;
}
.volcanos-list-levels-container .volcanos-list-levels-icons .modal-level-activity {
  width: 50px;
  margin: 0rem 0.3rem;
}
.volcanos-list-levels-container .volcanos-list-levels-icons .m-modal-level-activity {
  width: 50px;
  margin: 0rem 0.3rem;
}

.fole-main {
  display: grid;
  grid-template-areas: "volcano-level-activity volcano-info-main";
  grid-template-columns: auto 75%;
  grid-template-rows: auto;
  padding: 1rem 0rem;
  font-size: 10pt;
}
.fole-main span {
  font-weight: bold;
}
.fole-main .volcano-level-activity {
  grid-area: volcano-level-activity;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #E3E3E4;
}
.fole-main .volcano-level-activity img {
  width: 50px;
}
.fole-main .volcano-info-main {
  grid-area: volcano-info-main;
  padding: 0rem 1rem;
}
.fole-main .volcano-info-main h3 {
  color: #005833;
  font-weight: bold;
}

.item.active h3 {
  color: #FFFFFF;
}

.fole-expanded {
  display: grid;
  grid-template-areas: "empty-space volcano-info-expanded";
  grid-template-columns: auto 75%;
  grid-template-rows: auto;
  font-size: 10pt;
}
.fole-expanded .empty-space {
  grid-area: empty-space;
  border-right: 2px solid #E3E3E4;
}
.fole-expanded .volcano-info-expanded {
  color: white;
  grid-area: volcano-info-expanded;
  padding: 0rem 1rem;
}
.fole-expanded .volcano-info-expanded .container-info-expanded {
  margin-bottom: 1rem;
}

.container-popup-volcano-marker {
  width: 500px;
  height: 350px;
  display: grid;
  grid-template-areas: "main-popup";
  grid-template-columns: auto;
  grid-template-rows: auto;
  background: #33362D;
  font-family: "Roboto";
  font-size: 9pt;
  z-index: 10000000000000000000000000000000000000000000000;
}
.container-popup-volcano-marker .main-popup {
  grid-area: main-popup;
  display: grid;
  grid-template-areas: "image-title-popup" "info-popup" "btn-container";
  grid-template-rows: 70% auto auto;
}
.container-popup-volcano-marker .main-popup span {
  font-weight: bold;
  color: #005833;
}
.container-popup-volcano-marker .main-popup .image-title-popup {
  grid-area: image-title-popup;
  display: grid;
  grid-template-areas: "img-container" "title-container";
  grid-template-columns: auto;
  grid-template-rows: auto 35%;
}
.container-popup-volcano-marker .main-popup .image-title-popup .img-container {
  background-size: cover;
  background-position: center center !important;
  border-bottom: 1px solid #667f00;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.container-popup-volcano-marker .main-popup .image-title-popup .img-container .level-activity-container {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 50%;
  border: 1px solid #667f00;
  position: relative;
  right: 30px;
  top: 40px;
}
.container-popup-volcano-marker .main-popup .image-title-popup .img-container .level-activity-container img {
  width: 50px;
  height: 50px;
}
.container-popup-volcano-marker .main-popup .image-title-popup .title-container {
  padding: 0.5rem 0.5rem;
  background: white;
  color: black;
}
.container-popup-volcano-marker .main-popup .image-title-popup .title-container .title-event-pp {
  font-size: 12pt;
  font-weight: bolder;
}
.container-popup-volcano-marker .main-popup .image-title-popup .title-container p {
  height: 22px;
}
.container-popup-volcano-marker .main-popup .info-popup {
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  background: white;
  color: black;
  border-top: none;
}
.container-popup-volcano-marker .main-popup .info-popup .info-container {
  grid-area: info-container;
  align-content: center;
}
.container-popup-volcano-marker .main-popup .info-popup .info-container hr {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #667f00;
  margin: 0rem 0rem 0.5rem 0rem;
}
.container-popup-volcano-marker .main-popup .btn-container-pp {
  grid-area: btn-container;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  color: black;
}
.container-popup-volcano-marker .main-popup .btn-container-pp button {
  outline: none;
  border: none;
  color: #FFFFFF;
  background: #005833;
  margin: 0rem 0.5rem;
  width: 150px;
  height: 30px;
  font-size: 10pt;
  border: 1px solid #FFFFFF;
  border-radius: 8px;
  cursor: pointer;
}
.container-popup-volcano-marker .main-popup .btn-container-pp button:hover {
  border: 1px solid #005833;
  color: #005833;
  background: #FFFFFF;
}

@media screen and (max-width: 539px) {
  .container-popup-volcano-marker {
    width: 300px;
    display: grid;
    grid-template-areas: "main-popup";
    grid-template-columns: auto;
    grid-template-rows: auto;
    background: #33362D;
    font-family: "Roboto";
    font-size: 9pt;
  }
}
.close-popup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #667f00;
  width: 500px;
}
.close-popup .icon-close {
  cursor: pointer;
}

#interactiveMapContainer {
  position: relative;
}
#interactiveMapContainer .VolcanoMapHid {
  width: 100%;
  height: 100%;
  position: absolute;
}
#interactiveMapContainer .VolcanoMapHid .HidContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  width: 100%;
  height: 100%;
}
#interactiveMapContainer .VolcanoMapHid .HidContent .HidMask {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  opacity: 0.5;
}
#interactiveMapContainer .VolcanoMapHid .HidContent .HidControls {
  display: grid;
  justify-items: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#interactiveMapContainer .VolcanoMapHid .HidContent .HidControls p {
  text-align: center;
}
#interactiveMapContainer .VolcanoMapHid .HidContent .HidControls button {
  background: #069169;
  color: #FFFFFF;
  border-color: transparent;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition-duration: 0.5s;
}
#interactiveMapContainer .VolcanoMapHid .HidContent .HidControls button:hover {
  border-radius: 12px;
  padding: 12px;
}

@media screen and (max-width: 539px) {
  .close-popup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #667f00;
    width: 300px;
  }
}
.info-observaroy .title-info-observatory {
  background: #005833;
  color: #FFFFFF;
  padding: 1rem;
  font-size: 13pt;
  font-weight: bold;
}
.info-observaroy .city-observatory {
  padding: 0.5rem 1rem;
}

.MuiDialogTitle-root .more-info-link {
  padding: 5px 0;
  margin-left: 4px;
}
.MuiDialogTitle-root .more-info-link a {
  font-size: 0.9rem;
  color: #005833;
}
@media (max-width: 600px) {
  .MuiDialogTitle-root .more-info-link a {
    font-size: 0.8rem;
  }
}

.MuiDialogContent-root .table-content {
  width: 100%;
}
.MuiDialogContent-root .table-content .head-table {
  display: grid;
  grid-template-areas: "state-activity description";
  grid-template-columns: 40% 60%;
  grid-template-rows: auto;
  font-weight: bold;
}
.MuiDialogContent-root .table-content .head-table div {
  border: 2px solid black;
}
.MuiDialogContent-root .table-content .head-table .state-activity {
  border-right: none;
}
.MuiDialogContent-root .table-content .head-table .state-activity, .MuiDialogContent-root .table-content .head-table .description {
  padding: 0.4rem;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #005833;
  color: #FFFFFF;
}
.MuiDialogContent-root .table-content .content-table {
  font-size: 0.9rem;
}
.MuiDialogContent-root .table-content .content-table #BG-g .state-activity-content, .MuiDialogContent-root .table-content .content-table #BG-g .description-content {
  background: #f1f1f1;
}
.MuiDialogContent-root .table-content .content-table .item-content {
  display: grid;
  grid-template-areas: "state-activity description";
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
}
.MuiDialogContent-root .table-content .content-table .item-content div {
  border: 2px solid black;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content {
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns {
  width: 100%;
  height: 100%;
  border: none;
  display: grid;
  grid-template-areas: "color-content text-content";
  grid-template-columns: 45% 55%;
  grid-template-rows: 100%;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns .color-content {
  border: none;
  border-right: 2px solid black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.5rem 0.7rem;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns .color-content img {
  width: 50px;
  margin-bottom: 0.4rem;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns .color-content p.title {
  font-weight: bold;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns .text-content {
  border: none;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiDialogContent-root .table-content .content-table .item-content .state-activity-content .state-activity-columns .text-content p {
  text-align: center;
}
.MuiDialogContent-root .table-content .content-table .item-content .description-content {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiDialogContent-root .table-content .content-table .item-content .description-content p {
  text-align: justify;
}

.MuiDialogContent-root.m-dialog-content {
  padding-left: 0px;
  padding-right: 0px;
  font-size: 0.8rem;
}
.MuiDialogContent-root.m-dialog-content .more-info-link {
  position: sticky;
  width: 90%;
  top: 0;
  left: 20px;
}
.MuiDialogContent-root.m-dialog-content .more-info-link a {
  color: #005833;
}
.MuiDialogContent-root.m-dialog-content p.justified {
  text-align: justify;
}
.MuiDialogContent-root.m-dialog-content p.centered {
  text-align: center;
}
.MuiDialogContent-root.m-dialog-content .m-table-content {
  width: 100%;
  display: table;
  border-spacing: 1.2rem 0;
  border-collapse: separate;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row {
  display: table-row;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content {
  display: table-cell;
  border: 2px solid black;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-head-table {
  font-size: 0.9rem;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table {
  width: calc(100vw - 100px);
  max-width: 520px;
  padding: 0.5rem;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table .m-state-activity {
  text-align: center;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table .m-state-activity img {
  width: 40%;
  max-width: 80px;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table .m-state-activity p.title {
  margin-top: 0;
  margin-bottom: 0.8rem;
  font-weight: bold;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table p {
  font-weight: bold;
  margin: 0.5rem 0rem;
}
.MuiDialogContent-root.m-dialog-content .m-table-content .m-table-row .m-item-content .m-content-table span {
  font-weight: normal;
}

.volcanos-list {
  background: #FFFFFF;
  height: calc(100vh - 100px);
  display: grid;
  grid-template-areas: "volcanos-list-filter" "volcanos-list-main" "volcanos-list-levels";
  grid-template-columns: 100%;
  grid-template-rows: 160px auto 100px;
}
.volcanos-list .volcanos-list-main {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
.volcanos-list .volcanos-list-levels {
  overflow: hidden;
}

.safari-list {
  grid-template-rows: 155px auto auto !important;
}

.container-table-consultation {
  padding: 1rem 2rem;
}
.container-table-consultation .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0 !important;
}
.container-table-consultation .MuiAccordionSummary-content.Mui-expanded p {
  font-size: 10pt !important;
}
.container-table-consultation .CatalogTotalDisabled {
  color: #D9D9D9;
}
.container-table-consultation .CatalogTotalEvents {
  color: #626262;
}
.container-table-consultation .MuiCircularProgress-colorSecondary {
  color: #005833 !important;
}
.container-table-consultation .MuiAccordionDetails-root {
  border: none;
  border-bottom: 1px solid #005833;
}
.container-table-consultation .MuiAccordionSummary-root.Mui-expanded {
  border-bottom: none;
}
.container-table-consultation .MuiAccordionSummary-root {
  border-bottom: 2px solid #005833;
}
.container-table-consultation .buttonMap {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.container-table-consultation .buttonMap p {
  color: #646464;
}
.container-table-consultation .buttonMap #QTablebutton_map {
  background-color: #005833;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  margin-left: 20px;
}
.container-table-consultation .buttonMap #QLoading {
  height: 50px;
}
.container-table-consultation .buttonMap #return {
  background-color: #005833;
  color: #FFFFFF;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-left: 10px;
  padding: 5px 10px;
}
.container-table-consultation .buttonMap .container-loading {
  text-align: center;
}
.container-table-consultation .buttonMap .container-loading .MuiCircularProgress-colorPrimary {
  color: #005833;
}
.container-table-consultation h3 {
  margin: 0.5rem 0rem;
  color: #005833;
}
.container-table-consultation h3 span {
  color: #626262;
  padding-left: 1rem;
  font-size: 9pt;
}
.container-table-consultation h4 {
  color: #D9D9D9;
  margin-top: 2rem;
}
.container-table-consultation .accordion-table-consultation {
  margin: 0.5rem 0rem;
}
.container-table-consultation .info-accordion {
  display: block;
  color: #646464;
}
.container-table-consultation .info-accordion .title-accordion {
  margin-bottom: 1rem;
}
.container-table-consultation .filter-inputs {
  padding-top: 1rem;
  display: flex;
  align-items: center;
}
.container-table-consultation .filter-inputs .form-control {
  height: 37px;
  width: 270px;
  margin-right: 1rem;
  margin-top: 0rem;
}
.container-table-consultation .filter-inputs .text-field {
  height: 37px;
}
.container-table-consultation .filter-inputs .text-field .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}
.container-table-consultation .filter-inputs .text-field .MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(1);
}
.container-table-consultation .filter-inputs .text-field .MuiOutlinedInput-root {
  height: 37px;
}
.container-table-consultation .filter-inputs .text-field .MuiOutlinedInput-root .MuiOutlinedInput-input {
  padding: 0.5rem;
}
.container-table-consultation .MUIDataTable-Catalogador table th {
  text-align: center;
}
.container-table-consultation .MUIDataTable-Catalogador .MuiTablePagination-caption {
  display: none;
}

@media screen and (max-width: 768px) {
  .container-table-consultation h3 {
    font-size: 10pt;
  }
  .container-table-consultation .filter-inputs .form-control {
    width: 100%;
  }
  .container-table-consultation .filter-inputs .text-field {
    width: 100%;
  }
  .container-table-consultation .buttonMap {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-top: 1rem;
    grid-gap: 1rem;
  }
  .container-table-consultation .buttonMap #QTablebutton_map {
    margin-left: 0 !important;
  }
}
.disabledOrder .MuiSelect-selectMenu {
  opacity: 0.5 !important;
}

.queryModalContent .QBodyAvailable .MuiTableCell-root.MuiTableCell-head {
  padding: 5px;
}
.queryModalContent .QBodyAvailable .MuiTableCell-root.MuiTableCell-head .MuiButtonBase-root.MuiButton-root {
  margin-right: 0;
  align-items: center;
}
.queryModalContent .QBodyAvailable .MuiTableCell-root.MuiTableCell-head span, .queryModalContent .QBodyAvailable .MuiTableCell-root.MuiTableCell-head div {
  font-size: 11px;
  line-height: 1.4;
  text-align: center;
}
.queryModalContent .QBodyAvailable .MuiTableCell-root.MuiTableCell-body div {
  font-size: 10px;
}

.CatalogPriority .events-container {
  overflow-y: visible !important;
}

.catalog_list_map {
  grid-template-rows: max-content auto !important;
}
.catalog_list_map .MuiTabs-indicatorSpan {
  background-color: transparent !important;
}
.catalog_list_map .summary-container {
  height: max-content !important;
  padding-bottom: 10px;
}
.catalog_list_map .events-container {
  height: calc(100vh - 207px) !important;
}
.catalog_list_map .events-container-simple {
  height: calc(100vh - 207px) !important;
  display: grid !important;
}
.catalog_list_map .events-container-simple .grid-events {
  overflow-y: scroll;
}
.catalog_list_map .events-pagination {
  align-self: end;
}
.catalog_list_map .events-container-double {
  height: calc(100vh - 400px) !important;
}
.catalog_list_map .QfilterModal {
  outline: none;
  background: #2d66d1;
  color: #FFFFFF;
  padding: 2px 10px;
  border: none;
  cursor: pointer;
}

.QFilterContainer {
  width: 80%;
  margin: auto;
  height: 500px;
  background: #FFFFFF;
  overflow-y: scroll;
}
.QFilterContainer .QueryContainer {
  background: #FFFFFF;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
}
.App #leafletWSMmap {
  position: relative;
  width: 100%;
  height: 100%;
}

#mapLegendContainerCatalogo {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  bottom: 0;
  margin-bottom: 20px;
  width: 100%;
  padding: 0 1rem;
}
#mapLegendContainerCatalogo button {
  z-index: 100;
  background-color: #fafafa;
  z-index: 1000;
  font-size: 100%;
}
#mapLegendContainerCatalogo button .legend-label {
  font-size: 80%;
}
#mapLegendContainerCatalogo .popup {
  z-index: 1000;
  background-color: #fafafa;
  margin-bottom: 20px;
  left: 0 !important;
}
#mapLegendContainerCatalogo .MuiPopover-paper {
  top: 60 !important;
}

.CatalogModal {
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}
.CatalogModal .Hmlogo {
  width: 100%;
}
.CatalogModal .container-table-consultation {
  background: #FFFFFF;
}
.CatalogModal .CatalogModalClose {
  position: fixed;
  right: 10px;
  top: 20px;
  cursor: pointer;
  z-index: 2000;
}
.CatalogModal .QueryContainer {
  background: #FFFFFF;
  height: 100vh;
  overflow-y: scroll;
}

.tableConsultTimeFormat span {
  display: block;
}

.c100 {
  z-index: 100;
}

.c200 {
  z-index: 200;
}

.c300 {
  z-index: 300;
}

.c400 {
  z-index: 400;
}

.c500 {
  z-index: 500;
}

.c600 {
  z-index: 600;
}

.c700 {
  z-index: 700;
}

.c800 {
  z-index: 800;
}

.MuiFormHelperText-filled {
  color: red !important;
}

#standard-number-helper-text {
  color: red !important;
}

.max_font {
  font-size: x-large;
}
.max_font #container-resumen-1 {
  grid-template-rows: 70px auto;
}
.max_font #leafletMiniMapId {
  width: 100%;
}

.initial_font {
  font-size: initial;
}

.small_font {
  font-size: small;
}

.min_font {
  font-size: x-small;
}

.High_contrast {
  -webkit-filter: invert(1);
  filter: invert(1);
  background-color: #000000;
}
.High_contrast table tr {
  background: #FFFFFF !important;
}
.High_contrast .Services_icon_on img {
  filter: none !important;
}

.leaflet-container {
  height: 100vh;
  z-index: 1;
}

.LoadingOrder .MuiLinearProgress-barColorPrimary {
  background-color: #069169 !important;
}

.HideElement {
  display: none !important;
}

.Link {
  cursor: pointer;
}


