/*HomeMenu*/
@import _HomeMenu

/*.HmHeader
.HmLogos
   margin: auto
   position: relative
   width: 80%
   height: 80px
   max-width: $XLarge
   background: $White
   padding: 10px 0
   display: flex
   justify-content: space-between

   .HmIcon
      cursor: pointer
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      .logo-sgc-white
         display: none
      .logo-sgc
         display: unset

      img
         width: 130px 

@media (max-width: $Medium)
   .HmLogos
      background: $SGC
      width: 100%
      display: flex
      justify-content: flex-start
      padding: 8px
      .HmIcon
         .logo-sgc-white
            display: unset
         .logo-sgc
            display: none

@media (min-width: $XLarge)
   .HmLogos
      width: 90%
