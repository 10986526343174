.defaultToolTip
    padding: 0
    // cursor: help
.inlineTooltip
    display: inline
    vertical-align: middle
.spaceBottom_tooltip
    padding-bottom: 5px
    // cursor: help
.spaceTop_tooltip
    padding-top: 5px
    // cursor: help
.spaceRight_tooltip
    padding-right: 5px
    // cursor: help
.spaceLeft_tooltip
    padding-left: 5px
    // cursor: help
.TmContainer
    display: flex
    align-items: center
    label
        width: max-content
        min-width: 110px
    .spaceTm_tooltip
        padding-left: 10px