.notification-card-volcano
    padding: 0.3rem
    

    .notification-volcano-item
        padding: 0.1rem
        border-bottom: 1px solid $Green
        .type-notification-volcano
            font-size: 8pt
                

        .title-notification-volcano
            padding: 0.1rem
            font-size: 8pt
            font-weight: normal
            color: $Green
            
            .title-type
                font-size: 8pt

        .level-notification-volcano
            padding: 0.1rem
            font-size: 8pt
            font-weight: bold

            span
                font-weight: normal

        .info-notification-volcano
            padding: 0.1rem
            display: grid
            grid-template-areas: "intensity-notification-volcano depth-localization-notification-volcano"
            grid-template-columns: 15% 85%
            grid-auto-rows: auto 

            .intensity-notification-volcano
                @extend .align-justify-center
                width: 45px
                height: 45px
                background-size: cover
                // div
                //     @extend .align-justify-center
                //     width: 45px
                //     height: 45px
                //     background-size: cover
                //     background-image: url("../assets/icons/EQViewer/Indicators/Magnitude-Background.svg")

            .level-download-volcano
                padding: 0.1rem
                @extend .align-center
                font-size: 8pt

                span
                    font-weight: normal

                hr
                    color: $White
                    border-top: none
                    border-right: none
                    border-left: none
                    border-bottom: 2px solid $White
                p
                    font-weight: normal

                .container-description
                    width: 250px
                    
                    p
                        font-size: 8pt
                        font-weight: bold
                        white-space: nowrap
                        text-overflow: ellipsis
                        overflow: hidden

    .close-notification-volcano-card
        .icon-close
            cursor: pointer
    &:hover
        background: #6d6d6d
        color: $White
        .title-notification-volcano
            color: $White         
