
.MuiDialogContent-root.m-dialog-content
   padding-left: 0px
   padding-right: 0px
   font-size: .8rem
   .more-info-link
      position: sticky
      width: 90%
      top: 0
      left: 20px
      a
         color: $Green
   p.justified
      text-align: justify
   p.centered
      text-align: center
   .m-table-content
      width: 100%
      display: table
      border-spacing: 1.2rem 0
      border-collapse: separate
      .m-table-row
         display: table-row
         .m-item-content
            display: table-cell
            border: 2px solid black
            .m-head-table
               font-size: .9rem
               border-bottom: 2px solid black
               display: flex
               justify-content: center
               align-items: center
               padding: .5rem
            .m-content-table
               width: calc( 100vw - 100px )
               max-width: 520px
               padding: .5rem
               .m-state-activity
                  text-align: center
                  img
                     width: 40%
                     max-width: 80px
                  p.title
                     margin-top: 0
                     margin-bottom: .8rem
                     font-weight: bold
               
               p
                  font-weight: bold
                  margin: .5rem 0rem
               span
                  font-weight: normal

            