/***Services_map
.Services_hidden
    opacity: 0 !important
    display: none !important
    height: 0 !important
    .services_header
        display: none
        height: 0 !important
    .services_body
        height: 0 !important
        display: none !important
        a
            display: none !important
            height: 0 !important
.Services_icon_on
    img
        -webkit-filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important
        filter: invert(1) sepia(1) hue-rotate(0deg) saturate(0%) !important
// #services_container
#MapservicesCrt
    padding: 0
    display: flex
    transition-duration: .3s
#MapServices, #accesibility_services, #accesibility_services_popup
    position: absolute
    box-shadow: -7px 10px 14px -10px rgba(0, 0, 0, .5)
    display: block
    max-width: 300px
    width: 100%
    border-radius: 5px
    // top: 100%
    right: 10px
    background: $White
    transition-duration: .3s
    &:hover
        box-shadow: -7px 10px 14px -10px rgba(0, 0, 0, .8)
    img
        width: 35px
    .services_header
        position: relative
        width: 100%
        padding: 20px
        display: grid
        justify-items: center
        &::before
            content: ''
            position: absolute
            height: 1.5px
            bottom: 0
            left: 0
            width: 100%
            background: $Grey11
        img
            width: 150px
        h4
            text-align: center
            color: $Grey11
    .services_body
        height: 320px
        overflow-y: scroll
        display: block
        scroll-behavior: smooth
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
            width: 10px
        &::-webkit-scrollbar-track
            background: $Grey1
        &::-webkit-scrollbar-thumb
            background: $Green
        a
            display: flex
            justify-content: flex-start
            align-items: center
            span
                color: $Grey11
                font-size: 10pt
                text-align: left
                margin-left: 0.5rem
/***Services_map
/***Accessibility

.styles_body
    span
        display: block
        padding: 10px
        cursor: pointer
        &:hover
            background: $Grey1
.High_contrast
    .EQTensor_container .fila2 .tablas
        .tabla1 thead th
            background: $PGreen
        .tabla3 th
            background: $PGreen
    .reports-cdi
        p
            color: $White
    .styles_body
        span
            &:hover
                background: $Black
                color: $White

@media ( max-width: 400px )
    .Vsection_slider
        padding: 0 25px 0 20px !important
    .viewer-header
        grid-gap: 0 !important
        .sgc-logo .logo img
            width: 78px
    .container-events-list
        .summary_content
            width: 90% !important
    .max_font
        .summary_content
            div
                padding-left: 15px
        .overviewtable td
            padding: 0 !important

.max_font
    .details_element
        overflow-y: scroll !important
    .HomeSocialTabs
        div a
            width: 200px
    .MuiPickersCalendarHeader-transitionContainer
        height: 32px !important
    .search-filter
        margin: 0 !important
    .volcanos-list-filter-main
        padding: 0.8rem 0rem !important
    .EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div
        height: 4.375rem
    .content .events-list
        .container-events-list
            grid-template-rows: max-content auto !important
        .summary_content
            height: max-content !important
    
    .summary_content
        padding-left: 10px

@media ( min-width: 1200px )
    .max_font
        .container-resumen
            margin: 0.5rem 1rem !important
    

/***Accessibility

