/**SummayGrid

.EQSummary
  padding: 8px 40px .2rem 40px
  background: $Grey1
  -webkit-box-shadow: 10px 0px 13px -7px rgba(0, 0, 0, 0.5) inset, 5px 5px 15px 5px rgba(0, 0, 0, 0) inset
  box-shadow: 10px 0px 13px -7px rgba(0, 0, 0, 0.5) inset, 5px 5px 15px 5px rgba(0, 0, 0, 0) inset
  @media( max-width: $Small )
   padding: 6px 5vw 

  .EQSummaryTitle
    color: $Green
    text-align: center
    font-size: 18pt
    border-bottom: 2px solid $Green
    padding-bottom: 5px
    font-family: "Montserrat", sans-serif
    @media( max-width: $Small )
      font-size: 15pt
      padding: 7px 0
    h2
      font-weight: 800  

.DetailsInfoGrid
   display: grid
   border: 1px solid white
   margin: 16px 0
   border-radius: 8px
   overflow: hidden
   &.Summary
      grid-template-columns: 1fr 1fr .75fr
      .InfoRow
         grid-template-columns: .5fr 1fr

      @media( max-width: 1050px ) and ( min-width: $Small + 1px )
         grid-template-columns: 1fr 1fr
         .InfoColumn
            &:nth-child(3)
               grid-column: 1 / 3
               display: grid
               grid-template-columns: 1fr 1fr
               .InfoRow
                  background-color: transparent

   &.Volcano
      grid-template-columns: 1fr 1fr
      .InfoRow
         grid-template-columns: .7fr 1fr
      @media( max-width: $Small )
         .InfoColumn
            &:nth-child(2)
               .InfoRow:nth-child(odd)
                  background-color: white
               .InfoRow:nth-child(even)
                  background-color: transparent

   .InfoColumn
      overflow: hidden

   @media( max-width: $Small )
      display: block
      .InfoRow
         .InfoTitle
            padding: 10px 12px
   @media( max-width: $XSmall )
      .InfoRow
         grid-template-columns: .75fr 1fr

.InfoRow
   display: grid
   grid-template-columns: 1fr 1fr
   margin-bottom: 1px
   &.Small
      grid-template-columns: .75fr 1fr
   .InfoTitle
      background-color: $Green
      color: white
      font-weight: bold
      padding: 13px 10px
      overflow: hidden
      text-wrap: nowrap
      text-overflow: ellipsis
      align-content: center
   .InfoText
      padding: 5px 12px
      display: flex
      align-items: center
      .level_volcano
         justify-content: flex-start
         
   &:nth-child(even)
      background-color: white
      


@media (min-width: 200px)
  .EQSummary

    .summary_hidden
      display: none

    .SummaryGrid
      overflow-x: hidden
      display: -ms-grid
      display: grid
      -ms-grid-columns: 38% 32% 30%
      grid-template-columns: 38% 32% 30%
      -ms-grid-rows: auto
      grid-template-rows: auto
      padding: 10px 0
      -webkit-box-align: start
      -ms-flex-align: start
      align-items: flex-start
      font-family: "Oxigen"
      font-size: 12pt

      > *
        &:nth-child(1)
          -ms-grid-row: 1
          -ms-grid-column: 1

        &:nth-child(2)
          -ms-grid-row: 1
          -ms-grid-column: 2

        &:nth-child(3)
          -ms-grid-row: 1
          -ms-grid-column: 3

      p
        display: -ms-grid
        display: grid

        strong
          color: $Green

@media (min-width: 1200px)
  .EQSummary

    .EQSummaryTitle
      margin: 10px 0 10px 0

    .summary_hidden
      display: unset
      padding-right: 5px
      color: $GrayText

    .SummaryGrid p
      display: -webkit-box
      display: -ms-flexbox
      display: inline
      font-size: 13pt

      span
        &:nth-child(1) strong
          color: $Grey7

        &:nth-child(2)
          color: $Grey6

/**SummayGrid

.EQContentManager
  padding: 35px 40px 0
  .EQSectionContainer
   margin-bottom: 16px
   &:last-child
      margin-bottom: 0
  @media ( max-width: $Small )
   padding: 4vw

