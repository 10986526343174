.cards-news
    width: 100%
    height: 100%
    padding: 0rem 1rem
    .Vcard-selected
        position: relative
        transition-duration: .4s
        background: $Grey6 !important

    .card-new
        display: grid
        grid-template-areas: "img-new-card content-new-card" 
        grid-template-columns: 20% 80%
        grid-template-rows: 80px
        background: $Grey19
        margin-bottom: 0.5rem
        cursor: pointer
        .img-new-card
            background-size: cover
            background-position: center center

        p, h6
            color: $White
            margin: 0px

        .content-new-card
            display: grid
            grid-template-areas: "content-new circles" 
            grid-template-columns: 60% 40%
            grid-template-rows: 100%
            padding: .5rem

            .content-new
                .content-trunk
                    width: 100%
                    white-space: nowrap
                    overflow: hidden
                    text-overflow: ellipsis

        .circles
            display: flex
            justify-content: center
            align-items: center
            img
                width: 30px
            .circle
                width: 35px
                height: 35px
                border-radius: 50%
                background: $Grey6
                margin: 0rem .3rem
                display: flex
                justify-content: center
                align-items: center
                // background-image: url('../assets/icons/Reusable/pdf.svg')
                img
                    width: 25px
                    height: 25px

@media screen and ( max-width: 1000px )
    .cards-news
        .card-new
            .content-new-card
                display: grid
                grid-template-areas: "content-new circles" 
                grid-template-columns: 60% 40%
                grid-template-rows: 100%
                padding: .5rem