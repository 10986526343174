/**_EQMenu

.EQmenu
  width: 100%
  height: 100%

  .products-list
    overflow: hidden
    position: fixed
    width: 100%
    height: 100%

    .menuheader
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      -webkit-box-pack: start
      -ms-flex-pack: start
      justify-content: flex-start
      width: 100%
      height: 120px
      background-image: url("../assets/icons/EQDetail/pruebaVisores-01.png")
      background-size: cover
      border-bottom: 2px solid $White
      font-family: 'Montserrat', sans-serif

      h2
        font-size: 16pt
        color: $White
        padding-left: 20px

      h5
        font-size: 10pt
        margin-top: 20px
        margin-left: 20px
        color: $White

      hr
        height: 4px
        width: 100%
        border-top: 1px dotted $White
        background-color: $White
        margin-top: 5px
        margin-bottom: 5px
        
    .volcanos-filter-container
      background: $Grey1
      padding: 1rem
      border: 1px solid $Green

    .EQMenu-list-container
      background: $GrayText

      .EQMenu_list_content
        display: block 
        padding: 0 20px 12px
        overflow-y: scroll
        height: calc( 100vh - 220px )
        scroll-behavior: smooth
        -webkit-overflow-scrolling: touch
        &::-webkit-scrollbar
          width: 10px

        &::-webkit-scrollbar-track
          background: $MenuBlack

        &::-webkit-scrollbar-thumb
          background: $Green

          &:hover
            background: $Grey5

        li
          list-style: none
          height: 55px
          border-bottom: 1px solid $White
          display: -webkit-box
          display: -ms-flexbox
          display: flex
          -webkit-box-align: center
          -ms-flex-align: center
          align-items: center

          &:hover p, &.active p
            color: $OranYellow
            font-weight: bolder

        div
          display: -webkit-box
          display: -ms-flexbox
          display: flex
          width: 100%
          -webkit-box-align: center
          -ms-flex-align: center
          align-items: center

        a.EQMenu-list-item
          width: 100%
          display: -ms-grid
          display: grid
          text-decoration: none
          color: $White
          -ms-grid-columns: 40px 15px auto
          grid-template-columns: 40px 15px auto
          -ms-grid-rows: 1fr
          grid-template-rows: 1fr
          -webkit-box-align: center
          -ms-flex-align: center
          align-items: center
          line-height: 1rem

          > *
            &:nth-child(1)
              -ms-grid-row: 1
              -ms-grid-column: 1

            &:nth-child(2)
              -ms-grid-row: 1
              -ms-grid-column: 2

            &:nth-child(3)
              -ms-grid-row: 1
              -ms-grid-column: 3

          img
            width: 100%

        hr
          width: 1px
          height: 27px
          border-top: none
          border-bottom: none
          border-left: none
          border-right: 1.5px solid $LightGray
          padding-left: 6px

      #EQMenu_list_content_volcano
          overflow-y: scroll
          height: calc( 100vh - 330px )
          scroll-behavior: smooth
          -webkit-overflow-scrolling: touch
          padding: 5px 0
          font-size: 11px
          background-color: white
          a
            text-decoration: none
          
          
@media (min-width: 1238px)
  .products-list
    overflow: hidden
    position: fixed
    width: 22% !important
@media ( max-width: 600px )

  #EQMenu_list_content_detail
    height: calc( 100vh - 200px ) !important
  
/**_EQMenu