.info-observaroy
    .title-info-observatory
        background: $Green
        color: $White
        padding: 1rem
        font-size: 13pt
        font-weight: bold
        
    .city-observatory
        padding: .5rem 1rem

        