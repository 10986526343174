.volcanos-list-levels-container
	background: $White
	width: 100%
	height: 100%
	display: grid
	grid-template-areas: "volcanos-list-levels-title" "volcanos-list-levels-icons"
	grid-template-columns: 100%
	grid-template-rows: 40px 60px
	
	.volcanos-list-levels-title
		width: 100%
		grid-area: volcanos-list-levels-title
		display: grid
		grid-template-areas: "title-list" "title-icon"
		grid-template-columns: 85% auto
		grid-template-rows: auto

		.title-list
			display: flex
			justify-content: center
			align-items: center
			font-size: 10pt
			text-align: center
		
		.title-icon
			display: flex
			justify-content: center
			align-items: center
			.modal-level-activity
				display: flex
				justify-content: center
				align-items: center
			
		h3
			font-size: 100%

	
	.volcanos-list-levels-icons
		grid-area: volcanos-list-levels-icons
		display: flex
		justify-content: center
		align-items: center

		.modal-level-activity
			width: 50px
			margin: 0rem .3rem

		.m-modal-level-activity
			width: 50px
			margin: 0rem .3rem