.HomeNewsContainer
   p
      a
         text-decoration: none
         color: inherit
         &:hover
            color: $SGC

.HomeNewsContent
   background-color: $LightGray
   padding: 4vw 0
   .MainNew, .NewsGrid
      width: 80%
      margin: 0 auto
      max-width: $HzMaxWidth
   .MainNew
      margin-bottom: 2rem
      display: grid
      grid-template-columns: 2fr 1fr
      .MainImgContainer
         margin-right: -20px
         z-index: 1
         max-height: 525px
         img.MainImg
            width: 100%
            height: 100%
            &:hover
               opacity: .9
      .MainNewInfoBg
         background-color: $Green
         color: white
         padding: 40px 
         overflow: hidden
         height: 100%
         margin-left: 20px
         .MainNewInfo
            height: 100%
            display: flex
            -ms-flex-direction: column
            flex-direction: column
            justify-content: center
            .NewTitle
               transition: .25s ease
               &:hover
                  transform: scale(101%)
         .NewsText
            font-size: .9rem
   .NewsGrid
      display: grid
      gap: 30px
      grid-template-columns: 1fr 1fr 1fr
      .NewsItem
         transition: .25s ease
         img.HomeNewImg
            width: 100%
            max-height: 200px
            display: block
         .NewsItemLink
            text-decoration: none
               
         .SubInfoText
            margin-top: 8px
            font-size: .8rem
            color: $DarkGray
         .InfoText
            font-size: .9rem
            color: $Green
            margin-top: 5px
         .TitleText
            margin-top: 8px
            font-weight: 700
            font-size: .9rem
            color: $DarkGray

         &:hover
            transform: scale(101%)
            .TitleText
               color: $SGC


.SectionAction
   padding-top: 3vw
   text-align: center

.ShadowBoxGray
   width: 100%
   height: 80px
   background: $LightGray


@media ( max-width: $Medium )
   .HomeNewsContent
      .MainNew, .NewsGrid
         width: 90%
      .MainNew
         grid-template-columns: 1fr
         .MainImgContainer
            margin-right: 0
            img.MainImg
               max-height: 500px
         .MainNewInfoBg
            margin-left: 0
            padding: 40px
            height: unset
      .NewsGrid
         .NewsItem
            img.HomeNewImg
               height: 16vw
   
@media ( max-width: $Small )
   .HomeNewsContent
      .MainNew, .NewsGrid
         width: 80%
      .NewsGrid
         grid-template-columns: 1fr
         .NewsItem
            .TitleText
               font-size: 1.1rem
            img.HomeNewImg
               max-height: 300px
               height: auto
      .MainNew
         .MainNewInfoBg
            font-size: 15px
            .NewsText
               font-size: 14px

   .SectionAction
      padding-top: 6vw
         
@media ( max-width: $XSmall )
   .HomeNewsContent
      .MainNew, .NewsGrid
         width: 90%   
      .NewsGrid
         .NewsItem
            .TitleText
               font-size: 14px
      .MainNew
         .MainNewInfoBg
            .NewsText
               font-size: 3.2vw
         
   .SectionAction
      padding-top: 8vw