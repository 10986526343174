/*HomeCarousel

.HomeCarouselContainer
  background: $Grey1
  width: 100%
  

.HomeCarousel, .EventsCarousel
   .CarouselSwip
      position: absolute
      height: 100%
      z-index: 1
      pointer-events: none
      @media( max-width: $Small )
         pointer-events: all
      &.Right
         right: 0
         top: 0

   .CarouselBtnBack, .CarouselBtnNext
      opacity: 0.5
      position: relative
      pointer-events: all
      z-index: 2
      cursor: pointer
      top: 50%
      transform: translateY(-50%) 
      transition: .25s ease
      padding: 1rem 0
      @media (max-width: $Small)
         padding: 0
      svg.MuiSvgIcon-root
         color: $SGC
         font-size: 80px
         @media (max-width: $Medium)
            font-size: 7vw
         @media (max-width: $Small)
            font-size: 8vw
      &:hover
         opacity: 1
         transform: translateY(-50%) scale(105%)

   .CarouselBtnBack
      left: 35px
   .CarouselBtnNext
      right: 35px
   @media (max-width: $Medium)
      .CarouselBtnBack
         left: 1vw
      .CarouselBtnNext
         right: 1vw
   @media (max-width: $Small)
      .CarouselBtnBack
         left: 0px
      .CarouselBtnNext
         right: 0px

.HomeCarousel
   padding-bottom: 100px
   height: 100%
   @media ( max-width: $Small )
      padding-bottom: 50px
   .CarouselImg
      min-height: 300px
      .HomeImg
         @media ( max-width: $XSmall )
            min-height: 50vw 
      @media ( max-width: $Small )
         min-height: 200px
      @media ( max-width: $XSmall )
         min-height: 50px

   .carousel
      overflow: visible
      .control-dots
         bottom: -70px
         @media ( max-width: $Small )
            bottom: -45px
         .dot
            background: $SGC
            box-shadow: none
            width: 9px
            height: 9px
/* ----------------------------------

/* Events Carousel
.EventsCarouselContainer
   position: relative
   width: 100%
   background: url("../assets/img/Home/bg-volcanos.png")
   background-position: center center
   background-repeat: no-repeat
   background-size: cover
   &::before
      content: ""
      position: absolute
      top: 0px
      right: 0px
      bottom: 0px
      left: 0px
      background-color: rgba(0, 0, 0, 0.551)

.EventsCarousel
   padding-bottom: 2vw
   min-height: 40vh

.SlideEventContainer
   width: 80%
   max-width: $HzMaxWidth
   margin: auto
   padding: 7vw 0
   height: 100%
   display: flex
   flex-wrap: wrap
   align-content: center

.SlideEvent
   width: 100%
   padding: 45px 5vw
   border: 3px solid white
   border-radius: 15px
   color: white
   background-color: rgba(42, 42, 42, 0.147)

   .EventEmphasisText
      display: block
      font-weight: 700
      color: $SGC
      font-style: italic

   .SlideEventTitle
      border-top: 4px solid $SGC
      border-bottom: 4px solid $SGC
      padding: .6rem 2rem
      text-align: center
      width: max-content
      margin: auto
      span
         color: white
   
   .SlideEventSubTitle
      padding: 16px
      padding-bottom: 0
      .EventSuperTitle
         margin-bottom: 3px
         color: $SGC
         text-align: center
         font-size: 1.2rem
         font-weight: 800

   .SlideEventContent
      width: 100%
      padding: 3rem 0
      display: flex
      justify-content: space-around
      align-items: center
      @media ( max-width: $Medium )
         display: block

      .EventInfo
         text-align: left
         padding-right: 35px
         max-width: 48%
         @media ( max-width: $Medium )
            max-width: none
            margin-bottom: 2rem
         .EventTitle
            display: inline-block
            margin-top: 10px
            font-size: 1.5rem
            font-weight: 800
         .EventSubTitle
            font-size: 1.15rem
            margin-bottom: 4px
         .EventDescription
            margin-top: 12px
            font-size: .9rem

      .VolcanoData
         min-width: 50%
      .EventData
         display: -webkit-box
         display: -ms-flexbox
         display: flex
         align-items: center
         justify-content: center 
      
      // Boletines Volcanes
      .EventTableContainer
         display: flex
         border: 1px solid white
         border-radius: 10px
         min-width: 400px
         font-weight: 700
         @media (max-width: $Small)
            flex-direction: column
         .EventTableItem
            flex-grow: 1
            flex-basis: 0
            .EventTableContent
               padding: 25px 35px
               height: 100%
               display: flex
               align-items: center
               @media (max-width: $Medium)
                  padding: 15px 25px
                  font-size: .8rem
               .TableListLink
                  text-decoration: none
                  color: inherit
                  &:hover
                     color: $SGC

               .TableListItem
                  margin: 5px 0
                  display: flex
                  align-items: center
                  font-weight: 900
                  span
                     margin-left: 5px
                     text-align: left
               
@media ( max-width: $Small )
   .SlideEvent
      width: 100%
      margin: 0 auto
      padding: 40px 9vw

      .SlideEventTitle
         padding: 1vw 0
         width: auto
         span.HomeTitle
            font-size: 2.5vw

      .SlideEventSubTitle
         padding: 16px
         padding-bottom: 0
         .EventSuperTitle
            color: $SGC
            text-align: center
            font-size: 1.2rem
            font-weight: 800

      .SlideEventContent
         font-size: 15px
         padding: 3.5vw 0
         .EventInfo
            padding-right: 0
            margin-bottom: 23px
            .EventTitle
               font-size: 20px
               margin-bottom: 4px
            .EventSubTitle
               font-size: 16px
            .EventDescription
               margin-top: 8px
               font-size: 14px
         
         .VolcanoData 
            margin: 0 auto

         .EventTableContainer
            padding: 15px 5vw 0
            width: 80%
            min-width: auto
            margin: 0 auto
            .EventTableItem
               .EventTableContent
                  padding: 0
                  padding-bottom: 15px

@media ( max-width: $XSmall )
   .SlideEventContainer
      width: 100%
      padding: 40px 9vw
   .SlideEvent
      padding: 7vw 10vw

      .EventEmphasisText
         font-size: 2.5vw

      .SlideEventTitle
         border-width: 3.5px
         span.HomeTitle
            font-size: 2.8vw

      .SlideEventSubTitle
         padding: 16px 0 0
         .EventSuperTitle
            font-size: 4vw

      .SlideEventContent
         font-size: 3vw
         padding: 4vw 0
         .EventInfo
            margin-bottom: 6vw
            .EventTitle
               font-size: 3.8vw
               margin-top: 1vw
            .EventSubTitle
               font-size: 3vw
            .EventDescription
               margin-top: 2vw
               font-size: 2.5vw

         .EventTableContainer
            padding: 15px 6vw 0
            width: 100%
            .EventTableItem
               .EventTableContent
                  font-size: 2.5vw
                  padding-bottom: 2vw

/*HomeCarousel

/* Responsive Bordered Grid
.ResponsiveGrid
   display: grid
   grid-auto-columns: 1fr
   grid-auto-flow: column
   border: 1px solid white
   border-radius: 8px

   &.EventsGrid
      grid-auto-columns: minmax(100px, 160px)
      .GridItem
         .GridItemTitle
            padding: 0 8px
   
   &.VolcanosGrid
      grid-auto-columns: minmax(auto, 160px)

   .GridItem
      &.Flex
         display: flex
         flex-direction: column
      &:not(:last-child)
         border-right: 1px solid white
      .GridItemTitle
         padding: 0 20px
         height: 40px
         border-bottom: 1px solid white
         display: flex
         flex-wrap: wrap
         align-items: center
         justify-content: space-evenly
         text-transform: uppercase
         font-size: .65rem
         font-weight: 900
         line-height: .8rem
         letter-spacing: -0.1pt
         span
            width: 100%
            p
               white-space: nowrap
               overflow: hidden
               text-overflow: ellipsis
      .animate
         transition: .25s
         &:hover
            transform: scale(95%)
      .GridItemContent
         padding: 10px 16px
         position: relative
         text-align: center
         &.Flex
            flex-grow: 1
            display: flex
            justify-content: center
            align-items: center
         img
            max-height: 80px
         .ItemText
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            .ImgVolcano
               width: 14vw
         .Big
            font-size: 24px
         .Small
            font-size: .85rem
         span
            display: block
            white-space: nowrap
         img.dates-icons
            height: 100%
            max-height: 50px
            margin: 0
            min-width: 40px
         .TextMedium
            font-size: .8rem    
      
   @media ( min-width: $Medium ) and ( max-width: $Large)
      &.EventsGrid
         grid-template-rows: auto auto
         grid-auto-flow: row
         &.FourItems
            grid-template-columns: repeat(2, minmax(120px, 160px))
            .GridItem
               grid-column: auto
         &.FullItems
            grid-template-columns: repeat(6, minmax(60px, 80px))
            .col-3
               grid-column: auto / span 3
         .GridItem
            grid-column: auto / span 2
            &:nth-child(n+1)
               border-right: 1px solid white
            &:last-child
               border-right: none
         
         &.FullItems .GridItem:nth-child(n+4), &.FourItems .GridItem:nth-child(n+3)
            border-top: 1px solid white
         &.FullItems .GridItem:nth-child(3), &.FourItems .GridItem:nth-child(2)
            border-right: none

   @media ( max-width: $Small )
      &.EventsGrid
         grid-template-rows: auto auto
         grid-auto-flow: row

         &.FourItems
            margin: 0 20px
            grid-template-columns: repeat(2, minmax(100px, 160px))
            .GridItem
               grid-column: auto
         &.FullItems
            grid-template-columns: repeat(6, minmax(50px, 80px))
            .col-3
               grid-column: auto / span 3
         .GridItem
            grid-column: auto / span 2
            &:nth-child(n+1)
               border-right: 1px solid white
            &:last-child
               border-right: none
            
         &.FullItems .GridItem:nth-child(n+4), &.FourItems .GridItem:nth-child(n+3)
            border-top: 1px solid white
         &.FullItems .GridItem:nth-child(3), &.FourItems .GridItem:nth-child(2)
            border-right: none
         
      .GridItem
         .GridItemTitle
            padding: 3px 6px
            font-size: 10px
         .GridItemContent
            img
               max-height: 70px
            span
               font-size: 14px
            .Big
               font-size: 21px
            .Small
               font-size: 12px

   @media ( max-width: $XSmall )
      &.EventsGrid
         &.FourItems
            margin: 0
            grid-template-columns: repeat(2, minmax(50px, 120px))
         &.FullItems
            grid-template-columns: repeat(6, minmax(25px, 60px))

      .GridItem
         &.Flex
            .GridItemContent
               padding-left: 1vw
               padding-right: 1vw
         .GridItemTitle
            height: 8vw
            font-size: 2vw
            line-height: 2.4vw
         .GridItemContent
            height: 16vw
            padding: 2vw 3vw
            .ItemText
               &.Img
                  height: 60%
               .ImgVolcano
                  width: 10vw
            span
               font-size: 2.5vw
            .Big
               font-size: 3.5vw
            .Small
               font-size: 2.1vw
            img
               height: 100%
               max-height: none