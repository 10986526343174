.EQCdi
   .intensity-info
      padding: 1rem
      border: 2px solid $Green
      display: flex
      align-items: center

.max-intensity-cdi
    display: flex
    align-items: center
    color: $Green
    .dates-icons
      margin: 5px 8px
      width: 25px
      height: 25px
.reports-cdi 
    display: inline-block
    margin: 15px 0

/* EQCdiTabs */
.main-content-cdi
    display: block

    .intensity-table
        grid-area: intensity-table
        .ems
            width: 50px
        table 
            th
                background: $Grey0
                border: 1px solid $Black
            td
                border: 1px solid $Black
                padding: 8px 16px
        display: flex
        margin-bottom: 1rem
        .dates-icons
         width: 35px
         height: 35px
         margin: 0 auto


    .intensity-icon
        display: flex
        justify-content: center
    .intensity-description
        display: flex
        width: 100%
        justify-content: space-between
    .infographic-info
        padding: 1rem
        border: 2px solid $Black
        display: flex
        align-items: center

    .map-table-cdi
        display: grid
        grid-template-columns: 70% auto
        grid-template-rows: auto
        grid-template-areas: "map-cdi intensity-table"
        gap: 16px


.map-cdi
    margin-bottom: 1rem
    .LegendButton
      background-color: white
      z-index: 1000
      margin-top: 60px
      margin-left: 10px
      border: 2px solid rgba(0,0,0,0.2)
      line-height: normal
      padding: 5px
    .epicenter-icon-container
        display: flex
        justify-content: space-between
        align-items: center
    .epicenter-icon
        height: .9rem
        margin-right: 5px

        
#map-cdi 
    width: 100%
    height: 700px
    position: relative
.cdi-tables 
    display: grid
    margin-bottom: 1rem
    grid-template-columns: 100%
    grid-template-rows: auto
    grid-template-areas: "intensity-legal"
    grid-gap: 1rem
    
.intensity-legal
    padding: 1rem
    border: 2px solid $Green
    background: $Grey1
    color: $Grey5

.ans-vs-time
    display: block
    .apexcharts-xaxis-texts-g .apexcharts-xaxis-label:nth-child(n):nth-last-child(2n+2)
        display: none
    .ans-graph
        margin-bottom: 1rem
        display: flex
        justify-content: center

        
.dist-vs-intensity
    .apexcharts-xaxis-texts-g .apexcharts-xaxis-label:nth-child(3n):nth-last-child(2n-5)
        display: none
    display: block
    .dist-graph
        margin-bottom: 1rem
        display: flex
        justify-content: center
@media screen and (max-device-width: 375px) 

    .main-content-cdi
        .map-table-cdi
            display: grid
            grid-template-columns: 100%
            grid-template-rows: auto
            grid-template-areas: "map-cdi" "intensity-table"
            grid-gap: .5rem
        .intensity-table
         overflow-x: scroll
         margin-bottom: .5rem
         .dates-icons
            width: 25px
            height: 25px
    .cdi-tables 
        display: block
        margin-bottom: .5rem
    .intensity-legal
        padding: .5rem

    .table-reports-info
        padding: .5rem
  
    .ans-vs-time 
        .ans-graph
            margin-bottom: .5rem
            overflow-x: scroll
            word-wrap: break-word
  
    .dist-vs-intensity 
        .dist-graph
            margin-bottom: .5rem
            overflow-x: scroll
@media screen and (min-device-width: 376px) and (max-device-width: 767px) 

    .main-content-cdi
        .map-table-cdi
            display: grid
            grid-template-columns: 100%
            grid-template-rows: auto
            grid-template-areas: "map-cdi" "intensity-table"
            grid-gap: .5rem

    .cdi-tables 
        display: block
        margin-bottom: .5rem
  
    .intensity-table
        overflow-x: scroll
        margin-bottom: .5rem
        .dates-icons
         width: 30px
         height: 30px
  
    .ans-vs-time 
        .ans-graph
            margin-bottom: .5rem
            overflow-x: scroll
  
  
    .dist-vs-intensity 
        .dist-graph
            margin-bottom: .5rem
            overflow-x: scroll
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) 

    .main-content-cdi
        .map-table-cdi
            display: grid
            grid-template-columns: 100%
            grid-template-rows: auto
            grid-template-areas: "map-cdi" "intensity-table"
            grid-gap: .5rem


    .cdi-tables 
        display: block
        margin-bottom: 1rem
  
    .intensity-table
        overflow-x: scroll
        margin-bottom: 1rem
  
    .intensity-legal
        padding: 1rem
  
  
    .ans-vs-time 
        .ans-graph
            margin-bottom: 1rem
            overflow-x: scroll
  
    .dist-vs-intensity 
        .dist-graph
            margin-bottom: 1rem
            overflow-x: scroll
  




