.VDivSection
    position: absolute
    bottom: 50px
    height: max-content !important
    width: 100%
    .VNewsPfd
        padding: 10px 0
        overflow: initial !important
        img
            width: 30px
    .hidden_legend_volcano
        height: 170px
    .show_legend_volcano
        height: 400px !important
    #div-content-new
        background: $Black
        opacity: .7
        position: relative
        transition-duration: .4s
        .VNewsContainer
            height: 100% !important
            display: flex
            align-items: center
            padding: 0 20px
            .VLogoNews
                background: url(../assets/icons/Reusable/logoWhite.svg)
                height: 100% !important
                width: 50px
                background-position: center right
                background-repeat: no-repeat
                background-size: 200%
                margin-right: 20px
                position: relative
                &::before
                    content: ''
                    position: absolute
                    left: 0
                    height: 100%
                    border: 1px solid $White
                &::after
                    content: ''
                    position: absolute
                    height: 100%
                    right: 0
                    border: 1px solid $White
            div
                height: 100%
                overflow-y: auto
            .VNewsContent
                .VNewsDescription
                    display: grid
                    height: max-content
                    .VNewsDate
                        width: max-content
                        font-size: 14px
                        position: relative
                        &::before
                            content: ''
                            position: absolute
                            width: 100%
                            bottom: 0
                            border: 1px solid $White
                span, h2
                    padding: 5px 0
                    color: $White
                
        .VArrow
            position: absolute
            top: 5px
            width: 100%
            display: flex
            justify-content: center
            height: 30px
            div
                width: 20px
                height: 100%
                cursor: pointer
                img
                    width: 20px
                    height: 100%
