.volcano_map
    margin-top: 20px
    h6
        color: $Green
    #VolcanoMap
        height: 400px
.volcan3D_model
    padding: 20px 0 15px
    h6
        color: $Green
    #volcan3D_img
        height: 400px
        width: 100%
        overflow-y: hidden