.crrBtnBack, .crrBtnNext
  width: 30px
  border: 1px solid transparent
  font-size: 70px
  height: 100px
  cursor: pointer
.crrBtnBack
  background: url(../assets/icons/Home/slider_back.svg)
  background-repeat: no-repeat
.crrBtnNext
  background: url(../assets/icons/Home/slider_front.svg)
  background-repeat: no-repeat
.crrBtnBack, .crrBtnNext
    top: calc(50% - 15px)