.apexcharts-toolbar
	margin: 1.3rem 0rem


#highcharts 
	.highcharts-root
		font-family: inherit !important
		.highcharts-title
			color: $Green !important
			fill: $Green !important
	& > div
		margin: 1rem 0rem

.SmContainer
	border-radius: 8px
	overflow: hidden
	margin: 20px 0
	display: flex
	border-bottom: 2px solid $Green
	border-top: 2px solid $Green
	border-right: 2px solid $Green
	border-left: 2px solid $Green
	.SmColumns
		display: grid
		width: 100%
		span
			display: inline-block
			padding: 5px 10px
			text-wrap: nowrap
			border-left: 1px solid $Green
			border-bottom: 1px solid $Green
			text-align: center
			align-content: center
			&:last-child
				border-bottom: none
		&:nth-child(odd)
			background-color: $Grey1
		&:first-child
			background-color: $Green
			color: white
			span:first-child
				border-bottom: 1px solid white
         

@media ( max-width: 950px )
	.SmContainer
		margin: 20px 0
		display: grid
		.SmColumns
			width: 100%
			display: flex
			span
				width: 100%
				text-wrap: wrap
				&:last-child
					border-bottom: 1px solid $Green
				&:first-child
					border-left: none
			&:last-child
				span
					border-bottom: none
			&:first-child
				span
					&:first-child
						border-bottom: none
					&:last-child
						border-left: 1px solid white

.descripcion-sm
	ul
		padding: 0rem 2rem
		margin-bottom: 1rem

		table
			margin: .5rem
			border: 2px solid Green

			td
				border: 1px solid Green
	p
		margin-bottom: .5rem

#interactive_map
	width: 100%
	height: 840px
	position: relative

#interactiveMapLegendContainer
	position: absolute
	display: flex
	flex-direction: row
	justify-content: center
	align-content: center
	align-items: center
	width: 100%
	bottom: 0
	margin-bottom: 30px








