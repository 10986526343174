.EQPopup
   margin: 0px
   padding: 0px
   border-radius: 10px
   overflow: hidden
   min-width: 180px
   color: $GrayText
   background-color: $LightGray
   font-size: 11px
   .EQPopupHeader
      display: flex
      flex-direction: column
      justify-content: center
      background-color: $Green
      color: $White
      padding: 10px 15px
      .EQTitle
         font-size: 16px
         font-weight: bold
      .EQTimeContainer
         display: flex
         flex-direction: row
         p
            text-wrap: nowrap
         .EQTimeText
            margin-left: 5px
   .EQPopupContent
      padding: 10px 15px
      line-height: normal

      .EQInfoTable
         display: table
         width: 100%
         margin-bottom: 10px
         border: 1px solid $White
      .EQInfoCell
         display: table-cell
         border: 1px solid $White

         .CellTitleContainer
            height: 25px
            display: flex
            flex-direction: column
            justify-content: center
            border-bottom: 2px solid
            border-color: $White
            p.CellTitle
               color: $Green
               text-align: center
               font-size: 10px
               line-height: 9px
               font-weight: bold

         .CellDataContainer
            padding: 7px 0
            .CellDataCircle
               border: 1.5px solid $DarkGray
               padding: 2px
               border-radius: 50%
               height: 57px
               width: 57px
               margin: 0 auto
               display: flex
               flex-direction: column
               justify-content: center
               .DataValue
                  font-size: 16px
                  text-align: center
                  font-weight: bold
                  &.Small
                     font-size: 11px
                     padding-top: 2px
                     line-height: 11px
                  &.DepthText
                     font-size: 9.5px

               .DataMeasure
                  font-size: 9px
                  text-align: center
                  &.Small
                     font-size: 8px
                     line-height: 8px
      
      .EQLocationData
         display: flex
         flex-direction: column
         justify-content: center
         .EQLocationText
            padding-top: 7px


   .EQButtons
      display: flex
      justify-content: center
      padding: 5px 5px 15px
      .InfoButton
         color: $White !important
         line-height: 1.5
         background-color: $Green
         border: 1px solid $Green
         border-radius: 8px
         width: 130px
         height: 100%
         font-weight: bold
         font-size: 10px
         padding: 7px 2px
         margin: 4px 3px 0
         text-align: center
         cursor: pointer
         text-decoration: none
         &:hover
            background-color: $White
            color: $Green !important

.MapPopup
   font-size: 13px // leaflet-popup default font-size is 12px
   font-family: 'Nunito Sans', sans-serif
   font-weight: 300
   min-width: 250px
   .PopupHeader
      font-size: 14px
      background-color: $Green
      color: $White
      padding: 15px 18px
      .PopupTitleContainer
         margin-bottom: 5px
      .PopupTitle
         font-size: 16px
         line-height: 1.2
   .PopupInfoGrid
      &>div
         display: grid
         grid-template-columns: 80px auto
         border-bottom: 1.5px solid white
         padding: 7px 0
         &:last-child
            border-bottom: none
         span
            align-content: center
      
   .EQButtons
      .InfoButton
         font-size: 12px
         width: 50%
