/**_ModalImg*

.ModalImg
  width: 100%
  height: 100%
  position: fixed
  background: $ModalGrey
  z-index: 1001
  opacity: 0
  visibility: hidden

  .ModalControllers
    position: relative
    height: 100%
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center

    .ModalClose
      position: absolute
      top: 0
      right: 0
      cursor: pointer
      padding: 20px

      img
        width: 20px

    .PrevVisibleModal
      -webkit-transition: all 0.7s
      -o-transition: all 0.7s
      transition: all 0.7s
      position: absolute
      visibility: visible
      cursor: pointer
      opacity: 1
      top: 50%
      left: 0
      padding-left: 20px

      img
        width: 50px

    .PrevInvisibleModal
      position: absolute
      opacity: 0
      visibility: hidden
      cursor: pointer
      top: 50%
      left: 0
      padding-left: 20px

      img
        width: 50px

    .NextArrow
      position: absolute
      cursor: pointer
      right: 0
      top: 50%
      padding-right: 20px

      img
        width: 50px

    .ModalImgContainer
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: flex-start
      -ms-flex-align: flex-start
      align-items: flex-start
      background: $White
      padding: 20px
      margin: auto
      overflow-y: auto
      overflow-x: hidden
      width: 50%
      height: 90%

      .ImgContainer
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        -webkit-box-pack: start
        -ms-flex-pack: start
        justify-content: flex-start
        -webkit-box-align: flex-start
        -ms-flex-align: flex-start
        align-items: flex-start
        margin: auto

        img
          width: 100%
          -ms-flex-negative: 0
          flex-shrink: 0

/**_ModalImg*