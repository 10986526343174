.basic-info-volcanos
	.table-info
		div
			&:nth-child(odd)
				span
					&:nth-child(odd)
						background: $Grey1

.level_volcano
	display: flex
	justify-content: center
/**.High_contrast
				
.EQContentDetail
	#show_location
		position: absolute
		bottom: 10px
		left: 40%
		z-index: 1000
		background: $SoftGrey1
		opacity: .6
		padding: 0 10px
		&:hover
			opacity: 1
		span
			font-family: "Roboto", "Helvetica", "Arial", sans-serif
			letter-spacing: 0.00938em
	.VContentManager
		margin: 20px 30px
		padding: 16px
		@media ( max-width: $Small )
			margin: 4vw      
		.VTopSlider
			img
				height: 400px
				object-fit: cover
				border-radius: 8px
		.carousel-slider
			height: 400px
			.slide
				.carousel-news
					width: 100%
					height: 450px
					img
						width: 100%
						height: 100%
						object-fit: cover
	.EQSummary
		margin-bottom: 0 !important

	h6	
		font-size: 1.2rem !important
		color: $Green
		font-size: 13pt
		font-weight: 600
		margin: .5rem 0rem

	.slider-images-volcanos
		background: $White
		padding: 1rem
		text-align: center
		

	.basic-info-volcanos
		padding: 15px 0
		.tables-basic-info
			display: grid
			background-color: white
			border: 1px solid white
			grid-template-columns: 50% 50%
			grid-template-rows: auto auto
			
			.table_info
				width: 100%
				div
					display: grid
					grid-template-columns: 50% 50%
					margin-bottom: 1px
					min-height: 50px
					height: max-content
					overflow-y: hidden
					span
						display: flex
						align-items: center
						padding: 0 10px
					&:nth-child(odd)
						background: $Grey1		
				div.level_volcano
					min-height: 0
					display: flex
					height: 100%
					justify-content: flex-start
					padding: 0 7px

			.item-table-basic-info
				background: $Green
				color: $White
				font-weight: 600

			.item-table-grey
				background: $Grey1
				color: $Black
	.localization-volcanos
		margin: 20px 0
		p
			text-align: justify
	.generalities-volcanos
		margin: 20px 0
		p
			text-align: justify

	.see-more-volcan
		display: flex
		justify-self: center
		justify-content: center
		height: max-content
		min-height: 50px
		margin: 2rem 0 1rem
		button
			border-radius: 8px
			text-decoration: none
			height: 100%
			background: $Green
			color: $White
			border: 1px solid $Green
			font-weight: bold
			padding: 0 15px
			
			&:hover
				cursor: pointer
				background: $White
				color: $Green
				border: 1px solid $Green

	.news-volcanos
		color: $Black
		.carousel
			.slide
				background: $White
				// border: 2px solid $Green


	.newsletters-volcanos
		position: relative
		tbody
			td
				&:nth-child(1)
					@media( max-width: $Small )
						min-width: 60vw
					div
						text-align: left

				
	.carousel-cards-news
		padding: 10px 0
		display: grid
		grid-template-areas: "carousel-news-container cards-news-container"
		grid-template-rows: max-content
		grid-template-columns: 65% 35%
		
		.carousel-news-container
			position: relative
			// -webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,1)
			// -moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,1)
			// box-shadow: 0px 0px 5px 2px rgba(0,0,0,1)

			.carousel
				.carousel-status
					display: none
				.slide
		
					text-align: left
				.control-dots
					bottom: 10px
		
@media screen and ( max-width: 800px )
	.EQContentDetail
		.carousel-cards-news
			display: block !important
		.cards-news
			padding: .5rem 0
		.control-dots
			bottom: 30px

@media ( max-width: 630px )
	.tables-basic-info
		grid-template-areas: none !important
		grid-template-columns: 100% !important
		grid-auto-rows: auto
	.hidden_legend_volcano
		height: 220px !important
	.VTopSlider
		.carousel-slider
			height: 100% !important

	
@media screen and ( max-width: 411px )
	.EQContentDetail
		.slider-images-volcanos
			background: $White
			padding: 1rem
			text-align: center
			display: flex
			align-items: center
			img
				width: 100%

.High_contrast
	.level_volcano
		background: $White
	.volcano_latitude
		background: $White
		height: max-content
@media ( max-width: 600px )
	.EQContentDetail .basic-info-volcanos .tables-basic-info .table_info div span
		padding: 20px 10px
	
