.normal_font
    font-size: 14px
    .size_standard
        font-size: 14px
        a, span
            font-size: 14px
    .size_services
        h2
            font-size: 0.95em
        span
            font-size: 1.1em
    .size_social
        h3
            font-size: 1.2rem
        p
            font-size: initial
        a
            font-size: initial
        span
            font-size: initial
        .civil
            font-size: 1.1rem
    .size_position
        span
            font-size: 1rem
        p
            font-size: 1rem
        a
            font-size: initial
.high_font
    font-size: 18px
    .size_standard
        font-size: 18px
        a, span
            font-size: 18px
    .size_services
        h2
            font-size: 12px
        span
            font-size: 1.2em
    .size_social
        h3
            font-size: 1.5rem
        p
            font-size: 18px
        a
            font-size: 18px
        span
            font-size: 18px
        .civil
            font-size: 1.5rem
    .size_position
        span
            font-size: 1.3rem
        p
            font-size: 1.1rem
        a
            font-size: 18px
    