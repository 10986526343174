/**EQmmiTabs*

.EQintensity
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

  .container-img
    height: auto
    width: auto
    min-height: 200px
    padding: 25px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
    -moz-box-shadow: -7px 10px 14px rgba(235,235,235,1)
    box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)

    img
      width: 90%

.EQpga, .EQpgv
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center
  -webkit-box-align: center
  -ms-flex-align: center
  align-items: center

  .container-img
    height: auto
    width: auto
    padding: 25px
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    -webkit-box-pack: center
    -ms-flex-pack: center
    justify-content: center
    -webkit-box-align: center
    -ms-flex-align: center
    align-items: center
    -webkit-box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
    -moz-box-shadow: -7px 10px 14px rgba(235,235,235,1)
    box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)

    img
      width: 100%

@media (max-width: 767px)
  .EQintensity .container-img, .EQpga .container-img, .EQpgv .container-img
    height: auto
    width: auto

@media (min-width: 768px) and (max-width: 1250px)
  .EQintensity .container-img, .EQpga .container-img, .EQpgv .container-img
    height: auto
    width: auto

.EQDownloads
  width: 100%
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center

  .EQDownloads-main
    text-align: center
    display: -ms-grid
    display: grid
    -ms-grid-columns: 30% 2rem 30% 2rem 30%
    grid-template-columns: 30% 30% 30%
    -ms-grid-rows: auto
    grid-template-rows: auto
    grid-gap: 2rem

    > *
      &:nth-child(1)
        -ms-grid-row: 1
        -ms-grid-column: 1

      &:nth-child(2)
        -ms-grid-row: 1
        -ms-grid-column: 3

      &:nth-child(3)
        -ms-grid-row: 1
        -ms-grid-column: 5

    .DownloadMmi
      padding: 1rem 1rem
      border-radius: 8px
      background: $Grey1
      min-width: 200px
      height: 100%
      display: -ms-grid
      display: grid
      -ms-grid-columns: 100%
      grid-template-columns: 100%
      -ms-grid-rows: 20% 1rem 80%
      grid-template-rows: 20% 80%
      grid-template-areas: "tittle-option" "DownloadMmi_content"
      grid-gap: 1rem

      .tittle-option
        width: 100%
        height: 100%
        grid-area: tittle-option
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        -webkit-box-align: center
        -ms-flex-align: center
        align-items: center
        -webkit-box-pack: center
        -ms-flex-pack: center
        justify-content: center
        border-bottom: 2px solid $White
        font-size: 10pt

        h2
          color: $Green
          -ms-flex-line-pack: center
          align-content: center
          -webkit-box-pack: center
          -ms-flex-pack: center
          justify-content: center

      .DownloadMmi_content
        grid-area: DownloadMmi_content
        border-radius: 8px
        width: 100%
        border: 2px solid $Green
        font-size: .75rem
        background: $White
        padding: 24px 16px
        margin-bottom: 21px

        .container-content
          width: 100%
          height: 100%

          .container-option
            min-height: 24px
            width: 100%
            margin-bottom: 20px
            &:last-child
               margin-bottom: 0

            a
              text-decoration: none

              .btn_href
                text-align: center
                border-radius: 6px
                overflow: auto
                background: $Green
                color: $White
                padding: 7px

@media (max-width: 720px)
  .EQDownloads .EQDownloads-main
    -ms-grid-columns: auto
    grid-template-columns: auto
    -ms-grid-rows: auto 2rem auto
    grid-template-rows: auto auto

    > *
      &:nth-child(1)
        -ms-grid-row: 1
        -ms-grid-column: 1

      &:nth-child(2)
        -ms-grid-row: 3
        -ms-grid-column: 1