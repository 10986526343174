hr.Divider
   margin: 0px
   flex-shrink: 0
   border-width: 0px 0px thin
   border-style: solid
   border-color: rgba(102, 127, 0, 0.2)

hr.Divider.DividerWhite
   border-color: rgba(255, 255, 255, 0.701)
   margin: 8px 0px

span.CellDivider
   display: block
   margin-left: 1px solid white

.animated-marker
   border-radius: 50%
   border: 0.2px solid white
   pointer-events: none !important

.relevant-marker
   z-index: 999 !important    
   pointer-events: all !important
   cursor: pointer
   // &::after
   //    content: ""
   //    border-radius: 50%
   //    height: 100%
   //    width: 100%
   //    position: absolute
   //    top: 0
   //    left: 0
   //    animation: pulsate 1.5s ease-out
   //    animation-iteration-count: infinite
   //    opacity: 0.0
   //    box-shadow: 0 0 1px 2px red                               
   //    animation-delay: 1.2s
   .relevant-marker-text
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center
      font: lighter 17px sans-serif 
      color: white
   .marker-pulsate
      border-radius: 50%
      height: 100%
      width: 100%
      position: absolute
      top: 0
      left: 0
      animation: pulsate 2s ease-out
      animation-iteration-count: infinite
      opacity: 0.0
      box-shadow: 0 0 1px 2px red                               
      animation-delay: .5s

.relevant-marker.blue
   &::after
      box-shadow: 0 0 2px 3px #0000FF 
.relevant-marker.red
   &::after
      box-shadow: 0 0 2px 3px #FF0000
.relevant-marker.green
   &::after
      box-shadow: 0 0 2px 3px #FF0000
.relevant-marker.yellow
   &::after
      box-shadow: 0 0 2px 3px #FF0000
.relevant-marker.purple
   &::after
      box-shadow: 0 0 1px 2px #FF0000

@keyframes fade 
   from
      filter: brightness(3)
      width: 40px
      height: 40px

@keyframes pulsate
   0%
      transform: scale(1.4, 1.4)
   50%
      opacity: 1
   100%
      transform: scale(1, 1)
      opacity: 0
 
@keyframes fade-small
   from 
      filter: brightness(3)
      width: 20px
      height: 20px

.blinking
   animation-name: fade
   animation-duration: 1.2s
   animation-iteration-count: 1
   animation-direction: alternate

.blinking-small 
   animation-name: fade-small
   animation-duration: .7s
   animation-iteration-count: 1

.seismicity-popup
   margin-bottom: 25px
   .leaflet-popup-content-wrapper
      border-radius: 5px !important
      .leaflet-popup-content
         padding: .7rem .8rem
         color: rgba(0, 0, 0, 0.87)
         font-size: 11px
         span.title
            font-weight: 800
            // line-height: 20px
            display: block
            margin-bottom: 5px
            transform: scale(1, 1.1)
         span
            font-weight: 600
            // letter-spacing: .04rem

.seismicity-panel-info
   position: fixed
   bottom: 15px
   left: 25px
   z-index: 1
   width: 600px
   @media ( max-width: 1600px )
      width: 450px
   @media ( max-width: 600px )
      left: 15px
      width: calc( 100% - 30px )

.seismicity-table
   scrollbar-color: $Green $Grey1
   scrollbar-width: thin
   &::-webkit-scrollbar
         width: 5px
   &::-webkit-scrollbar-track
         background: $Grey1
   &::-webkit-scrollbar-thumb
         background: $Green

.seismicity-header
   padding: 7px 10px 7px 6px
   display: flex
   img
      height: 30px
      @media (max-width: 600px) 
         height: 25px
   .seismicity-counter
      font-weight: bold
      display: flex
      align-items: center
      color: $Green
      font-size: 0.9rem
      margin-left: 4px
      @media (max-width: 600px) 
         font-size: 0.8rem

#MiniMapLeft, #MiniMapRight
   z-index: 1000
   width: 50%
   height: 150px
   position: relative
   @media (max-width: 800px)
      height: 130px
   .leaflet-bottom
      display: none
#MiniMapLeft
   border-right: 1px solid gray

.SeismicityMiniMapContainer
   position: absolute
   top: 10px
   right: 56px
   z-index: 3
   @media (max-width: 800px) 
      top: 60px
      left: 12px
      right: auto
   .MuiAccordionSummary-content
      margin: 10px 2px !important 

.SeismicityMiniMapContainer, .MiniMapContainer, .MiniMapOptions
   .MiniMapTitle 
      color: $Green
      font-size: .9rem 
      padding-right: 4px
      font-weight: bold
      @media (max-width: 800px)
         font-size: .7rem
.MiniMapOptions
   .MuiPaper-elevation
      // margin-top: 3px
      margin-left: -10px
   .MiniMapTitle
      width: 220px
      text-align: center