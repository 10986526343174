@font-face 
    font-family: "Oxigen"
    src: local("Oxigen"), url(../assets/fonts/Oxygen-Regular.ttf) format("truetype")

@font-face 
    font-family: "Oxigen-Bold"
    font-weight: 900
    src: local("Oxigen"), url(../assets/fonts/Oxygen-Bold.ttf) format("opentype")

@font-face 
    font-family: "PTSans"
    src: local("PTSans"), url(../assets/fonts/PTSans-Regular.ttf) format("truetype")

@font-face 
    font-family: "PTSans-Bold"
    font-weight: 900
    src: local("PTSans"), url(../assets/fonts/PTSans-Bold.ttf) format("opentype")

@font-face 
    font-family: "Roboto"
    font-weight: 500
    src: local("Roboto"), url(../assets/fonts/Roboto-Regular.ttf) format("opentype")

@font-face
    font-family: "MontserratBlack"
    src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Black.ttf) format("opentype")

@font-face
    font-family: "MontserratLight"
    src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Light.ttf) format("opentype")

@font-face 
    font-family: "MontserratRegular"
    src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Regular.ttf) format("opentype")

@font-face 
    font-family: "MontserratMedium"
    src: local("Monserrat"), url(../assets/fonts/Montserrat/Montserrat-Medium.ttf) format("opentype")

@font-face 
    font-family: "Work_sansBlack"
    src: local("Work_sans"), url(../assets/fonts/Work_sans/WorkSans-Black.otf) format("opentype")
    
@font-face 
    font-family: "Work_sansBold"
    src: local("Work_sans"), url(../assets/fonts/Work_sans/WorkSans-Bold.otf) format("opentype")

// @font-face 
//     font-family: "Roboto-Bold"
//     font-weight: 900
//     src: local("Roboto"), url(../assets/fonts/Roboto-Bold.ttf) format("opentype")

@import url('https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap')