.HmTopic
   overflow: hidden

.HmTopicContainer
   overflow: hidden
   position: relative
   img.HmTopicBg
      width: 100%
      transform: scale(1.03)
      filter: brightness(100%)
      transition: 0.3s ease
      object-fit: cover
      max-height: 530px
   &:hover
      img.HmTopicBg
         transform: scale(1)
         filter: brightness(98%)
         transition: 0.3s ease
   .HmTopicContent
      position: absolute
      top: 0px
      height: 100%
      width: 100%
      padding: 7vw
      padding-bottom: 5vw
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      flex-direction: column
      -ms-flex-direction: column
      align-items: center
      justify-content: center

      .HmTopicTitle
         display: flex
         flex-direction: column
         color: white
         font-size: 3.5rem
         text-transform: uppercase
         text-align: center
         justify-content: center
         align-items: center
         font-weight: 900
         margin-bottom: 5rem

   @media ( max-width: $Medium )
      .HmTopicContent
         .HmTopicTitle
            margin-bottom: 4rem
            font-size: 3rem
   @media ( max-width: $Small )
      img.HmTopicBg
         min-height: 250px
      .HmTopicContent
         .HmTopicTitle
            margin-bottom: 2.5rem
            font-size: 2.1rem
   @media ( max-width: $XSmall )
      img.HmTopicBg
         min-height: 50vw
      .HmTopicContent
         .HmTopicTitle
            margin-bottom: 5vw
            font-size: 6vw