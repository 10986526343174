.container-table-consultation
    padding: 1rem 2rem
    .MuiAccordionSummary-content.Mui-expanded
        margin: 10px 0 !important
        p
            font-size: 10pt !important
    .CatalogTotalDisabled
        color: $Grey19
    .CatalogTotalEvents
        color: $Grey23

    .MuiCircularProgress-colorSecondary
        color: $Green !important

    .MuiAccordionDetails-root
        border: none
        border-bottom: 1px solid $Green

    .MuiAccordionSummary-root.Mui-expanded
        border-bottom: none

    .MuiAccordionSummary-root
        border-bottom: 2px solid $Green

  
    .buttonMap
        display: flex
        align-items: center
        margin-top: 1rem
        p
            color: $Grey6
        #QTablebutton_map
            background-color: $Green
            text-align: center
            display: flex
            align-items: center
            justify-content: center
            padding: 15px 5px
            color: $White
            text-decoration: none
            cursor: pointer
            margin-left: 20px
        #QLoading
            height: 50px
        #return
            background-color: $Green
            color: $White
            font-weight: bold
            display: flex
            justify-content: center
            align-items: center
            text-decoration: none
            margin-left: 10px
            padding: 5px 10px
        .container-loading
            text-align: center
            .MuiCircularProgress-colorPrimary
                color: $Green

    h3
        margin: 0.5rem 0rem
        color: $Green
        span
            color: $Grey23
            padding-left: 1rem
            font-size: 9pt

    h4
        color: $Grey19
        margin-top: 2rem

    .accordion-table-consultation
        margin: 0.5rem 0rem

    .info-accordion
        display: block
        color: $Grey6

        .title-accordion
            margin-bottom: 1rem

    .filter-inputs
        padding-top: 1rem
        display: flex
        align-items: center
        .form-control
            height: 37px
            width: 270px
            margin-right: 1rem
            margin-top: 0rem

        .text-field
            height: 37px
            .MuiInputLabel-outlined 
                transform: translate(14px, 14px) scale(1)
            .MuiInputLabel-shrink
                transform: translate(14px, -6px) scale(1)

            .MuiOutlinedInput-root
                height: 37px

                .MuiOutlinedInput-input
                    padding: 0.5rem

    .MUIDataTable-Catalogador
        table
            th
                text-align: center

        .MuiTablePagination-caption
            display: none

@media screen and (max-width: 768px)
    .container-table-consultation
        h3
            font-size: 10pt
        .filter-inputs
            .form-control
                width: 100%

            .text-field
                width: 100%


        .buttonMap
            display: grid
            grid-template-columns: 100%
            grid-template-rows: auto
            margin-top: 1rem
            grid-gap: 1rem
            #QTablebutton_map
                margin-left: 0 !important

.disabledOrder
    .MuiSelect-selectMenu
        opacity: .5 !important

.queryModalContent
   .QBodyAvailable
      .MuiTableCell-root.MuiTableCell-head
         padding: 5px
         .MuiButtonBase-root.MuiButton-root
            margin-right: 0
            align-items: center
         span, div
            font-size: 11px
            line-height: 1.4
            text-align: center
      .MuiTableCell-root.MuiTableCell-body
         div
            font-size: 10px