.notifications
    position: absolute
    z-index: 2
    background: #ececec
    color: #33362D
    max-width: 360px
    min-width: 360px
    max-height: 80vh
    min-height: 100px
    right: .6rem
    padding: .5rem
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
    &::-webkit-scrollbar
        width: 10px
    &::-webkit-scrollbar-track
        background: $Grey1
    &::-webkit-scrollbar-thumb
        background: $Green
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75)
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75)
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75)

@media ( max-width: 400px )
    .notifications
        min-width: 100% !important
        max-width: 100% !important
        right: 0 !important
    