.HMservices
   overflow: hidden
   position: relative
   .HmServicesContainer
      background-color: gray
   img.HmServicesBg
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover
      
   .HmServicesContent
      padding: 6vw 0
      margin: 0 auto
      width: 80%
      max-width: $HzMaxWidth 
      @media ( min-width: $Small + 1px ) and ( max-width: $Medium )
         width: 90% 
      @media ( max-width: $XSmall )
         width: 90%
.CardGrid
   display: grid
   grid-template-columns: repeat(4, minmax(120px, 1fr))
   gap: 20px
   align-items: center
   grid-auto-rows: minmax(100px, auto)

.SgcCard
   background-color: rgba(0, 0, 0, 0.5)
   border-radius: 10px
   height: 17vw
   min-height: 200px
   max-height: 280px
   position: relative
   overflow: hidden
   a
      text-decoration: none
      &:hover
         text-decoration: none
   .SgcCardContent
      position: relative
      height: 100%
      transition: .4s ease
      border-top-left-radius: 10px
      border-top-right-radius: 10px
      padding: 12px 20px 20px
      display: flex
      flex-direction: column
   .SgcCardImg
      display: flex
      justify-content: center
      height: 100%
      img
         margin: 0 2.2vw
         max-height: 200px
   .SgcCardTitle
      height: 100%
      display: flex
      align-items: center
      justify-content: center
      color: $SGC
      text-align: center
      font-size: 1.2rem
      line-height: 1rem
      transition: transform .5s linear
   .SgcCardText
      font-size: .8rem
      text-align: center
      position: absolute
      line-height: 120%
      width: 100%
      color: black
      bottom: -50%
      background-color: white
      left: 0
      transition: .5s ease
      padding: 15px

   &:hover
      background-color: $SGC
      cursor: pointer
      .SgcCardContent
         height: 80%
         transition: .5s ease
      .SgcCardImg
         height: 80%
      .SgcCardTitle
         color: white
         height: unset
         transform: scale(90%)
         transition: .5s ease
      .SgcCardText
         border-bottom-left-radius: 10px
         border-bottom-right-radius: 10px
         bottom: 0
         transition: .5s ease

@media ( max-width: $Large )
   .SgcCard
      min-height: 150px
      .SgcCardImg img
         max-height: 12vw
      .SgcCardTitle
         font-size: 1.2vw
      .SgcCardText
         font-size: .8vw

@media ( max-width: $Medium )
   .SgcCard
      height: 17vw
      min-height: 130px
      .SgcCardImg
         img
            margin: 0 3vw
      .SgcCardContent
         padding: 12px 10px 15px
      .SgcCardTitle
         font-size: 1.2vw
      .SgcCardText
         font-size: .8vw
         padding: 1.2vw
      &:hover
         .SgcCardContent
            height: 78%

@media ( max-width: $Small ) 
   .CardGrid    
      grid-template-columns: repeat(2, minmax(120px, 1fr))
      gap: 4vw
   .SgcCard
      height: 38vw
      max-height: none
      .SgcCardImg img
         max-height: 24vw
      .SgcCardContent
         padding-bottom: 3vw
         padding-top: 3vw
      .SgcCardTitle
         font-size: 2.4vw
      .SgcCardText
         padding: 2.2vw
         font-size: 1.7vw
      &:hover
         .SgcCardContent
            height: 80%

@media ( max-width: $XSmall ) 
   .SgcCard
      height: 40vw
      min-height: 120px
      .SgcCardImg
         img
            margin: 0 
            max-height: 25vw
      .SgcCardContent
         padding: 3vw
      .SgcCardTitle
         font-size: 2.7vw
      .SgcCardText
         padding: 3vw
      &:hover
         .SgcCardContent
            height: 75%
            