.ExpansionPanel
   .ExpansionPanelPaper
      background-color: $Grey1
      box-shadow: none
      border: 2.5px solid $Grey1
      .ExpansionPanelSummary
         color: $Green
         font-weight: bold
         span
            display: inline-block
         .MuiAccordionSummary-content
            margin-right: 5px
         .ExpandMoreIcon
            color: $Green
      .ExpansionPanelDetails
         background-color: white
   
.BasicTable
   max-height: 450px
   th
      color: $White
      background: $Green
      font-weight: bold
      border-right: 1px solid $White
      text-align: center
      line-height: 1.2rem
      height: 40px
   tr:nth-child(even)
      background: $Grey1
   td
      border-right: 1px solid $Green
      text-align: center
      height: 35px
      &:last-of-type
         border-right: none
   td, th
      padding: 7px 12px
      font-size: inherit
      font-family: inherit
   .MuiTableCell-sizeMedium
      padding: 16px

   tbody.BodyLeft
      td
         text-align: left
   
   &.ResponsiveTable
      tbody
         display: flex
         tr
            &:nth-child(even)
               background: transparent
            display: flex
            flex-direction: column
            th, td
               display: flex
               flex-grow: 1
               height: unset
            th
               border-bottom: 1px solid white
            td
               border-right: none
               &:nth-child(even)
                  background: $Grey1

.TabsBar
   box-shadow: none !important
   .TabsGroup
      background-color: $Grey1
      color: $DarkGray
      
      button, a
         color: inherit
         min-width: 130px
         border: 1px solid $DarkGray
         border-top-left-radius: 10px
         border-top-right-radius: 10px

         @media ( max-width: $Small )
            min-width: unset

      .MuiTabs-flexContainer
         gap: 4px
         &.centered
            justify-content: center
      .MuiTabs-indicator
         background-color: $Green
      .MuiTab-root.Mui-selected
         color: $Green
         font-weight: 700
         border-color: $Green
      .TabLink
         color: inherit
         font-family: inherit
         &:hover
            color: $Green
         .LinkBtn
            height: 100%
            text-transform: uppercase
            letter-spacing: 0.02857em

body
   .SgcContent
      .MuiInputBase-root
         font-size: inherit
         font-family: inherit
      .Mui-focused, label.Mui-focused
         color: $Green
         &.MuiOutlinedInput-root
            fieldset.MuiOutlinedInput-notchedOutline
               border-color: $Green

   .MuiPaper-root, .MuiInputBase-root, .MuiTypography-root, .MuiFormHelperText-root,
   label.MuiInputLabel-root, button.MuiButton-root, .MuiTab-root
      font-family: inherit
   .MuiFormHelperText-root
      font-size: .75rem

   .MuiAutocomplete-popper
      .MuiAutocomplete-listbox
         font-family: 'Nunito Sans', sans-serif

   .MuiPickersBasePicker-container
      .MuiPickersToolbar-toolbar
         background-color: $Green

      .MuiPickersDay-daySelected
         background-color: $Green
         &:hover
            background-color: $Green
            opacity: .9

   .MuiMenu-paper
      .MuiListItem-root
         font-family: 'Nunito Sans', sans-serif