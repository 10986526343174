/* HomeChat Button */
.HomeChat
  background: $SGC
  color: $White
  text-decoration: none
  cursor: pointer
  position: fixed
  bottom: 20px
  right: 30px
  z-index: 20
  border-radius: 5px
  padding: 10px .9rem
  transition: .25s ease
  @media ( max-width: $Small )
   font-size: .8rem
   padding: 8px 11px
   right: 5vw
   bottom: 5vw

  &:hover
   opacity: .9
   transform: scale(98%)

/* Accesibility Side Menu */
.HmSideMenu
  color: $White
  z-index: 40
  position: fixed
  -webkit-transition: 2s
  -o-transition: 2s
  transition: 2s
  right: 0
  top: 50%
  transform: translateY(-50%)
  cursor: pointer
  -webkit-transition: 0.5s
  -o-transition: 0.5s
  transition: 0.5s
  display: -ms-grid
  display: grid
  @media( max-width: $Medium )
   display: none

  .HmSideHref
    position: relative
    text-decoration: none
    color: $White
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    height: 38px
    width: auto
    -webkit-transition: all 2s
    -o-transition: all 2s
    transition: all 2s
    margin-bottom: 5px
    background: transparent

    &:hover
      width: 180px
      -webkit-transition: all 2s
      -o-transition: all 2s
      transition: all 2s

      div
        width: 100%
        -webkit-transition: all 0.5s
        -o-transition: all 0.5s
        transition: all 0.5s
        -webkit-box-pack: start
        -ms-flex-pack: start
        justify-content: flex-start

      span
        display: unset
        white-space: nowrap
        -webkit-transition: all 0.5s
        -o-transition: all 0.5s
        transition: all 0.5s
        padding-left: 10px

    div
      display: -webkit-box
      border-bottom-left-radius: 5px
      border-top-left-radius: 5px 
      display: -ms-flexbox
      display: flex
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      height: 100%
      width: 35px
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      position: absolute
      right: 0
      background: $SGC

    img
      -webkit-transition: 1s
      -o-transition: 1s
      transition: 1s
      padding: 2px 0 2px 3px
      max-height: 35px

    span
      display: none
      font-size: .84rem
      -webkit-transition: 0.5s
      -o-transition: 0.5s
      transition: 0.5s