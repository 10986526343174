

.fole-main
	display: grid
	grid-template-areas: "volcano-level-activity volcano-info-main"
	grid-template-columns: auto 75%
	grid-template-rows: auto
	padding: 1rem 0rem
	font-size: 10pt

	span
		font-weight: bold

	.volcano-level-activity
		grid-area: volcano-level-activity
		display: flex
		justify-content: center
		align-items: center
		border-right: 2px solid #E3E3E4

		img
			width: 50px
	
	.volcano-info-main
		grid-area: volcano-info-main
		padding: 0rem 1rem

		h3
			color: $Green
			font-weight: bold 

.item.active
	h3
		color: $White 

.fole-expanded
	display: grid
	grid-template-areas: "empty-space volcano-info-expanded"
	grid-template-columns: auto 75%
	grid-template-rows: auto
	font-size: 10pt

	.empty-space
		grid-area: empty-space
		border-right: 2px solid #E3E3E4
	
	.volcano-info-expanded
		color: white
		grid-area: volcano-info-expanded
		padding: 0rem 1rem

		.container-info-expanded
			margin-bottom: 1rem