

.close-popup
	display: flex
	justify-content: flex-end
	align-items: center
	background: $PGreen
	width: 500px

	.icon-close
		cursor: pointer

#interactiveMapContainer
	position: relative
	.VolcanoMapHid
		width: 100%
		height: 100%
		position: absolute
		.HidContent
			position: relative
			display: flex
			align-items: center
			justify-content: center
			z-index: 100
			width: 100%
			height: 100%
			.HidMask
				width: 100%
				height: 100%
				background: $White
				opacity: .5
			.HidControls
				display: grid
				justify-items: center
				align-items: center
				position: absolute
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
				p
					text-align: center
				button
					background: $CorpGreen
					color: $White
					border-color: transparent
					padding: 10px
					border-radius: 10px
					cursor: pointer
					transition-duration: .5s
					&:hover
						border-radius: 12px
						padding: 12px



@media screen and ( max-width:  539px)
	.close-popup
		display: flex
		justify-content: flex-end
		align-items: center
		background: $PGreen
		width: 300px
