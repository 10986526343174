/**Marker colors
.markerRed
    animation: markerRedAnimation 1s infinite
.markerYellow
    animation: markerYellowAnimation 1s infinite
.markerGreen
    animation: markerGreenAnimation 1s infinite
.markerBlue
    animation: markerBlueAnimation 1s infinite
.markerMagenta
    animation: markerMagentaAnimation 1s infinite
.activate_marker
    fill: $markerActivate
/**Marker colors animation
@keyframes markerRedAnimation
    0%
        fill: $markerRed
    100%
        fill: $markerActivate
@keyframes markerYellowAnimation
    0%
        fill: $markerYellow
    100%
        fill: $markerActivate
@keyframes markerGreenAnimation
    0%
        fill: $markerGreen
    100%
        fill: $markerActivate
@keyframes markerBlueAnimation
    0%
        fill: $markerBlue
    100%
        fill: $markerActivate
@keyframes markerMagentaAnimation
    0%
        fill: $markerMagenta
    100%
        fill: $markerActivate
/**Marker colors animation

        