// Updates to Mui-datatables v4   
.MuiTable-root
   th div
      color: $White

   .MuiTableCell-root.MuiTableCell-head
      .MuiButtonBase-root > div
         text-transform: none
   tfoot
      td.MuiTableCell-root.MuiTableCell-footer
         padding: 0

fieldset.MuiFormControl-root
   padding: 16px 24px 16px 24px !important   

.SgcMUIDataTable
   .MuiPaper-root
      color: inherit
   table
      td, th
         font: inherit
      thead
         th
            background-color: $Green
            border-right: 1px solid white
            text-align: center
            &:last-child
               border-right: none
            &>div, button
               line-height: 1.1rem
               font-weight: bold
               margin: 0
               padding: 4px 8px
            span
               justify-content: center
      tbody
         tr
            &:nth-child(even)
               background-color: $Grey1
         td
            text-align: center
            border-right: 1px solid $Green
            line-height: normal
            height: 45px
            padding: 7px 15px
            color: inherit
            &:last-child
               border-right: none
            img
               max-width: 40px
               cursor: pointer
               &.dates-icons
                  margin: 0 auto

         td.align-left
            text-align: left