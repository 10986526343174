.bannerQuery
    width: 100%
    height: 240px
    background-image: url("../assets/icons/Query/temp.jpg")
    background-size: cover
    background-position: center center
    position: relative
    .titleBanner
        position: absolute
        background-color: $Green
        color: $White
        font-weight: bold
        max-width: 350px
        padding: 0px 30px
        min-height: 40px
        bottom: -10px
        left: 12.5%
        display: flex
        align-items: center
        justify-content: center
        h3
            margin: 10px
            font-size: 17px
