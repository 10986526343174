/**EQtm.jsx*
.container-tmpTensor
  height: 100%
  width: 100%
  padding: 30px 20px 0
  display: -webkit-box
  display: -ms-flexbox
  display: flex
  -webkit-box-pack: center
  -ms-flex-pack: center
  justify-content: center


.EQTensorContainer
   width: 70%
   min-width: 600px
   max-width: 1000px

.EQTmItem
   margin-bottom: 25px
   .EQTmTitle
      text-align: center
      background-color: $Green
      color: white
      padding: 8px 12px
      &.Left
         text-align: left
   .EQTmContent
      display: flex
      gap: 15px
      .TmBeachball
         display: flex
         padding: 8px 8px 0px
         min-width: 216px // 200px img + 16 padding
         img
            object-fit: contain
            overflow: auto

.EQTmTable
   display: flex
   width: 100%
   overflow: auto
   table
      text-align: left
      td, th
         padding: 8px
      th
         background-color: $Grey1

      &.MainTmTable
         th
            width: 42%
      &.TmLastTable
         th
            width: 25%



@media ( max-width: $Small )
   .EQTensorContainer
      width: 100%    
      min-width: unset
   .EQTmItem
      .EQTmContent
         .TmBeachball
            min-width: 20vw

@media ( max-width: $XSmall+100px )
   .EQTmItem
      .EQTmContent
         display: block
         .TmBeachball
            padding: 20px
            justify-content: center
            min-width: unset
            img
               width: 170px

// -----

.EQTensor_container
  width: 100%
  height: 100%
  display: -ms-grid
  display: grid
  grid-template-areas: "fila1" "fila2"
  gap: 45px
  -ms-grid-columns: 100%
  grid-template-columns: 100%

  .container-table-tm
    -webkit-box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)
    -moz-box-shadow: -7px 10px 14px rgba(235,235,235,1)
    box-shadow: -7px 10px 14px -7px rgba(235,235,235,1)

    #EQTmTable
      border-bottom: .5px solid $Grey0
      box-shadow: -7px 10px 14px -7px #ebebeb
      overflow-x: auto
      table
        tbody
          display: flex
          width: 100%
        tr
          display: flex
          flex-direction: column
          width: 100%
          &:nth-child(even)
            background: $Grey2
          &:first-child
            min-width: 135px
          th
            background: $Green
            color: $White
            border: none
            border-right: 1px solid $White
            display: flex
            flex-grow: 1
            padding: 10px
            // height: 40px
            justify-content: center
            align-items: center
          td
            border-right: 1px solid $Green
            display: flex
            padding: 10px
            height: 80px
            justify-content: center
            align-items: center
          &:last-child
            td
               border-right: none

  .fila2
    .fila2-container
      display: -ms-grid
      display: grid
      grid-template-areas: "tablas bola"
      -ms-grid-columns: 55% 45%
      grid-template-columns: 55% 45%
      -ms-grid-rows: 100%
      grid-template-rows: 100%

    .tablas
      grid-area: tablas
      grid-template-areas: "tabla1" "tabla2" "tabla3"
      -ms-grid-columns: 100%
      grid-template-columns: 100%
      -ms-grid-rows: 10px 120px 80px
      grid-template-rows: 10px 120px 80px

      .tabla1, .tabla3
        padding: 1rem 0rem
        thead
         th
            &:first-child
               width: 20%

      .tabla2
        padding: 1rem 0rem
        tbody
          th
            border-right: 2px solid $Green
            background: $Green
            color: white
            width: 20%
            padding: 8px
          td
            padding: 8px
          tr
            height: 28px

          .no-border
            border-right: none

    .bola
      grid-area: bola
      padding: 1rem

      .container-bola
        height: 100%
        border: 2px solid $Grey1
        display: -webkit-box
        display: -ms-flexbox
        display: flex
        -webkit-box-pack: center
        -ms-flex-pack: center
        justify-content: center
        -webkit-box-align: center
        -ms-flex-align: center
        align-items: center

        img
          width: 200px

  table tr:nth-child(even)
    background: $Grey2
    
@media ( max-width: $XSmall )
  .container-tmpTensor
    padding: 25px 0 10px
    -webkit-box-shadow: none
    -moz-box-shadow: none
    box-shadow: none
  
@media (max-width: $Small)
  .EQTensor_container
    gap: 30px
    .container-table-tm
      #EQTmTable
        table
          tbody
            display: block
            tr
              display: grid
              grid-template-columns: 50% 50%
              grid-template-rows: 40px
              width: 100%
              td 
                width: 100%
                height: 100%
                border: none
              tr
                width: 100%
                height: 100%
                border: none
              

    .container-bola
      img
        width: 70% !important
    .fila2 .fila2-container
      display: -ms-grid
      display: grid
      grid-template-areas: "tablas" "bola"
      -ms-grid-columns: 100%
      grid-template-columns: 100%
      -ms-grid-rows: auto auto
      grid-template-rows: auto auto   
        
