.leaflet-div-icon
  background: none
  border: none
  color: black
  font-weight: bold
  position: absolute
  display: flex
  justify-content: center
  align-self: center

.close-popup-sm
  display: flex
  justify-content: flex-end
  align-items: center
  background: white
  color: black

  .icon-close
    cursor: pointer