/*HomeHeader.jsx*/
@import HomeComponents/_HomeHeader
/*HomeBody*/
@import HomeComponents/_HomeBody

@import HomeComponents/_HomeContrast
@import HomeComponents/_HomeFonts
   
// Home Styles
.HmContainer
  width: 100%
  overflow: hidden
  font-family: 'Nunito Sans', sans-serif
  font-weight: 300

.HomeTitle
   font-family: 'Montserrat', sans-serif
   font-weight: 700 
   
.HomeTitle
   font-weight: 900 
   text-transform: uppercase
   font-size: 1.3rem

// Buttons
div
   .EventButton, .EventButtonFilled
      transition: .25s
      display: inline-block
      color: white
      padding: 10px 2rem
      text-decoration: none
      font-size: 1rem
      border: 2px solid $SGC
      border-radius: 10px
      @media ( max-width: $XSmall )
         padding: 8px 1.5rem
         font-size: 3vw 
   .EventButton
      &:hover
         background: $SGC
         transform: scale(98%)
   .EventButtonFilled
      border: none
      background-color: $SGC
      &.Green
         background-color: $Green
      &:hover
         opacity: .9
         transform: scale(98%)
         text-decoration: none

section
   .SectionTitleContainer
      max-width: 1364px
      margin: 0 auto
      min-height: 180px
      padding: 1rem
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center
      flex-direction: column
      -ms-flex-direction: column
      .SectionTitle
         color: $Green
         text-align: center
         padding: .2rem .5rem
         border-bottom: 3px solid $Green
         text-transform: uppercase
         font-weight: 900 
         font-size: 1.7rem
      .SectionTitleInfo
         padding: .5rem
         text-align: center
         span
            display: inline-block
      @media ( max-width: $Small )
         min-height: unset
         padding: 1.5rem 2rem
         .SectionTitle
            font-size: 5vw
         .SectionTitleInfo
            font-size: 14px
      @media ( max-width: $XSmall )
         padding: 8vw 0
         width: 85%
         margin: 0 auto
         .SectionTitle
            font-size: 6.5vw
         .SectionTitleInfo
            font-size: 3.2vw
            span
               display: inline
   .SectionContent
      width: 100%

.HomeHeader, .HomeMain
  width: 100%
  background: $White
  position: relative

span.no-wrap
   text-wrap: nowrap
   white-space: nowrap

// Paragraph Text Font Size: 0.9rem
// Menu Item Font Size: 0.85rem
// Title Font Size: 1.3rem
// Section Title Font Size: 1.7rem
// Section Text Font Size: 1rem

