/**_QueryModal

.QmodalOpen
  display: -webkit-box
  display: -ms-flexbox
  display: flex

  .QmodalContainer
    height: 160px
    width: 70%
    margin: auto
    border: 8px solid $Grey12
    background: $White
    color: $Grey12

    .QmodalHead
      padding: 10px 0
      display: -webkit-box
      display: -ms-flexbox
      display: flex
      -webkit-box-pack: center
      -ms-flex-pack: center
      justify-content: center
      -webkit-box-align: center
      -ms-flex-align: center
      align-items: center

      h2 img
        width: 20px
        padding: 2px 0

    .QmodalBody
      padding: 20px

      p
        text-align: justify

      button
        float: right
        color: $GovBlue
        border: none
        background: transparent
        padding: 20px 20px 0 0
        cursor: pointer

@media (max-width: 420px)
  .QmodalBody p
    font-size: 8pt
.modalPresentation
  scroll-behavior: smooth
.CatalogModalContainer
  background: $White
  width: 100%
  .container-header
    position: fixed
    width: 100%
    height: 70px
    z-index: 1000
    background: $PGreen
  .queryModalContent
    padding-top: 70px
/**_QueryModal